import React from "react";

import styled from "styled-components";
import { devices, padding } from "..";

const ColumnContainer = styled.div`
  display: flex;
  @media only screen and (-ms-high-contrast:active), (-ms-high-contrast:none) {
    display: inline-block !important;
  }
  flex-flow: row;
  flex: 1;

  @media ${devices.tabletsm} {
    padding-top: ${padding.xl}
    position: relative;
  }
`;

const Column = styled.div`
  display: ${({ hideColumn }) => (hideColumn ? "none" : "flex")};
  flex: 1;
  flex-flow: column;
  @media ${devices.tabletsm} {
    display: flex;
    flex: 1;
  }
`;
const LeftColumn = styled(Column)`
  @media ${devices.tabletsm} {
    max-width: 420px;
    & > div {
      padding: 0px;
      max-width: 420px;
      padding-right: 10px;
      margin-left: 30px;
    }
  }
`;
const RightColumn = styled(Column)`
  @media ${devices.tabletsm} {
    & > div {
      padding: 0px;
      padding-left: 10px;
      margin-right: 30px;
    }
  }
`;

export default class DoubleColumn extends React.Component {
  render() {
    const { hideColumn, leftColumn, rightColumn, context } = this.props;
    return (
      <ColumnContainer>
        <LeftColumn hideColumn={hideColumn === "left"} id="left-column">
          {hideColumn === "left" && context.isDeviceMobile() ? (
            <React.Fragment />
          ) : (
            leftColumn
          )}
        </LeftColumn>
        <RightColumn hideColumn={hideColumn === "right"} id="right-column">
          {hideColumn === "right" && context.isDeviceMobile() ? (
            <React.Fragment />
          ) : (
            rightColumn
          )}
        </RightColumn>
      </ColumnContainer>
    );
  }
}
