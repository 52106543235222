import React from 'react'
import { Container, CardContent } from '../../style/components/card'
import { InfoContainer } from '../../style/styles'

export default class Contact extends React.Component {
  render() {
    const { email, profile } = this.props
    if (!email || !profile) {
      return <React.Fragment />
    }
    return (
      <Container>
        <CardContent>
          <InfoContainer>
            <div>Name:</div>
            <span>{ profile.full_name }</span>
          </InfoContainer>
          <InfoContainer>
            <div>Position:</div>
            <span>{ profile.profession }</span>
          </InfoContainer>
          <InfoContainer>
            <div>Email:</div>
            <span>{ email }</span>
          </InfoContainer>
          <InfoContainer>
            <div>Phone:</div>
            <span>{ profile.telephone }</span>
          </InfoContainer>
        </CardContent>
      </Container>
    )
  }
}
