import React from 'react';

import { Detail, Contact, Description, Products, Invited } from '.';
import IFrame from './IFrame/IFrame';
import { Button } from '../../style/components/button';

export default class ProviderDetails extends React.Component {
  constructor(props) {
    super(props);

    this.provider = this.props.provider;
    this.updateParent = this.props.updateParent;

    this.state = {
      provider: {
        id: null,
        name: '',
        website: '',
        telephone: '',
        logo_url: '',
        user: {
          profile: {},
        },
        enquiries: [],
        description: '',
        products: [],
      },
    };

    this.formRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.provider) {
      this.setState({
        provider: this.props.provider,
      });
    }
  }

  componentDidUpdate(prevProp, prevState) {
    if (prevProp.provider !== this.props.provider) {
      this.provider = this.props.provider;
      this.setState({
        provider: this.props.provider,
      });
    }
  }

  renderEditButton() {
    return (
      <Button styled='secondary' onClick={this.props.startEditing}>
        Edit provider
      </Button>
    );
  }

  renderInviteButton() {
    if (
      this.props.provider &&
      this.props.provider.user &&
      !this.props.provider.user.active
    ) {
      return (
        <Button
          styled='secondary'
          onClick={() => {
            this.props.inviteProvider(this.props.provider);
          }}
        >
          Invite provider
        </Button>
      );
    }
  }

  render() {
    if (!this.state.provider) return <div />;

    return (
      <div>
        <Invited user={this.state.provider.user} />
        <Detail provider={this.state.provider} />
        <Contact
          email={this.state.provider.user.email}
          profile={this.state.provider.user.profile}
        />
        <Description description={this.state.provider.description} />
        <IFrame url={this.state.provider.iframe} location='admin' />
        <Products products={this.state.provider.products} />
        {this.renderEditButton()}
        {this.renderInviteButton()}
      </div>
    );
  }
}
