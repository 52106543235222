import React from 'react'
import moment from 'moment'
import DoubleColumn from '../../../style/layouts/DoubleColumn'
import { Details, CreateOrUpdate } from '../../../components/Influencers'

import {
  getInfluencer,
  updateInfluencer,
  fetchEnquiry
} from '../../../lib/api'
import { DesktopTable } from '../../../style/layouts'
import { ScreenBody, UnstyledLink } from '../../../style/styles'
import NameCard from '../../../components/Influencers/NameCard'
import { IndexCard, Enquiry } from '../../../components/Enquiries'

import Validation, { required, email } from '../../../lib/validation'
import { orderNumerically } from '../../../lib/helper'
import { widths } from '../../../style'
import { Button } from '../../../style/components/button'

export default class Influencer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      // State required for update/create form
      influencer: {
        full_name: '',
        mobile_number: '',
        email: '',
        twitter_handle: '',
        start_date: '',
        end_date: '',
        leads: [],
        users: []
      },
      enquiry: {
        product: {},
        profile: {},
        influencer: {},
        status: {},
        provider: {
          user: {
            profile: {},
          },
        },
        user: {
          profile: {}
        }
      },
      // State required for view
      hiddenColumn: '',
      tableData: [],
      tableColumns: [],
      tableName: this.props.context.state.tableName,
      editing: false,
      formErrors: {},
      loaded: false,
      enquiryLoaded: false
    }

    this.validation = new Validation({
      full_name: [
        {type: required, message: 'Please enter a full name'}
      ],
      email: [
        {type: required, message: 'Please enter an email'},
        {type: email, message: 'Please enter a valid email'}
      ],
      mobile_number: [
        {type: required, message: 'Please enter a mobile number'}
      ],
      twitter_handle: [
        {type: required, message: 'Please enter a twitter handle'}
      ],
      start_date: [
        {type: required, message: 'Please enter a start date'}
      ],
      end_date: [
        {type: required, message: 'Please enter a end date'}
      ],
      commission: [
        {type: required, message: 'Please enter a fee %'}
      ],
    })
  }

  async componentDidMount() {
    const { isDeviceMobile } = this.props.context

    this.getEnquiry()

    if (isDeviceMobile()) {
      await this.setState({
        hiddenColumn: 'left'
      })
    }

    await this.fetchInfluencer()
  }

  async componentDidUpdate(prevProps) {
    const { match, context } = this.props

    if (match.params.id !== prevProps.match.params.id) {
      await this.fetchInfluencer()
      context.setHeaderButtons([
        {
          to: '/influencer#create',
          text: 'Add influencer',
          hasPermission: 'create-influencer',
          buttonDisplay: true
        }
        ])
    }

    if (match.params.enquiryId !== prevProps.match.params.enquiryId) {
      this.getEnquiry()
    }

    if (match.params.page !== prevProps.match.params.page) {
      this.setState({
        hiddenColumn: match.params.page === 'leads' ? 'right' : 'left'
      })
    }
  }

  componentWillUnmount() {
    const { context } = this.props
    context.updateClickableHeaders()
    context.updatePageTitle()
    context.setBreadcrumb()
    context.hideBCOnMobile()
    context.setHeaderButtons()
  }

  setPageHeader(influencer = this.state.influencer) {
    const { match, context, history } = this.props

    if (match.params.enquiryId && context.isDeviceMobile()) {
      context.setBreadcrumb([
        { text: 'Home', to: '/' },
        { text: 'Influencers', to: '/influencer', hideIcon: true },
        { text: 'Leads', onClick: () => {
          history.push(`/influencer/${match.params.id}/leads`)
          context.setBreadcrumb([
            { text: 'Home', to: '/' },
            { text: 'Influencers', to: '/influencer' }
          ])
        }, hideIcon: true }
      ])
    } else {
      context.setBreadcrumb([
        { text: 'Home', to: '/' },
        { text: 'Influencers', to: '/influencer' }
      ])
    }

    context.displayBCOnMobile()
    context.updatePageTitle(influencer.full_name)
    context.setHeaderButtons([
      {
        to: '/influencer#create',
        text: 'Add influencer',
        hasPermission: 'create-influencer',
        buttonDisplay: true
      }
    ])

    const enquiries = influencer.users.map(user => user.enquiries).flat()
    const isLeadsActive = match.params.page === 'leads' && enquiries.length
    context.updateClickableHeaders([
      {
        title: enquiries.length ? `Leads (${enquiries.length})` : 'Leads',
        active: isLeadsActive,
        to: `/influencer/${influencer.id}/leads`,
        disabled: !enquiries.length,
      },
      {
        title: 'Details',
        active: !isLeadsActive,
        to: `/influencer/${influencer.id}/details`
      }
    ])
  }

  async getEnquiry() {
    const { match, context } = this.props
    const { enquiryId } = match.params
    if (enquiryId) {
      try {
        const { data } = await fetchEnquiry(enquiryId)
        await this.setState({
          enquiry: data,
          enquiryLoaded: true
        })
        this.setPageHeader()
      } catch(e) {
        console.log(e)
        console.log(e.message)
      }
    } else {
      if (context.isDeviceMobile()) {
        context.setBreadcrumb([
          { text: 'Home', to: '/' },
          { text: 'Influencers', to: '/influencer' }
        ])
      }
    }
  }

  handleRowClick(row) {
    const { history  }= this.props
    switch (this.state.tableName) {
      case 'influencers':
        history.push(`/influencer/${row.id}`)
        break
      case 'influencerLeads':
      default:
        history.push(`/influencer/${this.state.influencer.id}/leads/${row.id}`)
        break
    }
  }

  async fetchInfluencer() {
    const { context, match } = this.props
    try {
      const { data } = await getInfluencer(match.params.id)
      this.setPageHeader(data)

      const enquiries = orderNumerically(data.users.map(user => user.enquiries).flat(), 'id', true)

      await this.setState({
        influencer: data,
        loaded: true,
        tableName: 'influencerLeads',
        tableData: enquiries.map((enquiry, index) => {
          return {
            id: enquiry.id,
            date: moment(enquiry.created_at).format('DD/MM/YYYY'),
            name: enquiry.full_name,
            product: enquiry.product.name,
            provider: enquiry.provider.name,
            status: enquiry.status.display_name,
            data: {
              ...enquiry,
              influencer_id: data.id
            }
          }
        }),
        tableColumns: [
          { name: 'Date', selector: 'date', sortable: true, width: widths.table.dateStatusColumn},
          { name: 'Name', selector: 'name', sortable: true},
          { name: 'Service', selector: 'product', sortable: true},
          { name: 'Provider', selector: 'provider', sortable: true},
          { name: 'Status', selector: 'status', sortable: true, width: widths.table.dateStatusColumn}
        ]
      })

      if (context.isDeviceMobile()) {
        const hiddenColumn = match.params.page === 'leads' && enquiries.length ? 'right' : 'left'
        this.setState({
          hiddenColumn
        })
      }
    } catch (e) {
      console.log(e.message)
    }
  }

  displayEditForm = () => {
    this.setState({
      editing: true
    })
  }

  handleBlur(target) {
    this.validation.validateInput(target)

    // View won't refresh on blur unless state updates
    this.setState({
      formErrors: this.validation.errors
    })
  }

  cancelEditing = async (event) => {
    event.preventDefault()
    const { match, history } = this.props

    if (match.params.id === 'create') {
      history.push('/influencer')
    } else {
      await this.fetchInfluencer()
      this.validation.resetErrors()
      this.setState({
        editing: false
      })
    }
  }

  async createOrUpdate(event) {
    event.preventDefault()

    if (!this.validation.validateForm(this.state.influencer)) {
      this.setState({
        formErrors: this.validation.errors
      })
      return
    }

    try {
      const { data } = await updateInfluencer(this.state.influencer)
      this.setPageHeader(data)
      this.setState({
        editing: false
      })
    } catch(e) {
      console.log(e.message)
    }
  }

  updateInfluencerState(target) {
    const { name, value } = target

    this.setState({
      influencer: {
        ...this.state.influencer,
        [name]: value
      }
    })
  }

  renderInfluencerBody() {
    const { context, match, history } = this.props
    if (match.params.enquiryId) {
      return this.renderEnquiry()
    }

    if (this.state.editing) {
      return <CreateOrUpdate
          influencer={ this.state.influencer }
          match={ match }
          history={ history }
          handleBlur={ this.handleBlur.bind(this) }
          updateFormState={ this.updateInfluencerState.bind(this) }
          cancel={ this.cancelEditing.bind(this) }
          submitForm={ this.createOrUpdate.bind(this) }
          errors={ this.state.formErrors }
        />
    } else {
      return <div>
          <Details
            match={ match }
            history={ history }
            influencer={ this.state.influencer }
            context={ context }
            displayEditForm={ this.displayEditForm.bind(this) }
          />
        </div>
    }
  }

  renderEnquiry() {
    if (!this.state.enquiryLoaded) return <React.Fragment />
    return (
      <React.Fragment>
        <Enquiry
          enquiry={ this.state.enquiry }
          profile={ this.state.enquiry.user.profile }
          provider={ this.state.enquiry.provider }
          influencer={ this.state.enquiry.user.influencer ? this.state.enquiry.user.influencer : null }
        />
        {
          this.props.context.isDeviceMobile() ?
            <React.Fragment /> :
            <div>
              <UnstyledLink to={ `/influencer/${this.state.influencer.id}` }>
                <Button styled='secondary'>
                  Hide lead
                </Button>
              </UnstyledLink>
            </div>
        }
      </React.Fragment>
    )
  }

  renderLeftColumn() {
    let enquiries = []

    if (this.props.match.params.enquiryId) {
      return this.renderEnquiry()
    }

    if (this.state.influencer.users) {
      this.state.influencer.users.map(user => {
        enquiries.push(user.enquiries)
        return user
      })
    }

    enquiries = orderNumerically(enquiries.flat(), 'id', true)

    return <div>
      <NameCard
        influencer={ this.state.influencer }
        full_name={ this.state.influencer.full_name }
        users={ this.state.influencer.users }
        showFlag
      />
      {
        enquiries.map((enquiry, index) => {
          return (
            <UnstyledLink key={ index } to={ `/influencer/${this.state.influencer.id}/leads/${enquiry.id}` }>
              <IndexCard
                status={ enquiry.status }
                name={ enquiry.full_name }
                date={ enquiry.created_at }
                product={ enquiry.product.name }
              />
            </UnstyledLink>
          )
        })
      }
    </div>
  }

  render() {
    if (!this.state.loaded) return <React.Fragment />

    const { context } = this.props

    if (context.isDeviceMobile()) {
      let enquiries = []

      if (this.state.influencer.users) {
        this.state.influencer.users.map(user => {
          enquiries.push(user.enquiries)
          return user
        })
      }

      enquiries = orderNumerically(enquiries.flat(), 'id', true)

      return (
        <ScreenBody>
          <DoubleColumn
            context={ context }
            leftColumn={ this.renderLeftColumn() }
            rightColumn={ this.renderInfluencerBody() }
            hideColumn={ this.state.hiddenColumn }
          />
        </ScreenBody>
      )
    }

    return (
      <DesktopTable
        tableName={ this.state.tableName }
        displaySidePanel={ true }
        tableData={ this.state.tableData }
        tableColumns={ this.state.tableColumns }
        onRowClicked={ this.handleRowClick.bind(this) }
        tableFilterOptions={ [{ value: 1, text: 'All leads' }] }
        updatingTable={ this.state.updatingTable }
        context={ this.props.context }
      >
        { this.renderInfluencerBody() }
      </DesktopTable>
    )
  }
}
