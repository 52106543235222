import React from 'react'
import { Redirect } from 'react-router-dom'

import { Boxes } from '../../../components/Boxes'
import { BoxScreenBody, ModalForm } from '../../../style/styles'
import { icons } from '../../../style'
import { Modal } from '../../../components/Modal'
import { Input, Select } from '../../../components/Inputs'
import Validation, { required } from '../../../lib/validation'
import { Button } from '../../../style/components/button'

import { createCategory, getCategories, updateCategory, deleteCategory } from '../../../lib/api'
import { orderAlphabetically } from '../../../lib/helper'

import IconList from '../../../lib/icons'

export default class Categories extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
    this.state = {
      newCategory: { name: '', icon: '' },
      formErrors: {},
      showModal: false,
      categories: [],
      redirectId: null,
      icons: IconList
    }

    this.validation = new Validation({
      name: [
        {type: required, message: 'Please enter a name for the category'},
      ],
      icon: [
        {type: required, message: 'Please enter a Font awesome icon name'}
      ],
    })
  }

  componentDidMount() {
    this.fetchCategories()

    if (this.props.context) {
      this.props.context.updatePageTitle('Services')
      this.props.context.setBreadcrumb()
    }
  }

  fetchCategories = async () => {
    let addCategory = {
      name: 'Add service',
      onClick: this.addCategory.bind(this),
      editable: false
    }

    let categories = []
    try {
      const { data } = await getCategories()

      categories = orderAlphabetically(data, 'name')
    } catch(e) {
      console.log(e)
    }

    categories.push(addCategory)

    this.setState({
      categories
    })
  }


  updateChosenIcon = (option) => {
    if (option) {
      this.setState({
        newCategory: {
          ...this.state.newCategory,
          icon: option.value
        }
      })
    }
  }

  updateNewCategoryState = (target) => {
    const { name, value } = target

    this.setState({
      newCategory: {
        ...this.state.newCategory,
        [name]: value
      }
    })
  }

  handleBlur = (target) => {
    if (this.state.showModal) {
      this.validation.validateInput(target)

      this.setState({
        formErrors: this.validation.errors
      })
    }
  }

  async createOrUpdateCategory(event = null) {
    event.preventDefault()

    if (this.validation.validateForm(this.state.newCategory)) {
      try {
        if (this.state.newCategory.id) {
          await updateCategory(this.state.newCategory)
        } else {
          await createCategory(this.state.newCategory)
        }
      } catch (e) {
        console.log(e)
      }

      this.fetchCategories()

      this.closeModal()
      this.validation.resetErrors()
    } else {
      this.setState({
        formErrors: this.validation.errors
      })
    }
  }

  addCategory = () => {
    this.setState({
      showModal: true
    })
  }

  closeModal = async () => {
    this.validation.resetErrors()
    await this.setState({
      formErrors: {}
    })
    await this.setState({
      showModal: false,
      newCategory: { name: '', icon: '' }
    })

    // Make sure the modal is closed before clearing off validation
    this.setState({
      formErrors: {}
    })
  }

  viewCategory = (category) => {
    this.setState({
      redirectId: category.id
    })
  }

  editCategory = (category) => {
    this.setState({
      newCategory: category,
      showModal: true
    })
  }

  removeCategory = async (category) => {
    if (window.confirm("You are about to delete a category and all it's associated services. Do you wish to continue?")) {
      try {
        await deleteCategory(category)
      } catch (e) {
        console.log(e)
      }

      this.fetchCategories()
    }
  }

  render() {
    if (this.state.redirectId) {
      return <Redirect push to={`/categories/${this.state.redirectId}`} />
    }

    return (
      <React.Fragment>
        <BoxScreenBody>
          <Boxes
            items={ this.state.categories }
            displayKey="name"
            onClick={ this.viewCategory.bind(this) }
            editable={ true }
            onMenuClick={ this.editCategory.bind(this) }
            // menuItems={[
            //   {
            //     icon: icons.pencil,
            //     text: 'Edit',
            //     onClick: this.editCategory.bind(this)
            //   },
            //   {
            //     icon: icons.archive,
            //     text: 'Remove',
            //     onClick: this.removeCategory.bind(this)
            //   }
            // ]}
          />
        </BoxScreenBody>
        <Modal
          title={ this.state.newCategory.id ? 'Update service' : 'Add service' }
          show={ this.state.showModal }
          close={ this.closeModal.bind(this)}
        >
          <ModalForm onSubmit={ this.createOrUpdateCategory.bind(this) }>
            <Input
              placeholder="Service name"
              data_testid="name"
              name="name"
              type="text"
              onChange={ this.updateNewCategoryState.bind(this) }
              onBlur={ this.handleBlur.bind(this) }
              value={ this.state.newCategory.name }
              errors={ this.state.formErrors.name }
            />
            <Select
              placeholder="Choose an icon"
              name="icon"
              options={ this.state.icons }
              onChange={this.updateChosenIcon.bind(this)}
              closeMenuOnSelect={true}
            />
            <Button>
              { this.state.newCategory.id ? 'Update service' : 'Add service' }
            </Button>
          </ModalForm>
        </Modal>
      </React.Fragment>
    )
  }
}

            // <Input
            //   placeholder="Font awesome name"
            //   data_testid="icon"
            //   name="icon"
            //   type="text"
            //   onChange={ this.updateNewCategoryState.bind(this) }
            //   onBlur={ this.handleBlur.bind(this) }
            //   value={ this.state.newCategory.icon }
            //   errors={ this.state.formErrors.icon }
            // />
