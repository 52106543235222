import React from 'react'
import moment from 'moment'
import { Container, CardContent } from '../../style/components/card'
import { InfoContainer } from '../../style/styles'

export default class Commissions extends React.Component {
  render() {
    const { start_date, end_date, commission } = this.props
    return (
      <Container>
        <CardContent>
          <InfoContainer>
            <div>Start:</div>
            <span>{ moment(start_date).format('DD/MM/YYYY') }</span>
          </InfoContainer>
          <InfoContainer>
            <div>End:</div>
            <span>{ moment(end_date).format('DD/MM/YYYY') }</span>
          </InfoContainer>
          <InfoContainer>
            <div>Fee %:</div>
            <span>{ commission }</span>
          </InfoContainer>
        </CardContent>
      </Container>
    )
  }
}
