import styled from 'styled-components'
import { border, margin, padding, colours, devices } from '..'

export const CardContainer = styled.div`
  padding: ${padding.md};
  background: ${colours.white};
  border-radius: ${border.radius.default};
  margin-bottom: ${margin.lg};
  position: relative;
`

export const ReferralLink = styled.a`
  color: inherit;
`

export const Container = styled.div`
  border-radius: ${border.radius.default};
  background: ${colours.white};
  padding: 10px;
  margin-bottom: 15px;

  cursor: ${({onClick}) => onClick ? 'pointer' : 'default'}

  display: flex;
  @media only screen and (-ms-high-contrast:active), (-ms-high-contrast:none) {
    display: inline-block !important;
  }
  flex-direction: row;

  align-items: flex-start;

  position: relative;
`

export const CardContent = styled.div`
  display: flex;
  @media only screen and (-ms-high-contrast:active), (-ms-high-contrast:none) {
    display: inline-block !important;
  }
  flex-direction: column;
  flex: 1;
  justify-content: space-around;

  position: relative;
`

export const CardFlag = styled.div`
  width: 80px;
  height: 25px;
  border-radius: 0 ${border.radius.default} 0 0;
  position: absolute;
  display: flex;
  @media only screen and (-ms-high-contrast:active), (-ms-high-contrast:none) {
    display: inline-block !important;
  }
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  z-index: 2;
  background: ${({status}) => {
    switch (status) {
      case 1:
        return colours.status.new
      case 2:
        return colours.status.open
      case 3:
        return colours.status.won
      case 4:
        return colours.status.lost
      default:
        return colours.lightGrey
    }
  }}
  color: ${({status}) => {
    switch (status) {
      case 1:
        return colours.status.text.new
      case 2:
        return colours.status.text.open
      case 3:
        return colours.status.text.won
      case 4:
        return colours.status.text.lost
      default:
        return colours.lightGrey
    }
  }}

  @media ${devices.tabletsm} {
    box-shadow: inset -1px 1px 0 0 ${({highlighted}) => highlighted ? colours.blue : colours.transparent}
  }
`
