import React from 'react';

import styled from 'styled-components';
import { devices, colours } from '../../style';

import { hasRole } from '../../lib/permission';

import { ReactComponent as LeadsSVG } from '../../assets/leads.svg';
import { ReactComponent as ProvidersSVG } from '../../assets/providers.svg';
import { ReactComponent as InfluencerSVG } from '../../assets/influencer.svg';
import { ReactComponent as UsersSVG } from '../../assets/users.svg';
import { ReactComponent as ServicesSVG } from '../../assets/services.svg';
import { ReactComponent as SettingsSVG } from '../../assets/settings.svg';
import { ReactComponent as ProfileSVG } from '../../assets/profile.svg';
import { ReactComponent as LogOutSVG } from '../../assets/log-out.svg';

import MenuLink from './MenuLink';
import { checkActiveTab } from '../../lib/helper';

const MenuBody = styled.div`
  display: ${({ hasToken }) => (hasToken ? 'flex' : 'none')};
  flex: 1;
  flex-direction: column;
  position: absolute;
  top: 74px;
  bottom: 0;
  right: 0;
  left: 0;
  background: ${colours.white};
  transform: ${({ show }) => (show ? 'translateX(0)' : 'translateX(-100%)')};
  transition: all 0.2s linear;

  z-index: 100;

  & a:first-child {
    margin-top: 12.5px;
  }

  @media ${devices.tabletsm} {
    transform: translateX(0);
    position: relative;
    top: 0;
    max-width: 200px;
    min-width: 200px;
    display: ${({ individual, hasToken }) =>
      individual || !hasToken ? 'none' : 'flex'};
  }
`;

export default class Menu extends React.Component {
  fireLogout = (callback) => {
    this.props.context.logout();

    if (callback) {
      callback();
    }
  };

  onClick() {
    const { onClick } = this.props;

    onClick();
  }

  renderAdminLinks() {
    if (hasRole('admin') || hasRole('developer')) {
      return (
        <React.Fragment>
          <MenuLink
            permission='get-enquiries'
            onClick={this.props.onClick}
            tab='leads'
            to='/leads'
            label='Leads'
            SVG={LeadsSVG}
            active={
              checkActiveTab('leads') ||
              (window.location.pathname === '/' ? colours.blue : null)
            }
          />
          <MenuLink
            permission='get-all-providers'
            onClick={this.props.onClick}
            tab='providers'
            to='/providers'
            label='Providers'
            SVG={ProvidersSVG}
          />
          <MenuLink
            permission='get-influencers'
            onClick={this.props.onClick}
            tab='influencer'
            to='/influencer'
            label='Influencers'
            SVG={InfluencerSVG}
          />
          <MenuLink
            permission='get-individuals'
            onClick={this.props.onClick}
            tab='user'
            to='/user'
            label='Users'
            SVG={UsersSVG}
          />
          <MenuLink
            permission='get-categories'
            onClick={this.props.onClick}
            tab='categories'
            to='/categories'
            label='Services'
            SVG={ServicesSVG}
          />
        </React.Fragment>
      );
    }
  }

  renderProviderLinks() {
    if (hasRole('service-provider')) {
      return (
        <React.Fragment>
          <MenuLink
            permission='get-enquiries'
            onClick={this.props.onClick}
            tab='leads'
            to='/leads'
            label='Leads'
            SVG={LeadsSVG}
            active={
              checkActiveTab('leads') ||
              (window.location.pathname === '/' ? colours.blue : null)
            }
          />
          <MenuLink
            permission='get-provider'
            onClick={this.props.onClick}
            tab='provider'
            to='/provider'
            label='Details'
            SVG={ProvidersSVG}
          />
        </React.Fragment>
      );
    }
  }

  renderIndividualLinks() {
    if (hasRole('individual')) {
      return (
        <React.Fragment>
          <MenuLink
            permission='get-enquiries'
            onClick={this.props.onClick}
            tab='enquiries'
            to='/enquiries'
            label='Enquiries'
            SVG={LeadsSVG}
          />
          <MenuLink
            permission='get-categories'
            onClick={this.props.onClick}
            tab='category'
            to='/category'
            label='Services'
            active={
              checkActiveTab('service') ||
              checkActiveTab('category') ||
              (window.location.pathname === '/' ? colours.blue : null)
            }
            SVG={ServicesSVG}
          />
        </React.Fragment>
      );
    }
  }

  render() {
    const { show, individual, onClick } = this.props;

    return (
      <MenuBody
        show={show}
        hasToken={this.props.context.state.token !== ''}
        individual={individual}
      >
        {this.renderAdminLinks()}
        {this.renderProviderLinks()}
        {this.renderIndividualLinks()}
        {this.props.context.isDeviceMobile() ? (
          <React.Fragment>
            {!hasRole('service-provider') ? (
              <MenuLink
                onClick={this.props.onClick}
                tab='profile'
                to='/profile'
                label='Profile'
                SVG={ProfileSVG}
              />
            ) : (
              <React.Fragment />
            )}
            <MenuLink
              onClick={this.props.onClick}
              tab='settings'
              to='/settings'
              label='Settings'
              SVG={SettingsSVG}
            />
            <MenuLink
              onClick={() => this.fireLogout(onClick)}
              label='Log out'
              SVG={LogOutSVG}
            />
          </React.Fragment>
        ) : (
          <React.Fragment />
        )}
      </MenuBody>
    );
  }
}
