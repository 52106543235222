import React from 'react';
import { Route, BrowserRouter as Router, Switch, Link } from 'react-router-dom';
import styled from 'styled-components';

import {
  AuthRoutes,
  IndividualRoutes,
  AdminRoutes,
  ProviderRoutes,
  EmailRoute,
} from './routes';
import { StateProvider, StateContext } from './components/Context';
import { Header, HeaderMenuOption } from './style/components/header';
import { ReactComponent as Icon } from './assets/Play-Ex-Logo.svg';
import { AppLayout, UnstyledLink } from './style/styles';
import { DropdownMenu, Menu, Hamburger } from './components/Navigation';
import { ProtectedRoute } from './components/Routes';

import { hasPermission, hasRole } from './lib/permission';
import { negateScrolling, allowScrolling } from './lib/helper';

const ContainerFragment = styled(React.Fragment)`
  background: white;
`;

class AppRouter extends React.Component {
  static contextType = StateProvider;
  constructor(props) {
    super(props);

    this.props = props;

    this.state = {
      displayMenu: false,
    };
  }

  toggleMenu = async () => {
    await this.setState({
      displayMenu: !this.state.displayMenu,
    });

    this.state.displayMenu ? negateScrolling() : allowScrolling();
  };

  renderDropdownMenu(context) {
    if (context.state.token && context.state.user) {
      let options = [
        {
          text: 'Settings',
          to: '/settings',
        },
        {
          text: 'Logout',
          onClick: () => context.logout(),
        },
      ];

      if (!hasRole('service-provider')) {
        options.unshift({
          text: 'Profile',
          to: '/profile',
        });
      }

      return (
        <DropdownMenu
          menuLabel={`${context.state.user.profile.full_name}`}
          options={options}
        />
      );
    }
  }

  renderIndividualLinks(context) {
    if (hasRole('individual') && context.emailVerified) {
      return (
        <React.Fragment>
          {hasPermission('get-categories') ? (
            <UnstyledLink to='/category'>
              Services {/* will be called Services for an individual */}
            </UnstyledLink>
          ) : (
            ''
          )}

          {hasPermission('get-enquiries') ? (
            <UnstyledLink to='/enquiries'>Enquiries</UnstyledLink>
          ) : (
            ''
          )}
        </React.Fragment>
      );
    }
    return <React.Fragment />;
  }

  renderRoute() {
    if (hasRole('individual')) {
      return IndividualRoutes;
    }
    if (hasRole('admin') || hasRole('developer')) {
      return AdminRoutes;
    }
    if (hasRole('service-provider')) {
      return ProviderRoutes;
    }

    return AuthRoutes;
  }

  render() {
    return (
      <Router key='mainRouter'>
        <Route
          path='/'
          render={({ location }) => {
            if (typeof window.ga === 'function') {
              window.ga('set', 'page', location.pathname + location.search);
              window.ga('send', 'pageview');
            }
            return null;
          }}
        />
        <StateProvider>
          <StateContext.Consumer>
            {(context) => {
              return (
                <React.Fragment>
                  <Header
                    ref={context.deviceWidthRef}
                    location={window.location.pathname}
                  >
                    <Link to='/'>
                      <Icon width='150px' height='80px' />
                    </Link>
                    <Hamburger
                      context={context}
                      active={this.state.displayMenu}
                      onClick={this.toggleMenu.bind(this)}
                    />
                    <HeaderMenuOption
                      displayOptions={context.state.token !== ''}
                    >
                      {this.renderIndividualLinks(context)}

                      {this.renderDropdownMenu(context)}
                    </HeaderMenuOption>
                  </Header>
                  <Link to='/email'>
                    <ContainerFragment>
                      <EmailRoute />
                    </ContainerFragment>
                  </Link>
                  <AppLayout>
                    <Menu
                      context={context}
                      onClick={this.toggleMenu.bind(this)}
                      show={this.state.displayMenu}
                      individual={hasRole('individual')}
                    />
                    <Switch>
                      <Route
                        key='authRouter'
                        path='/auth'
                        render={(props) => (
                          <AuthRoutes {...props} context={context} />
                        )}
                      />
                      <ProtectedRoute
                        key='defaultRouter'
                        path='/'
                        context={context}
                        component={this.renderRoute()}
                      />
                    </Switch>
                  </AppLayout>
                </React.Fragment>
              );
            }}
          </StateContext.Consumer>
        </StateProvider>
      </Router>
    );
  }
}

export default AppRouter;
