import React from 'react';

import { PanelBody, UnstyledLink } from '../../../style/styles';
import { getIndividual, fetchEnquiry } from '../../../lib/api';

import { NameCard, DetailsCard } from '../../../components/Individuals';
import { InfluencerCard } from '../../../components/Influencers';
import moment from 'moment';
import { DesktopTable } from '../../../style/layouts';
import { IndexCard, Enquiry } from '../../../components/Enquiries';
import { widths } from '../../../style';
import { Button } from '../../../style/components/button';

class Individual extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      tableData: [],
      individual: {
        profile: {},
        enquiries: [],
      },
      enquiry: {
        product: {},
        user: {
          profile: {},
        },
        status: {},
        influencer: {},
        provider: {
          user: {
            profile: {},
          },
        },
      },
      searchValue: '',
      loaded: false,
      enquiryLoaded: false,
    };
  }

  async componentDidMount() {
    this.fetchLeads();

    if (this.props.match.params.enquiryId) {
      this.displayLead();
    }
  }

  componentWillUnmount() {
    if (this.props.context) {
      this.props.context.updatePageTitle();
      this.props.context.updateClickableHeaders();
      this.props.context.setBreadcrumb();
      this.props.context.hideBCOnMobile();
    }
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if (
      match.params.id !== prevProps.match.params.id ||
      this.props.location.search !== prevProps.location.search
    ) {
      this.fetchLeads();
    }

    if (match.params.enquiryId !== prevProps.match.params.enquiryId) {
      this.displayLead();
      this.setPageBreadcrumbs();
    }
  }

  async displayLead() {
    const { match } = this.props;

    if (match.params.enquiryId) {
      try {
        const { data } = await fetchEnquiry(match.params.enquiryId);
        this.setState({
          enquiry: data,
          enquiryLoaded: true,
        });
      } catch (e) {
        console.log(e.message);
      }

      this.setPageHeader();
    }
  }

  setPageHeader(individual = this.state.individual) {
    const { match, context } = this.props;

    context.displayBCOnMobile();
    context.updatePageTitle(individual.name);

    const enquiries = individual.enquiries.flat();
    const isLeadsActive = match.params.page === 'leads' && enquiries.length;
    this.setPageBreadcrumbs();
    context.updateClickableHeaders([
      {
        title: enquiries.length ? `Leads (${enquiries.length})` : 'Leads',
        active: isLeadsActive,
        to: `/user/${individual.id}/leads`,
        disabled: !enquiries.length,
      },
      {
        title: 'Details',
        active: !isLeadsActive,
        to: `/user/${individual.id}/details`,
      },
    ]);
  }

  setPageBreadcrumbs() {
    const { context, match, history } = this.props;
    const { id, enquiryId } = match.params;

    if (enquiryId && context.isDeviceMobile()) {
      context.setBreadcrumb([
        { text: 'Home', to: '/' },
        { text: 'Users', to: '/user', hideIcon: true },
        {
          text: 'Leads',
          onClick: () => {
            history.push(`/user/${id}/leads`);
            context.setBreadcrumb([
              { text: 'Home', to: '/' },
              { text: 'Users', to: '/user' },
            ]);
          },
          hideIcon: true,
        },
      ]);
    } else {
      context.setBreadcrumb([
        { text: 'Home', to: '/' },
        { text: 'Users', to: '/user' },
      ]);
    }
  }

  async fetchLeads() {
    const { context, match } = this.props;
    let user = {};
    try {
      const { data } = await getIndividual(match.params.id);
      user = data;
      await this.setState({
        individual: data,
        loaded: true,
      });
      this.setPageHeader(data);
    } catch (e) {
      console.log(e.message);
    }

    context.updatePageTitle(user.profile.full_name);
    context.displayBCOnMobile();
    context.setBreadcrumb([
      { text: 'Home', to: '/' },
      { text: 'Users', to: '/user' },
    ]);

    this.setState({
      tableData: user.enquiries.map((enquiry) => {
        return {
          id: enquiry.id,
          user_id: enquiry.user_id,
          date: enquiry.created_at,
          product: enquiry.product.name,
          provider: enquiry.provider.name,
          status: enquiry.status.display_name,
          data: enquiry,
        };
      }),
    });
    return user;
  }

  handleRowClick(row) {
    this.props.history.push(
      `/user/${this.props.match.params.id}/leads/${row.data.id}`
    );
  }

  renderLeads() {
    return (
      <div>
        {this.state.individual.enquiries &&
        this.state.individual.enquiries.length ? (
          this.state.individual.enquiries.map((enquiry, key) => {
            return (
              <UnstyledLink
                key={key}
                to={`/user/${this.props.match.params.id}/leads/${enquiry.id}`}
              >
                <IndexCard
                  status={enquiry.status}
                  name={enquiry.provider.name}
                  date={enquiry.created_at}
                  product={enquiry.product.name}
                />
              </UnstyledLink>
            );
          })
        ) : (
          <div />
        )}
      </div>
    );
  }

  renderDetails() {
    return (
      <div>
        <DetailsCard
          email={this.state.individual.email}
          telephone={this.state.individual.profile.telephone}
          profession={this.state.individual.profile.profession}
          instagram={this.state.individual.profile.instagram}
          countyOfResidence={this.state.individual.profile.county_of_residence}
          ageRange={this.state.individual.profile.age_range}
          profileImage={this.state.individual.profile.profile_image}
          socialMediaTotal={this.state.individual.profile.social_media_total}
        />
        {this.state.individual.influencer ? (
          <InfluencerCard
            full_name={this.state.individual.influencer.full_name}
            commission={this.state.individual.influencer.commission}
          />
        ) : (
          <React.Fragment />
        )}
      </div>
    );
  }

  renderEnquiry() {
    if (!this.state.enquiryLoaded) return <React.Fragment />;
    return (
      <PanelBody>
        <Enquiry
          profile={this.state.enquiry.user.profile}
          enquiry={this.state.enquiry}
          provider={this.state.enquiry.provider}
          influencer={this.state.enquiry.user.influencer || null}
        />
        {this.props.context.isDeviceMobile() ? (
          <React.Fragment />
        ) : (
          <div>
            <UnstyledLink to={`/user/${this.props.match.params.id}`}>
              <Button styled='secondary'>Hide lead</Button>
            </UnstyledLink>
          </div>
        )}
      </PanelBody>
    );
  }

  render() {
    const { context, match } = this.props;
    const { loaded, individual, tableData } = this.state;
    if (!loaded) return <React.Fragment />;
    return (
      <DesktopTable
        displaySidePanel={true}
        tableData={tableData}
        tableColumns={[
          {
            name: 'Date',
            selector: 'date',
            sortable: true,
            width: widths.table.dateStatusColumn,
            cell: (row) => moment(row.date).format('DD/MM/YYYY'),
          },
          { name: 'Service', selector: 'product', sortable: true },
          { name: 'Provider', selector: 'provider', sortable: true },
          {
            name: 'Status',
            selector: 'status',
            sortable: true,
            width: widths.table.dateStatusColumn,
          },
        ]}
        onRowClicked={this.handleRowClick.bind(this)}
        tableFilterOptions={[{ value: 1, text: 'All leads' }]}
        tableName='individualLeads'
        context={context}
      >
        {match.params.enquiryId ? (
          this.renderEnquiry()
        ) : (
          <PanelBody>
            <NameCard
              user={individual}
              name={individual.profile.full_name}
              openLeads={
                individual.enquiries.filter(
                  (lead) => lead.status.name === 'open'
                ).length
              }
              newLeads={
                individual.enquiries.filter(
                  (lead) => lead.status.name === 'new'
                ).length
              }
            />
            {match.params.page === 'leads' &&
            individual.enquiries.length &&
            context.isDeviceMobile()
              ? this.renderLeads()
              : this.renderDetails()}
          </PanelBody>
        )}
      </DesktopTable>
    );
  }
}

export default Individual;
