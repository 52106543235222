import React from 'react'
import { Invited as Container } from '../../style/styles'

const Invited = ({ user }) => {
  if (user.invited) {
    return (
        <Container>Invite Sent</Container>
    )
  }
  return <React.Fragment />
}

export default Invited
