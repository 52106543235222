import React from 'react'
import { Redirect } from 'react-router-dom'
import { BoxScreenBody } from '../../../style/styles'
import { Boxes } from '../../../components/Boxes'
import { getCategories } from '../../../lib/api'
import { orderAlphabetically } from '../../../lib/helper'

export default class ViewCategories extends React.Component {
  constructor(props) {
    super(props)

    this.props = props
    this.state = {
      categories: [],
      redirectCategory: null,
      searchValue: ''
    }
  }

  async componentDidMount() {
    this.props.context.updateClickableHeaders([
      {
        title: 'Services',
        active: true
      },
      {
        title: 'Enquiries',
        active: false,
        to: '/enquiries'
      }
    ])
    try {
      const { data } = await getCategories()
      this.setState({
        categories: data
      })
    } catch(e) {
      console.log(e)
    }

    this.props.context.updatePageTitle('Services')
  }

  componentWillUnmount() {
    this.props.context.setBreadcrumb()
    this.props.context.updateClickableHeaders()
  }

  viewCategory = (category) => {
    this.setState({
      redirectCategory: category
    })
  }

  render() {
    if (this.state.redirectCategory) {
      return (
        <Redirect push to={{
          pathname: `/category/${this.state.redirectCategory.id}`,
          state: {
            category: this.state.redirectCategory
          }
        }} />
      )
    }

    return (
      <BoxScreenBody>
        <Boxes
          items={ orderAlphabetically(this.state.categories, 'name') }
          onClick={ this.viewCategory.bind(this) }
          displayKey="name"
        />
      </BoxScreenBody>
    )
  }
}
