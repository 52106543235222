import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { colours, padding, border } from '../../style';
import { Button } from '../../style/components/button';

const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isDragActive) {
    return '#2196f3';
  }
  return colours.white;
};

const Container = styled.div`
  display: flex;
  margin-bottom: 15px;
  flex: 0 1;
  flex-direction: column;

  align-items: start;
  padding: ${padding.default};
  border-width: 2px;
  border-radius: ${border.radius.xxl};
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: ${colours.white};
  outline: none;
  transition: border 0.24s ease-in-out;
`;

const FileContainer = styled.div`
  padding: 0 0 15px 0;
  text-align: center;
`;

const FileImg = styled.img`
  border-radius: 50px;
  height: 100px;
  width: 100px;
  margin-bottom: 15px;
`;

function FileInput({ file, onChange, placeholder = 'Upload file' }) {
  function File({ src }) {
    return (
      <FileContainer>
        <FileImg src={src} height={100} width={100} alt={placeholder} />
        <br />
        <Button
          type='button'
          styled='remove'
          onClick={(e) => {
            if (window.confirm('Are you sure?')) {
              onChange({ type: 'file', file: null });
            }
          }}
        >
          Remove Image
        </Button>
      </FileContainer>
    );
  }

  const onDrop = useCallback((acceptedFiles) => {
    onChange({ type: 'file', file: acceptedFiles[0] });
  });

  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop });

  if (!file) {
    return (
      <Container
        {...getRootProps({
          isDragActive,
          isDragAccept,
          isDragReject,
          acceptedFiles,
        })}
      >
        <input {...getInputProps()} />
        {placeholder}
      </Container>
    );
  }
  return (
    <File src={typeof file === 'object' ? URL.createObjectURL(file) : file} />
  );
}

export default FileInput;
