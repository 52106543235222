import React from 'react'
import { Redirect } from 'react-router-dom'

import { Input } from '../../components/Inputs'
import { AuthBody, Subheading, SubText, StyledForm, TitleContainer, AuthContainer } from '../../style/styles'
import { Button } from '../../style/components/button'
import Validation, { required } from '../../lib/validation'
import { registerProvider } from '../../lib/api'

class VerifyProvider extends React.Component {
  constructor(props) {
    super(props)

    this.params = props.match.params

    this.state = {
      password: '',
      password_confirm: '',
      verification_string: this.params.verificationString,
      formErrors: {},
      redirect: false
    }

    this.validation = new Validation({
      password: [
        {type: required, message: 'Please enter your password'}
      ],
      password_confirm: [
        {type: required, message: 'Please confirm your password'}
      ]
    })
  }

  handleInput(target) {
    this.setState({
      ...this.state,
      [target.name]: target.value
    })
  }

  handleBlur(target) {
    this.validation.validateInput(target)

    this.setState({
      formErrors: this.validation.errors
    })
  }

  clearForm() {
    this.setState({
      ...this.state,
      password: '',
      password_confirm: '',
      formErrors: {}
    })
  }

  async submitForm(event) {
    event.preventDefault()

    if (this.state.password !== this.state.password_confirm) {
      // We need a real notification system
      alert("Your passwords do not match")
      return
    }

    try {
      let options = {
        password: this.state.password,
        password_confirm: this.state.password_confirm,
        verification_string: this.state.verification_string
      }

      let { data } = await registerProvider(options)

      // For now, force a login after we set the password
      // otherwise we have to return the entire provider object
      // on register, as it doesnt pull it in when we visit the provider
      // details page, etc
      // TODO: Return provider object, or fetch if it doesnt exist
      //
      // this.props.context.updateUser(data.user)
      // this.props.context.updateToken(data.token)

      this.setState({
        redirect: true,
      })
    } catch (err) {
      this.clearForm()
      console.error(err)
    }
  }

  render() {
    const { context } = this.props

    if (this.state.redirect) {
      return <Redirect push to={{
        pathname: "/",
      }} />
    }

    return (
      <AuthBody>
        <AuthContainer>
          <TitleContainer>
            <Subheading>Welcome to Play-Ex</Subheading>
            <SubText>Set a password to start using your account</SubText>
          </TitleContainer>
          <StyledForm onSubmit={ event => this.submitForm(event, context)}>
            <Input
              placeholder="Password"
              name="password"
              type="password"
              onChange={this.handleInput.bind(this)}
              onBlur={ this.handleBlur.bind(this) }
              value={ this.state.password }
              errors={ this.state.formErrors.password }
            />
            <Input
              placeholder="Password confirmation"
              name="password_confirm"
              type="password"
              onChange={this.handleInput.bind(this)}
              onBlur={ this.handleBlur.bind(this) }
              value={ this.state.password_confirm }
              errors={ this.state.formErrors.password_confirm }
            />
            <Button>Register</Button>
          </StyledForm>
        </AuthContainer>
      </AuthBody>
    )
  }
}

export default VerifyProvider
