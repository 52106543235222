import React from 'react';
import { InfluencerCard } from '../Influencers';
import { Detail, Contact, Description } from '../Providers';
import { hasRole } from '../../lib/permission';
import { IndexCard } from '.';
import { DetailsCard } from '../Individuals';
import { Button } from '../../style/components/button';
import { ModalForm } from '../../style/styles';
import { Modal } from '../Modal';
import { Select } from '../Inputs';
import { updateEnquiryStatus } from '../../lib/api';
import IFrame from '../../components/Providers/IFrame/IFrame';

export default class Enquiry extends React.Component {
  state = {
    renderStatusModal: false,
    statuses: [
      {
        label: 'Open',
        value: 2,
      },
      {
        label: 'Won',
        value: 3,
      },
      {
        label: 'Lost',
        value: 4,
      },
    ],
    chosenStatus: {},
    redirect: false,
  };

  renderProviderDetails() {
    if (!hasRole('service-provider')) {
      return (
        <div>
          <Detail flag={false} provider={this.props.provider} />
          <Contact
            email={this.props.provider.user.email}
            profile={this.props.provider.user.profile}
          />
          {hasRole('individual') ? (
            <Description description={this.props.provider.description} />
          ) : (
            <React.Fragment />
          )}
          <IFrame url={this.props.provider.iframe} />
        </div>
      );
    }
  }

  renderIndividualDetails() {
    if (!hasRole('individual')) {
      return (
        <div>
          <IndexCard
            status={this.props.enquiry.status}
            name={this.props.enquiry.user.profile.full_name}
            date={this.props.enquiry.created_at}
            product={this.props.enquiry.product.name}
          />
          <DetailsCard
            name={this.props.enquiry.full_name}
            profession={this.props.enquiry.profession}
            telephone={this.props.enquiry.telephone}
            email={this.props.enquiry.email}
          />
        </div>
      );
    }
  }

  renderInfluencer() {
    if (hasRole('admin') || hasRole('developer')) {
      if (
        this.props.influencer &&
        this.props.influencer.hasOwnProperty('full_name')
      ) {
        return (
          <InfluencerCard
            full_name={this.props.influencer.full_name}
            commission={this.props.influencer.commission}
          />
        );
      }
    }
  }

  showStatusModal() {
    this.setState({
      renderStatusModal: true,
    });
  }

  hideStatusModal() {
    this.setState({
      renderStatusModal: false,
    });
  }

  renderEditStatus() {
    if (
      (hasRole('admin') || hasRole('developer')) &&
      this.props.enquiry.status.id > 1
    ) {
      return (
        <Button onClick={this.showStatusModal.bind(this)}>Edit Status</Button>
      );
    }
  }

  async submitStatusForm(event) {
    event.preventDefault();

    await updateEnquiryStatus(
      this.props.enquiry.id,
      this.state.chosenStatus.value
    );

    // really hacky
    // TODO make this less shit
    this.setState({
      redirect: true,
    });

    this.hideStatusModal();
  }

  updateSelectedStatus(options) {
    if (options === null) {
      return;
    }

    this.setState({
      chosenStatus: options,
    });
  }

  renderStatusModal() {
    if (hasRole('admin') || hasRole('developer')) {
      if (this.state.renderStatusModal) {
        return (
          <Modal
            show={this.state.renderStatusModal}
            title='Update Status'
            close={this.hideStatusModal.bind(this)}
          >
            <ModalForm
              onSubmit={(event) => {
                this.submitStatusForm(event);
              }}
            >
              <Select
                options={this.state.statuses}
                name='status'
                value={this.state.chosenStatus}
                onChange={this.updateSelectedStatus.bind(this)}
                closeMenuOnSelect={true}
              />
              <Button>Update Status</Button>
              <Button
                styled='secondary'
                onClick={this.hideStatusModal.bind(this)}
              >
                Cancel
              </Button>
            </ModalForm>
          </Modal>
        );
      }
    }
  }

  render() {
    if (this.state.redirect) {
      // Oh god ignore the hack
      window.location.reload();
    }

    return (
      <div>
        {this.renderIndividualDetails()}
        {this.renderInfluencer()}
        {this.renderProviderDetails()}
        {this.renderEditStatus()}
        {this.renderStatusModal()}
      </div>
    );
  }
}
