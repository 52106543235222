import React from 'react'
import styled from 'styled-components'
import { InputRow, InputLabel, LabelText, ErrorText } from '../../style/components/input'
import { padding, colours, border } from '../../style'

const Select = styled.select`
  font-size: 12px;
  padding: ${padding.sm};
  border-radius: ${border.radius.md};
  border: 1px solid ${({error}) => error ? colours.danger : colours.background};
  font-family: inherit;
  background: #fff;
`

class CustomDropdown extends React.Component {
  constructor(props) {
    super(props)

    this.props = props
  }

  shouldComponentUpdate(nextProps) {
    return (
      this.props.value !== nextProps.value ||
      nextProps.errors !== this.props.errors ||
      nextProps.children !== this.props.children ||
      nextProps.disabled !== this.props.disabled
    )
  }

  renderErrorMessage(errorMessage) {
    if (!this.props.errors) return

    return (
      <ErrorText>
        { errorMessage }
      </ErrorText>
    )
  }

  render() {
    const {
      label,
      name,
      errors,
      onChange,
      disabled,
      value,
      data_testid
    } = this.props

    if (label) {
      return (
        <InputRow>
          <InputLabel htmlFor={ name }>
            <LabelText>{ label }</LabelText>
            <Select
              disabled={ disabled }
              value={ value }
              onChange={ onChange }
              name={ name }
              data-testid={ data_testid }
            >
              { this.props.children }
            </Select>
          </InputLabel>
          { this.renderErrorMessage(errors) }
        </InputRow>
      )
    }

    // TODO: update to use new select component
    return (
      <InputRow>
        <Select
          disabled={ disabled }
          value={ value }
          onChange={ onChange }
          name={ name }
          data-testid={ data_testid }
        >
          { this.props.children }
        </Select>
        { this.renderErrorMessage(errors) }
      </InputRow>
    )
  }
}

export default CustomDropdown
