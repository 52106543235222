import React from 'react';
import { Button } from '../../style/components/button';
import { StyledForm } from '../../style/styles';
import { Input, Select, FileInput } from '../Inputs';
import { orderAlphabetically } from '../../lib/helper';

export default class CreateOrUpdate extends React.Component {
  constructor(props) {
    super(props);

    this.updateFormState = props.updateFormState;
    this.submitForm = props.submitForm;
    this.cancel = props.cancel;
    this.handleBlur = props.handleBlur;
    this.updateSelectedProducts = props.updateSelectedProducts;
  }

  render() {
    const { location, provider, errors, products } = this.props;

    let newProducts = orderAlphabetically(products, 'name').map((product) => {
      if (product.children.length) {
        return {
          label: product.name,
          key: product.id,
          options: orderAlphabetically(product.children, 'name').map(
            (nProduct) => {
              return {
                label: nProduct.name,
                value: nProduct.id,
                key: nProduct.id,
              };
            }
          ),
        };
      } else {
        return {
          label: product.name,
          value: product.id,
          key: product.id,
        };
      }
    });
    console.log('provider', provider);
    return (
      <div>
        <StyledForm onSubmit={(event) => this.submitForm(event)}>
          <FileInput
            file={provider.logo || provider.logo_url}
            name='logo'
            placeholder='Upload company logo'
            onChange={this.updateFormState}
            onBlur={this.handleBlur}
          />
          <Input
            name='name'
            value={provider.name}
            placeholder='Company name'
            onChange={this.updateFormState}
            onBlur={this.handleBlur}
            errors={errors.name}
          />
          <Input
            name='website'
            value={provider.website}
            placeholder='Website'
            onChange={this.updateFormState}
            onBlur={this.handleBlur}
            errors={errors.website}
          />
          <Input
            name='telephone'
            value={provider.telephone}
            placeholder='Company phone number'
            onChange={this.updateFormState}
            onBlur={this.handleBlur}
            errors={errors.telephone}
          />

          <Input
            name='contact_name'
            value={provider.contact_name}
            placeholder='Contact name'
            onChange={this.updateFormState}
            onBlur={this.handleBlur}
            errors={errors.contact_name}
          />
          <Input
            name='contact_position'
            value={provider.contact_position}
            placeholder='Contact position'
            onChange={this.updateFormState}
            onBlur={this.handleBlur}
            errors={errors.contact_position}
          />
          <Input
            name='contact_email'
            value={provider.contact_email}
            placeholder='Contact email address'
            type='email'
            onChange={this.updateFormState}
            onBlur={this.handleBlur}
            errors={errors.contact_email}
          />
          <Input
            name='contact_telephone'
            value={provider.contact_telephone}
            placeholder='Contact phone number'
            type='tel'
            onChange={this.updateFormState}
            onBlur={this.handleBlur}
            errors={errors.contact_telephone}
          />
          <Input
            type='richeditor'
            name='description'
            data={provider.description}
            placeholder='Company description'
            onChange={this.updateFormState}
            onBlur={this.handleBlur}
            errors={errors.description}
          />
          <Input
            name='iframe'
            value={provider.iframe}
            placeholder='iFrame Link'
            onChange={this.updateFormState}
            onBlur={this.handleBlur}
            errors={errors.description}
          />
          <Select
            options={newProducts}
            isMulti
            closeMenuOnSelect={false}
            name='products'
            placeholder='Services'
            onChange={this.updateSelectedProducts.bind(this)}
            value={provider.products.map(({ id, name }) => {
              return {
                key: id,
                label: name,
                value: id,
              };
            })}
          />

          <Button styled='primary'>
            {location.hash.includes('create')
              ? 'Add provider'
              : 'Update provider'}
          </Button>
          <Button onClick={this.cancel} styled='secondary'>
            Cancel
          </Button>
        </StyledForm>
      </div>
    );
  }
}
