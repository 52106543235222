import React from 'react'
import Input from '../Inputs/Input'
import { StyledForm, UnstyledLink } from '../../style/styles'
import { Button } from '../../style/components/button'

export default class Create extends React.Component {
  constructor (props) {
    super(props)

    this.handleChange = props.handleChange
    this.handleBlur = props.handleBlur
    this.submitEnquiry = props.submitEnquiry
  }

  render () {
    const { enquiry, errors, productId } = this.props
    return (
      <StyledForm onSubmit={ this.submitEnquiry.bind(this) }>
        <Input
          name="full_name"
          value={ enquiry.full_name }
          placeholder="Full name"
          onChange={ this.handleChange.bind(this) }
          onBlur={ this.handleBlur.bind(this) }
          errors={ errors.full_name }
        />
        <Input
          name="email"
          type="email"
          value={ enquiry.email }
          placeholder="Email address"
          onChange={ this.handleChange.bind(this) }
          onBlur={ this.handleBlur.bind(this) }
          errors={ errors.email }
        />
        <Input
          name="telephone"
          type="tel"
          value={ enquiry.telephone }
          placeholder="Mobile"
          onChange={ this.handleChange.bind(this) }
          onBlur={ this.handleBlur.bind(this) }
          errors={ errors.telephone }
        />
        <Input
          name="profession"
          value={ enquiry.profession }
          placeholder="Profession"
          onChange={ this.handleChange.bind(this) }
          onBlur={ this.handleBlur.bind(this) }
          errors={ errors.profession }
        />
        <Button onClick={this.submitEnquiry.bind(this)}>Send enquiry</Button>
        <UnstyledLink to={ `/service/${productId}/providers` }>
            <Button styled='secondary'>Cancel</Button>
        </UnstyledLink>
      </StyledForm>
    )
  }
}
