let icons = [
  {
    value: '/img/car-insurance.svg',
    label: 'Car Insurance',
  },
  {
    value: '/img/career-ending-insurance.svg',
    label: 'Career Ending Insurance',
  },
  {
    value: '/img/life-insurance.svg',
    label: 'Life Insurance',
  },
  {
    value: '/img/income-protection-insurance.svg',
    label: 'Income Protection Insurance',
  },
  {
    value: '/img/travel-insurance.svg',
    label: 'Travel Insurance',
  },
  {
    value: '/img/home-insurance.svg',
    label: 'Home Insurance',
  },
  {
    value: '/img/first-time-buyer-mortgage.svg',
    label: 'First Time Buyer Mortage',
  },
  {
    value: '/img/remortgage.svg',
    label: 'Re-Mortgage',
  },
  {
    value: '/img/buy-to-let-mortgage.svg',
    label: 'Buy To Let Mortgage',
  },
  {
    value: '/img/commercial-mortgage.svg',
    label: 'Commercial Mortgage',
  },
  {
    value: '/img/accountancy.svg',
    label: 'Accountancy',
  },
  {
    value: '/img/financial-advice.svg',
    label: 'Financial Advice',
  },
  {
    value: '/img/currency-transfers.svg',
    label: 'Currency Transfers',
  },
  {
    value: '/img/self-build-mortgage.svg',
    label: 'Self Build Mortgage',
  },
  {
    value: '/img/mortgages.svg',
    label: 'Mortgages',
  },
  {
    value: '/img/insurance.svg',
    label: 'Insurance',
  },
  {
    value: '/img/contract-reviews.svg',
    label: 'Contract Reviews',
  },
  {
    value: '/img/image-rights.svg',
    label: 'Image Rights',
  },
  {
    value: '/img/investments.svg',
    label: 'Investments',
  },
  {
    value: '/img/pension-advice.svg',
    label: 'Pension Advice',
  },
  {
    value: '/img/tax-returns.svg',
    label: 'Tax Returns',
  },
  {
    value: '/img/legal-services.svg',
    label: 'Legal Services',
  },
  {
    value: '/img/bridging-loans.svg',
    label: 'Bridging Finance',
  },
  {
    value: '/img/auction-finance.svg',
    label: 'Auction Finance',
  },
  {
    value: '/img/sponsorship.svg',
    label: 'Sponsership',
  },
  {
    value: '/img/car-leasing.svg',
    label: 'Car Leasing',
  },
  {
    value: '/img/marketing-services.svg',
    label: 'Marketing Services',
  },
  {
    value: '/img/sponsorship-index.svg',
    label: 'Sponsorship Index By RocketYard',
  },
  {
    value: '/img/mental-fitness.svg',
    label: 'Mental Fitness',
  },
  {
    value: '/img/bookings-and-appearances.svg',
    label: 'Bookings & Appearances',
  },
  {
    value: '/img/careers-icon.svg',
    label: 'Careers',
  },
  {
    value: '/img/career-mentorship.svg',
    label: 'Career Mentorship',
  },
  {
    value: '/img/physio-icon.svg',
    label: 'Physio',
  },
  {
    value: '/img/car-sponsorship.svg',
    label: 'Car Sponsorship',
  },
];

let sorted = icons.sort((a, b) => a.value.localeCompare(b.value));
// github deploy
export default sorted;
