// For validaiton to work, the input's name needs to be the same as the object key defined in state.
// The validation object passed into the constructor needs to share it's keys with each input's name that are under validation.
// That key then takes an array of objects that has the keys; type and message.
// The value set to type must be set to one of the validation functions exported at the end of this page.

// Future enhancements would be to flatten the form object passed to validate form so we can validate nested arrays

class Validation {
  constructor(validation) {
    this.validation = validation

    this.errors = {}
  }

  validateForm = (form) => {
    this.errors = {}
    const fieldValidation = Object.keys(this.validation)

    for (let i = 0; i < fieldValidation.length; i++) {
      for (let j = 0; j < this.validation[fieldValidation[i]].length; j ++) {
        if (this.validateField(
          form[fieldValidation[i]],
          this.validation[fieldValidation[i]][j].type
        )) {
          continue
        }

        this.errors[fieldValidation[i]] = this.validation[fieldValidation[i]][j].message
        continue
      }
    }

    if (!this.isEmpty(this.errors)) {
      return false
    }

    return true
  }

  errors = this.errors
  resetErrors = () => {
    this.errors = {}
  }

  validateInput = (target) => {
    const field = target.name
    const value = target.value
    this.errors = {
      ...this.errors,
      [field]: ''
    }

    const fieldValidation = this.validation[field]
    if (fieldValidation) {
      for (let i = 0; i < fieldValidation.length; i ++) {
        if (this.validateField(value, fieldValidation[i].type)) {
          continue
        }

        this.errors[field] = this.validation[field][i].message
        continue
      }

      if (!this.isEmpty(this.errors)) {
        return false
      }
    }

    return true
  }

  validateField(value, validationRule) {
    return validationRule(value)
  }

  isEmpty(obj) {
    for(var key in obj) {
      if(obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }
}

export const required = (value) => value !== undefined && value !== null && value !== ''
export const toBeTruthy = (value) => typeof value === 'boolean' && value
export const toBeFalsy = (value) => typeof value === 'boolean' && !value
export const email = (value) => {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(value).toLowerCase());
}
export default Validation
