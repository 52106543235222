import 'react-app-polyfill/ie11';
import React from 'react'
import ReactDOM from 'react-dom'
import './style/App.css'
import 'array-flat-polyfill'
import App from './App'
import * as serviceWorker from './serviceWorker'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faChevronDown,
  faPencilAlt,
  faPlus,
  faEllipsisH,
  faTimes,
  faCar,
  faHome,
  faArchive,
  faChevronLeft,
  faExchangeAlt,
  faPlane,
  faStethoscope,
  faStar,
  faBuilding,
  faMobileAlt,
  faBriefcase,
  faUsers,
  faChevronRight,
  faChevronUp,
  faUser,
  faLock,
  faSignOutAlt,
  faCog,
  faCalculator,
  faHardHat,
  faCity,
  faCoins,
  faUmbrella,
  faKey,
  faHouseDamage,
  faHandHoldingUsd,
  faHeartbeat,
  faCarSide
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faChevronDown,
  faPencilAlt,
  faPlus,
  faEllipsisH,
  faTimes,
  faCar,
  faHome,
  faArchive,
  faChevronLeft,
  faExchangeAlt,
  faPlane,
  faStethoscope,
  faStar,
  faBuilding,
  faMobileAlt,
  faBriefcase,
  faUsers,
  faChevronRight,
  faChevronUp,
  faUser,
  faLock,
  faSignOutAlt,
  faCog,
  faCalculator,
  faHardHat,
  faCity,
  faCoins,
  faUmbrella,
  faKey,
  faHouseDamage,
  faHandHoldingUsd,
  faHeartbeat,
  faCarSide
)

if (!String.prototype.includes) {
  String.prototype.includes = function(search, start) {
    'use strict';

    if (search instanceof RegExp) {
      throw TypeError('first argument must not be a RegExp');
    }
    if (start === undefined) { start = 0; }
    return this.indexOf(search, start) !== -1;
  };
}

// https://tc39.github.io/ecma262/#sec-array.prototype.find
if (!Array.prototype.find) {
  Object.defineProperty(Array.prototype, 'find', {
    value: function(predicate) {
      // 1. Let O be ? ToObject(this value).
      if (this == null) {
        throw TypeError('"this" is null or not defined');
      }

      var o = Object(this);

      // 2. Let len be ? ToLength(? Get(O, "length")).
      var len = o.length >>> 0;

      // 3. If IsCallable(predicate) is false, throw a TypeError exception.
      if (typeof predicate !== 'function') {
        throw TypeError('predicate must be a function');
      }

      // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
      var thisArg = arguments[1];

      // 5. Let k be 0.
      var k = 0;

      // 6. Repeat, while k < len
      while (k < len) {
        // a. Let Pk be ! ToString(k).
        // b. Let kValue be ? Get(O, Pk).
        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
        // d. If testResult is true, return kValue.
        var kValue = o[k];
        if (predicate.call(thisArg, kValue, k, o)) {
          return kValue;
        }
        // e. Increase k by 1.
        k++;
      }

      // 7. Return undefined.
      return undefined;
    },
    configurable: true,
    writable: true
  });
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
