import styled from 'styled-components'
import { colours, padding, border, margin } from '../'
import { fonts } from '../typography'

export const Pill = styled.div`
  padding: ${padding.tiny} ${padding.md};
  background: ${colours.mediumGrey};
  border-radius: ${border.radius.lg};
  margin-right: ${margin.default};
  color: ${colours.white};
  font-family: ${fonts.family.silkasemibold};
  font-size: ${fonts.size.md};
  display: inline-block;
`
