import React from 'react';
import { UnstyledLink, Subheading } from '../../style/styles';
import IFrame from '../../components/Providers/IFrame/IFrame';
import styled from 'styled-components';
import { border, margin, colours } from '../../style';
import { Button } from '../../style/components/button';
import { Description } from '../Providers';

const ProviderBody = styled.div`
  border-radius: ${border.radius.default};
  background: ${colours.white};
  display: flex;
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    display: inline-block !important;
  }
  flex-direction: column;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
`;

const ProviderContainer = styled.div`
  position: relative;

  display: flex;
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    display: inline-block !important;
  }
  flex-direction: column;
  flex: 1;
  width: 100%;
`;

const EnquireButton = styled(Button)`
  margin-top: ${margin.lg};
  margin-bottom: ${margin.lg};
  cursor: pointer;
`;

export default class EnquiryPreview extends React.Component {
  constructor(props) {
    super(props);

    this.props = props;
    this.state = {
      provider: {},
      product: {
        category: {},
      },
    };
  }

  displayEnquireButton(provider, product) {
    if (this.props.hideButton) {
      return;
    }

    return (
      <UnstyledLink
        to={`/service/${product.id}/providers/${provider.id}/enquiry`}
      >
        <EnquireButton>Enquire now</EnquireButton>
      </UnstyledLink>
    );
  }

  render() {
    const { provider, product } = this.props;

    return (
      <div>
        <ProviderBody>
          <ProviderContainer>
            <Subheading>{provider.name}</Subheading>
            <Description description={provider.description} />
          </ProviderContainer>
        </ProviderBody>
        <IFrame url={provider.iframe} />

        {this.displayEnquireButton(provider, product)}
      </div>
    );
  }
}
