
export const fonts = {
  size: {
    default: '14px',
    heading: '32px',
    subheading: '17px',
    sm: '10px',
    md: '12px',
    lg: '14px',
    xl: '16px',
    xxl: '18px',
    xxxl: '20px',
  },
  weight: {
    default: '400',
    regular: '400',
    semibold: '500',
    boldish: '600',
    bold: '700'
  },
  family: {
    silkasemibold: `'silkasemibold'`
  }
}
