import React from 'react'
import { Container, CardContent } from '../../style/components/card'
import { InfoContainer } from '../../style/styles'

const InfluencerCard = ({ full_name, commission }, ...rest) => {
  if (full_name) {
    return (
      <Container>
        <CardContent>
          <InfoContainer>
            <div>Referrer:</div>
            <span>{ full_name }</span>
          </InfoContainer>
          <InfoContainer>
            <div>Fee %:</div>
            <span>{ commission + '%' }</span>
          </InfoContainer>
        </CardContent>
      </Container>
    )
  }

  return <div />
}

export default InfluencerCard
