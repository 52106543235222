import React from 'react'
import styled from 'styled-components'
import { hasPermission } from '../../lib/permission'
import { colours, fonts, devices, padding } from '../../style'
import { Link } from 'react-router-dom'
import { checkActiveTab } from '../../lib/helper'

const MenuOptionStyling = `
  display: flex;

  @media only screen and (-ms-high-contrast:active), (-ms-high-contrast:none) {
    display: inline-block !important;
  }

  padding: 12.5px ${padding.md} 12.5px 25px;

  @media ${devices.tabletsm} {
    padding: ${padding.md} ${padding.md} ${padding.md} 16px;
  }
  font-size: ${fonts.size.lg};
  align-items: center;

  color: ${colours.black};
  text-decoration: none;
  flex-flow: row;
`

const MenuOption = styled.div`
  ${MenuOptionStyling}
`

const MenuLinkStyling = styled(Link)`
  ${MenuOptionStyling}
`

const MenuText = styled.div`
  font-size: ${fonts.size.xxl};
  width: 75%
  @media ${devices.tabletsm} {
    font-size: ${fonts.size.xl};
  }
`

const SVGWrapper = styled.div`
  text-align: center;

  svg {
    height: 28px;
    width: 28px;
    margin-right: 12px;
    @media ${devices.tabletsm} {
      height: 16px;
      width: 16px;
      margin-right: 8px;
    }
    font-size: 20px;
    fill: ${({fill}) => fill ? fill : colours.navy };
    & path {
      fill: ${({fill}) => fill ? fill : colours.navy } !important;
    }
  }
`

export default class MenuLink extends React.Component {
  renderLinkContent() {
    const { tab,  SVG, label, active } = this.props
    return (
      <React.Fragment>
        <SVGWrapper fill={
          active || checkActiveTab(tab)
        }>
          <SVG />
        </SVGWrapper>
        <MenuText>{ label }</MenuText>
      </React.Fragment>
    )
  }

  renderLinkOrDiv() {
    const { onClick, to } = this.props
    if (to) {
      return (
        <MenuLinkStyling
          onClick={ () => onClick() }
          to={ to }
        >
          { this.renderLinkContent() }
        </MenuLinkStyling>
      )
    }

    return (
      <MenuOption
        onClick={ () => onClick() }
      >
        { this.renderLinkContent() }
      </MenuOption>
    )
  }

  render() {
    const { permission } = this.props
    return !permission || hasPermission(permission) ?
      this.renderLinkOrDiv() :
      <React.Fragment />
  }
}
