const countyList = [
  {
    name: 'county_of_residence',
    label: 'Avon',
    value: 'Avon',
    abbreviation: 'AVN',
    country: 'England',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'BDF',
    country: 'England',
    label: 'Bedfordshire',
    value: 'Bedfordshire',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'BRK',
    country: 'England',
    label: 'Berkshire',
    value: 'Berkshire',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'BKM',
    country: 'England',
    label: 'Buckinghamshire',
    value: 'Buckinghamshire',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'CAM',
    country: 'England',
    label: 'Cambridgeshire',
    value: 'Cambridgeshire',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'CHS',
    country: 'England',
    label: 'Cheshire',
    value: 'Cheshire',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'CLV',
    country: 'England',
    label: 'Cleveland',
    value: 'Cleveland',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'CON',
    country: 'England',
    label: 'Cornwall',
    value: 'Cornwall',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'CMA',
    country: 'England',
    label: 'Cumbria',
    value: 'Cumbria',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'DBY',
    country: 'England',
    label: 'Derbyshire',
    value: 'Derbyshire',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'DEV',
    country: 'England',
    label: 'Devon',
    value: 'Devon',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'DOR',
    country: 'England',
    label: 'Dorset',
    value: 'Dorset',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'DUR',
    country: 'England',
    label: 'Durham',
    value: 'Durham',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'SXE',
    country: 'England',
    label: 'East Sussex',
    value: 'East Sussex',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'ESS',
    country: 'England',
    label: 'Essex',
    value: 'Essex',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'GLS',
    country: 'England',
    label: 'Gloucestershire',
    value: 'Gloucestershire',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'HAM',
    country: 'England',
    label: 'Hampshire',
    value: 'Hampshire',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'HEF',
    country: 'England',
    label: 'Herefordshire',
    value: 'Herefordshire',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'HRT',
    country: 'England',
    label: 'Hertfordshire',
    value: 'Hertfordshire',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'IOW',
    country: 'England',
    label: 'Isle of Wight',
    value: 'Isle of Wight',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'KEN',
    country: 'England',
    label: 'Kent',
    value: 'Kent',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'LAN',
    country: 'England',
    label: 'Lancashire',
    value: 'Lancashire',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'LEI',
    country: 'England',
    label: 'Leicestershire',
    value: 'Leicestershire',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'LIN',
    country: 'England',
    label: 'Lincolnshire',
    value: 'Lincolnshire',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'LDN',
    country: 'England',
    label: 'London',
    value: 'London',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'MSY',
    country: 'England',
    label: 'Merseyside',
    value: 'Merseyside',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'NFK',
    country: 'England',
    label: 'Norfolk',
    value: 'Norfolk',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'NTH',
    country: 'England',
    label: 'Northamptonshire',
    value: 'Northamptonshire',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'NBL',
    country: 'England',
    label: 'Northumberland',
    value: 'Northumberland',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'NYK',
    country: 'England',
    label: 'North Yorkshire',
    value: 'North Yorkshire',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'NTT',
    country: 'England',
    label: 'Nottinghamshire',
    value: 'Nottinghamshire',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'OXF',
    country: 'England',
    label: 'Oxfordshire',
    value: 'Oxfordshire',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'RUT',
    country: 'England',
    label: 'Rutland',
    value: 'Rutland',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'SAL',
    country: 'England',
    label: 'Shropshire',
    value: 'Shropshire',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'SOM',
    country: 'England',
    label: 'Somerset',
    value: 'Somerset',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'SYK',
    country: 'England',
    label: 'South Yorkshire',
    value: 'South Yorkshire',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'STS',
    country: 'England',
    label: 'Staffordshire',
    value: 'Staffordshire',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'SFK',
    country: 'England',
    label: 'Suffolk',
    value: 'Suffolk',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'SRY',
    country: 'England',
    label: 'Surrey',
    value: 'Surrey',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'TWR',
    country: 'England',
    label: 'Tyne and Wear',
    value: 'Tyne and Wear',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'WAR',
    country: 'England',
    label: 'Warwickshire',
    value: 'Warwickshire',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'WMD',
    country: 'England',
    label: 'West Midlands',
    value: 'West Midlands',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'SXW',
    country: 'England',
    label: 'West Sussex',
    value: 'West Sussex',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'WYK',
    country: 'England',
    label: 'West Yorkshire',
    value: 'West Yorkshire',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'WIL',
    country: 'England',
    label: 'Wiltshire',
    value: 'Wiltshire',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'WOR',
    country: 'England',
    label: 'Worcestershire',
    value: 'Worcestershire',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'CWD',
    country: 'Wales',
    label: 'Clwyd',
    value: 'Clwyd',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'DFD',
    country: 'Wales',
    label: 'Dyfed',
    value: 'Dyfed',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'GNT',
    country: 'Wales',
    label: 'Gwent',
    value: 'Gwent',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'GWN',
    country: 'Wales',
    label: 'Gwynedd',
    value: 'Gwynedd',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'MGM',
    country: 'Wales',
    label: 'Mid Glamorgan',
    value: 'Mid Glamorgan',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'POW',
    country: 'Wales',
    label: 'Powys',
    value: 'Powys',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'SGM',
    country: 'Wales',
    label: 'South Glamorgan',
    value: 'South Glamorgan',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'WGM',
    country: 'Wales',
    label: 'West Glamorgan',
    value: 'West Glamorgan',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'ABD',
    country: 'Scotland',
    label: 'Aberdeenshire',
    value: 'Aberdeenshire',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'ANS',
    country: 'Scotland',
    label: 'Angus',
    value: 'Angus',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'ARL',
    country: 'Scotland',
    label: 'Argyll',
    value: 'Argyll',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'AYR',
    country: 'Scotland',
    label: 'Ayrshire',
    value: 'Ayrshire',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'BAN',
    country: 'Scotland',
    label: 'Banffshire',
    value: 'Banffshire',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'BEW',
    country: 'Scotland',
    label: 'Berwickshire',
    value: 'Berwickshire',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'BUT',
    country: 'Scotland',
    label: 'Bute',
    value: 'Bute',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'CAI',
    country: 'Scotland',
    label: 'Caithness',
    value: 'Caithness',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'CLK',
    country: 'Scotland',
    label: 'Clackmannanshire',
    value: 'Clackmannanshire',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'DGY',
    country: 'Scotland',
    label: 'Dumfriesshire',
    value: 'Dumfriesshire',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'DNB',
    country: 'Scotland',
    label: 'Dunbartonshire',
    value: 'Dunbartonshire',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'ELN',
    country: 'Scotland',
    label: 'East Lothian',
    value: 'East Lothian',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'FIF',
    country: 'Scotland',
    label: 'Fife',
    value: 'Fife',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'INV',
    country: 'Scotland',
    label: 'Inverness-shire',
    value: 'Inverness-shire',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'KCD',
    country: 'Scotland',
    label: 'Kincardineshire',
    value: 'Kincardineshire',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'KRS',
    country: 'Scotland',
    label: 'Kinross-shire',
    value: 'Kinross-shire',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'KKD',
    country: 'Scotland',
    label: 'Kirkcudbrightshire',
    value: 'Kirkcudbrightshire',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'LKS',
    country: 'Scotland',
    label: 'Lanarkshire',
    value: 'Lanarkshire',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'MLN',
    country: 'Scotland',
    label: 'Midlothian',
    value: 'Midlothian',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'MOR',
    country: 'Scotland',
    label: 'Moray',
    value: 'Moray',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'NAI',
    country: 'Scotland',
    label: 'Nairnshire',
    value: 'Nairnshire',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'OKI',
    country: 'Scotland',
    label: 'Orkney',
    value: 'Orkney',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'PEE',
    country: 'Scotland',
    label: 'Peeblesshire',
    value: 'Peeblesshire',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'PER',
    country: 'Scotland',
    label: 'Perthshire',
    value: 'Perthshire',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'RFW',
    country: 'Scotland',
    label: 'Renfrewshire',
    value: 'Renfrewshire',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'ROC',
    country: 'Scotland',
    label: 'Ross-shire',
    value: 'Ross-shire',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'ROX',
    country: 'Scotland',
    label: 'Roxburghshire',
    value: 'Roxburghshire',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'SEL',
    country: 'Scotland',
    label: 'Selkirkshire',
    value: 'Selkirkshire',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'SHI',
    country: 'Scotland',
    label: 'Shetland',
    value: 'Shetland',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'STI',
    country: 'Scotland',
    label: 'Stirlingshire',
    value: 'Stirlingshire',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'SUT',
    country: 'Scotland',
    label: 'Sutherland',
    value: 'Sutherland',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'WLN',
    country: 'Scotland',
    label: 'West Lothian',
    value: 'West Lothian',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'WIG',
    country: 'Scotland',
    label: 'Wigtownshire',
    value: 'Wigtownshire',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'ANT',
    country: 'Northern Ireland',
    label: 'Antrim',
    value: 'Antrim',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'ARM',
    country: 'Northern Ireland',
    label: 'Armagh',
    value: 'Armagh',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'DOW',
    country: 'Northern Ireland',
    label: 'Down',
    value: 'Down',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'FER',
    country: 'Northern Ireland',
    label: 'Fermanagh',
    value: 'Fermanagh',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'LDY',
    country: 'Northern Ireland',
    label: 'Londonderry',
    value: 'Londonderry',
  },
  {
    name: 'county_of_residence',
    abbreviation: 'TYR',
    country: 'Northern Ireland',
    label: 'Tyrone',
    value: 'Tyrone',
  },
];

export default countyList;
