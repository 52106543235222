import React from 'react'
import Truncate from 'react-truncate'

import { Subheading, ProviderImg, UnstyledAnchor } from '../../style/styles'
import { Container, CardContent } from '../../style/components/card'
import logo from '../../assets/Play-Ex-Icon.png'

export default class Detail extends React.Component {
  render() {
    const { provider } = this.props
    return (
      <Container>
        <ProviderImg src={ provider.logo_url || logo } />
        <CardContent>
          <Subheading data-testid="providerName">{ provider.name }</Subheading>
          <UnstyledAnchor href={provider.website} target="_BLANK"><Truncate width={250}>{ provider.website }</Truncate></UnstyledAnchor>
          <div>{ provider.telephone }</div>
        </CardContent>
      </Container>
    )
  }
}
