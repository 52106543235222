import React from 'react'
import { Container, ReferralLink } from '../../style/components/card'
import { PanelBody } from '../../style/styles'
import { Button } from '../../style/components/button'
import NameCard from './NameCard'
import Contact from './Contact'
import Commissions from './Commission'

export default class InfluencerDetails extends React.Component {
  state = {
    influencer: {
      full_name: '',
      mobile_number: '',
      email: '',
      facebook_url: '',
      address_1: '',
      address_2: '',
      city: '',
      postcode: '',
      start_date: '',
      end_date: '',
      users: []
    },
    editing: false
  }

  componentDidMount() {
    const { influencer, match } = this.props
    if (match.params.id === 'create') {
      this.setState({ editing: true })
    } else {
      this.setState({
        influencer
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.influencer.id !== prevProps.influencer.id) {
      if (this.props.influencer.id === 'create') {
        this.setState({
          influencer: {
            full_name: '',
            mobile_number: '',
            email: '',
            facebook_url: '',
            address_1: '',
            address_2: '',
            city: '',
            postcode: '',
            start_date: '',
            end_date: '',
            users: []
          },
          editing: true
        })
      } else {
        this.setState({
          influencer: this.props.influencer,
          editing: false
        })
      }
    }
  }

  renderButtons() {
    const { displayEditForm, match } = this.props
    return (
      <Button onClick={ () => displayEditForm() } data-testid="saveProvider" styled="secondary">
        { match.params.id === 'create' ? 'Add influencer' : 'Edit influencer' }
      </Button>
    )
  }

  renderReferralUrl() {
    if (!this.state.editing) {
      return (
        <Container>
          <ReferralLink target="_blank" href={ this.state.influencer.referral_url }>
            { this.state.influencer.referral_url }
          </ReferralLink>
        </Container>
      )
    }
    return <React.Fragment />
  }

  render() {
    const { influencer } = this.state

    return (
      <PanelBody>
        <NameCard
          influencer={ influencer }
          full_name={ influencer.full_name }
          users={ influencer.users }
          showFlag
        />
        {this.renderReferralUrl()}
        <Contact
          mobile_number={ influencer.mobile_number }
          email={ influencer.email }
          twitter_handle={ influencer.twitter_handle }
        />
        <Commissions
          start_date={ influencer.start_date }
          end_date={ influencer.end_date }
          commission={ influencer.commission }
        />
        { this.renderButtons() }
      </PanelBody>
    )
  }
}
