import axios from 'axios';

let API_URL =
  `${process.env.REACT_APP_API_URL}/api` || 'https://playex.co.uk/api';

const processSignIn = async (credentials) => {
  try {
    let { data } = await axios.post(`${API_URL}/auth/login`, credentials);

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const updateClientProfile = async (details, token) => {
  let keys = Object.keys(details);
  let formData = new FormData();
  for (let i = 0; i < keys.length; i++) {
    if (keys[i] === 'contact') {
      formData.append(keys[i], JSON.stringify(details[keys[i]]));
    } else {
      formData.append(keys[i], details[keys[i]]);
    }
  }

  const { data } = await axios.post(`${API_URL}/client`, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

const updateEmail = async (email, token) => {
  // Update user
  const { data } = await axios.post(
    `${API_URL}/user`,
    { email: email },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return data;
};

const verifyEmail = async (verification) => {
  const { data } = await axios.post(`${API_URL}/auth/verify`, verification);

  return data;
};

const registerUser = async (user, referralCode = null) => {
  let route = `${API_URL}/auth/register`;

  if (referralCode !== null) {
    route = `${route}?referral=${referralCode}`;
  }

  const { data } = await axios.post(route, user);

  return data;
};

const fetchProviders = async () => {
  const { data } = await axios.get(`${API_URL}/provider`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    },
  });

  return data;
};

const fetchProvider = async (id) => {
  const { data } = await axios.get(`${API_URL}/provider/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    },
  });

  return data;
};

const getCategories = async () => {
  const { data } = await axios.get(`${API_URL}/product`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    },
  });

  return data;
};

const updateCategory = async (category) => {
  const { data } = await axios.post(
    `${API_URL}/product/${category.id}`,
    category,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('jwt')}`,
      },
    }
  );

  return data;
};

const createCategory = async (category) => {
  const { data } = await axios.post(`${API_URL}/product`, category, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    },
  });

  return data;
};

const deleteCategory = async (category) => {
  const { data } = await axios.delete(`${API_URL}/product/${category.id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    },
  });

  return data;
};
const createProduct = async (product) => {
  const { data } = await axios.post(`${API_URL}/product`, product, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    },
  });

  return data;
};
const getCategory = async (id) => {
  const { data } = await axios.get(`${API_URL}/product/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    },
  });

  return data;
};
const updateProduct = async (product) => {
  const { data } = await axios.post(
    `${API_URL}/product/${product.id}`,
    product,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('jwt')}`,
      },
    }
  );

  return data;
};
const deleteProduct = async (product) => {
  const { data } = await axios.delete(`${API_URL}/product/${product.id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    },
  });

  return data;
};

const updateProvider = async (provider) => {
  let keys = Object.keys(provider);
  let formData = new FormData();
  for (let i = 0; i < keys.length; i++) {
    if (keys[i] === 'contact') {
      formData.append(keys[i], JSON.stringify(provider[keys[i]]));
    } else {
      formData.append(keys[i], provider[keys[i]]);
    }
  }

  const { data } = await axios.post(
    `${API_URL}/provider/${provider.id}`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('jwt')}`,
      },
    }
  );

  return data;
};

const getProducts = async (categoryId = null) => {
  let url = `${API_URL}/product`;

  if (categoryId) {
    url = `${API_URL}/category/${categoryId}/products`;
  }

  const { data } = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    },
  });

  return data;
};

const getProduct = async (productId) => {
  const { data } = await axios.get(`${API_URL}/product/${productId}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    },
  });

  return data;
};

const createProvider = async (provider) => {
  let keys = Object.keys(provider);
  let formData = new FormData();

  for (let i = 0; i < keys.length; i++) {
    if (keys[i] === 'contact') {
      formData.append(keys[i], JSON.stringify(provider[keys[i]]));
    } else {
      formData.append(keys[i], provider[keys[i]]);
    }
  }

  const { data } = await axios.post(`${API_URL}/provider`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    },
  });

  return data;
};

const attachProduct = async (provider, product) => {
  const { data } = await axios.post(
    `${API_URL}/provider/${provider.id}/product`,
    product,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('jwt')}`,
      },
    }
  );

  return data;
};

const detachProduct = async (provider, product) => {
  const { data } = await axios.delete(
    `${API_URL}/provider/${provider.id}/product`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('jwt')}`,
      },
      data: product,
    }
  );

  return data;
};

const getIndividuals = async () => {
  const { data } = await axios.get(`${API_URL}/individual`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    },
  });

  return data;
};

const getIndividual = async (individualId) => {
  const { data } = await axios.get(`${API_URL}/individual/${individualId}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    },
  });

  return data;
};

const fetchEnquiry = async (leadId) => {
  const { data } = await axios.get(`${API_URL}/enquiry/${leadId}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    },
  });

  return data;
};

const getEnquiries = async () => {
  const { data } = await axios.get(`${API_URL}/enquiry`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    },
  });

  return data;
};

const createEnquiry = async (details) => {
  const { data } = await axios.post(`${API_URL}/enquiry`, details, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    },
  });

  return data;
};

const updatePassword = async (passwords) => {
  const { data } = await axios.post(`${API_URL}/user/password`, passwords, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    },
  });

  return data;
};

const getInfluencer = async (id) => {
  const { data } = await axios.get(`${API_URL}/influencer/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    },
  });

  return data;
};

const getAllInfluencers = async (id) => {
  const { data } = await axios.get(`${API_URL}/influencer`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    },
  });

  return data;
};

const createInfluencer = async (influencer) => {
  const { data } = await axios.post(`${API_URL}/influencer`, influencer, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    },
  });

  return data;
};

const updateInfluencer = async (influencer) => {
  const { data } = await axios.post(
    `${API_URL}/influencer/${influencer.id}`,
    influencer,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('jwt')}`,
      },
    }
  );

  return data;
};

const checkIfUserVerified = async () => {
  const { data } = await axios.get(`${API_URL}/user/verified`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    },
  });

  return data;
};

const fetchUserProfile = async () => {
  const { data } = await axios.get(`${API_URL}/user/profile`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    },
  });

  return data;
};

const inviteProvider = async (providerId) => {
  const { data } = await axios.get(`${API_URL}/provider/${providerId}/invite`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    },
  });

  return data;
};

const updateEnquiryStatus = async (enquiryId, statusId) => {
  const { data } = await axios.post(
    `${API_URL}/enquiry/${enquiryId}/status`,
    {
      status_id: statusId,
    },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('jwt')}`,
      },
    }
  );

  return data;
};

const registerProvider = async (options) => {
  let route = `${API_URL}/auth/verify/provider`;

  const { data } = await axios.post(route, options);

  return data;
};

const resendVerificationEmail = async (id) => {
  const { data } = await axios.post(
    `${API_URL}/auth/verify/resend`,
    {
      user_id: id,
    },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('jwt')}`,
      },
    }
  );

  return data;
};

const forgotPassword = async (email) => {
  const { data } = await axios.post(`${API_URL}/auth/forgot_password`, {
    email,
  });

  return data;
};

const resetPassword = async (passwordObject, reset_token) => {
  const { data } = await axios.post(
    `${API_URL}/auth/password/${reset_token}`,
    passwordObject
  );

  return data;
};

const checkResetToken = async (reset_token) => {
  const { data } = await axios.get(`${API_URL}/auth/password/${reset_token}`);

  return data;
};

export {
  processSignIn,
  updateClientProfile,
  verifyEmail,
  registerUser,
  fetchProviders,
  fetchProvider,
  createCategory,
  getCategories,
  updateCategory,
  deleteCategory,
  createProduct,
  getCategory,
  updateProduct,
  deleteProduct,
  getProducts,
  getProduct,
  updateProvider,
  createProvider,
  updateEmail,
  attachProduct,
  detachProduct,
  getIndividuals,
  getIndividual,
  fetchEnquiry,
  createEnquiry,
  getEnquiries,
  updatePassword,
  getInfluencer,
  getAllInfluencers,
  createInfluencer,
  updateInfluencer,
  checkIfUserVerified,
  fetchUserProfile,
  inviteProvider,
  registerProvider,
  updateEnquiryStatus,
  resendVerificationEmail,
  forgotPassword,
  resetPassword,
  checkResetToken,
};
