import React from 'react';
import moment from 'moment';
import { UnstyledLink, ScreenBody } from '../../style/styles';
import { hasRole } from '../../lib/permission';
import { fetchEnquiry, getEnquiries } from '../../lib/api';
import { DesktopTable } from '../../style/layouts';
import { IndexCard, Enquiry } from '../../components/Enquiries';
import { orderNumerically } from '../../lib/helper';
import { widths } from '../../style';

export default class LeadsIndex extends React.Component {
  constructor(props) {
    super(props);

    this.props = props;
    this.state = {
      tableColumns: [],
      tableData: [],
      enquiries: [],
      enquiry: {
        product: {},
        user: {
          profile: {},
          influencer: {},
        },
        status: {},
        provider: {
          user: {
            profile: {},
          },
        },
      },
      enquiryLoaded: false,
    };
  }

  async componentDidMount() {
    await this.getEnquiry();
    await this.getEnquiries();

    if (!this.props.context.isDeviceMobile()) {
      this.props.context.updatePageTitle('Leads');
    }

    this.getEnquiries();
  }

  componentDidUpdate(prevProps) {
    const { match, context } = this.props;
    if (match.params.id !== prevProps.match.params.id) {
      this.getEnquiry();

      if (context.isDeviceMobile() && match.params.id) {
        context.displayBCOnMobile();
        context.setBreadcrumb([
          { text: 'Home', to: '/' },
          { text: 'Leads', to: `/leads` },
        ]);
      } else {
        context.hideBCOnMobile();
        context.setBreadcrumb();
      }
    }
  }

  componentWillUnmount() {
    this.props.context.setBreadcrumb();
    this.props.context.hideBCOnMobile();
  }

  async getEnquiry() {
    const { id } = this.props.match.params;
    if (id) {
      try {
        const { data } = await fetchEnquiry(id);

        await this.setState({
          enquiry: data,
          enquiryLoaded: true,
        });
      } catch (e) {
        console.log(e.message);
      }
    }
  }

  async getEnquiries() {
    let tableColumns;
    try {
      const { data } = await getEnquiries();
      orderNumerically(data, 'id', true);

      if (hasRole('admin') || hasRole('developer')) {
        tableColumns = [
          {
            name: 'Date',
            selector: 'date',
            sortable: true,
            width: widths.table.dateStatusColumn,
          },
          { name: 'Name', selector: 'name', sortable: true },
          { name: 'Service', selector: 'product', sortable: true },
          { name: 'Provider', selector: 'provider', sortable: true },
          {
            name: 'Status',
            selector: 'status',
            sortable: true,
            width: widths.table.dateStatusColumn,
          },
        ];
        this.props.context.updatePageTitle('Leads');
      } else {
        tableColumns = [
          {
            name: 'Date',
            selector: 'date',
            sortable: true,
            width: widths.table.dateStatusColumn,
          },
          { name: 'Name', selector: 'name', sortable: true },
          { name: 'Service', selector: 'product', sortable: true },
          {
            name: 'Status',
            selector: 'status',
            sortable: true,
            width: widths.table.dateStatusColumn,
          },
        ];
        if (!this.props.context.isDeviceMobile()) {
          this.props.context.updatePageTitle('Leads');
        } else {
          this.props.context.updateClickableHeaders([
            {
              title: data.length > 0 ? `Leads (${data.length})` : 'Leads',
              active: true,
              to: '/leads',
            },
            {
              title: 'Details',
              active: false,
              to: '/provider',
            },
          ]);
        }
      }

      this.setState({
        enquiries: data,
        tableData: data.map((enquiry, index) => {
          let {
            id,
            full_name,
            provider,
            product,
            created_at,
            status,
          } = enquiry;

          if (!hasRole('admin') || hasRole('developer')) {
            if (
              status.display_name === 'Won' ||
              status.display_name === 'Lost'
            ) {
              status = {
                id: status.id,
                display_name: 'Closed',
              };
            }
          }

          return {
            id: id,
            date: moment(created_at).format('DD/MM/YYYY'),
            name: full_name,
            product: product.name,
            provider: provider.name,
            status: status.display_name,
            data: enquiry,
          };
        }),
        tableColumns,
      });
    } catch (e) {
      console.log(e);
    }
  }

  handleRowClick(row) {
    this.props.history.push(`/leads/${row.id}`);
  }

  renderEnquiry() {
    if (!this.state.enquiryLoaded) return <React.Fragment />;
    return (
      <Enquiry
        profile={this.state.enquiry.user.profile}
        enquiry={this.state.enquiry}
        provider={this.state.enquiry.provider}
        influencer={this.state.enquiry.user.influencer || null}
      />
    );
  }

  render() {
    const { context, match } = this.props;

    if (!context.isDeviceMobile()) {
      return (
        <DesktopTable
          displaySidePanel={match.params.id}
          tableData={this.state.tableData}
          tableColumns={this.state.tableColumns}
          onRowClicked={this.handleRowClick.bind(this)}
          tableFilterOptions={[{ value: 1, text: 'All leads' }]}
          tableName='leads'
          context={this.props.context}
        >
          {this.renderEnquiry()}
        </DesktopTable>
      );
    }

    if (match.params.id) {
      return (
        <ScreenBody>
          <div>{this.renderEnquiry()}</div>
        </ScreenBody>
      );
    }

    return (
      <ScreenBody>
        <div>
          {this.state.enquiries.map((enquiry, index) => {
            return (
              <UnstyledLink key={index} to={`/leads/${enquiry.id}`}>
                <IndexCard
                  status={enquiry.status}
                  name={enquiry.user.profile.full_name}
                  date={enquiry.created_at}
                  product={enquiry.product.name}
                />
              </UnstyledLink>
            );
          })}
        </div>
      </ScreenBody>
    );
  }
}
