import React from 'react';
import { InputRow } from '../../style/components/input';
import Select from 'react-select';
import { border, colours, fonts, padding } from '../../style';

class CustomSelect extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    const {
      options,
      value,
      isMulti,
      isSearchable,
      closeMenuOnSelect,
      name,
      onChange,
      placeholder,
    } = this.props;

    const customStyles = {
      control: (provided, state) => ({
        ...provided,
        borderRadius: border.radius.xxl,
        border: `1px solid ${colours.background}`,
      }),
      menuList: (provided, state) => ({
        ...provided,
        borderRadius: border.radius.default,
      }),
      multiValue: (provided, state) => ({
        ...provided,
        background: colours.mediumGrey,
        borderRadius: border.radius.lg,
        color: colours.white,
      }),
      multiValueLabel: (provided, state) => ({
        ...provided,
        paddingLeft: padding.md,
        color: colours.white,
        fontFamily: fonts.family.silkasemibold,
        fontSize: fonts.size.md,
      }),
      multiValueRemove: (provided, state) => ({
        ...provided,
        borderRadius: `0 ${border.radius.default} ${border.radius.default} 0`,
        '&:hover': {
          background: 'rgba(0, 0, 0, 0.5)',
          cursor: 'pointer',
          color: colours.white,
        },
      }),
      clearIndicator: (provided, state) => ({
        ...provided,
        '&:hover': {
          cursor: 'pointer',
        },
      }),
    };

    return (
      <InputRow>
        <Select
          styles={customStyles}
          options={options}
          value={value}
          isMulti={isMulti || false}
          isSearchable={isSearchable || false}
          closeMenuOnSelect={closeMenuOnSelect || false}
          name={name}
          onChange={onChange}
          placeholder={placeholder || 'Select...'}
          menuPlacement='auto'
        />
      </InputRow>
    );
  }
}

export default CustomSelect;
