import React from 'react'
import {
  SignIn,
  UnverifiedEmail,
  VerifyEmail,
  VerifyProvider,
  Register,
  ResetPassword,
  ForgotPassword
} from '../views/Auth'
import { Route} from "react-router-dom"

import { PageLayout } from '../style/styles'

class AuthRoutes extends React.Component {
  constructor(props) {
    super(props)

    this.props = props

    this.state = {
      displayMenu: false
    }
  }

  toggleMenu = () => {
    this.setState({
      displayMenu: !this.state.displayMenu
    })
  }

  render() {
    const { context } = this.props
    return (
      <PageLayout>
        <Route
          exact
          path="/auth/verify/provider/:verificationString"
          render={ (props) => <VerifyProvider {...props } context={ context } /> }
        />
        <Route
          exact
          path="/auth/verify/:verificationString"
          render={ (props) => <VerifyEmail {...props} context={ context } /> }
          key='verification'
        />
        {/* Render if no token */}
        <Route
          key='login'
          exact
          path="/auth/login"
          render={(props) => <SignIn context={ context } {...props} /> }
        />
        <Route
          key='register'
          exact
          path="/auth/register"
          render={(props) =>  <Register context={ context } {...props} /> }
        />
        <Route
          key='unverified'
          exact
          path="/auth/email/unverified"
          render={(props) => <UnverifiedEmail context={ context } {...props} /> }
        />
        <Route
          key='forgot_password'
          exact
          path="/auth/forgot_password"
          render={(props) => <ForgotPassword context={ context } {...props} /> }
        />
        <Route
          key='reset_password'
          exact
          path="/auth/reset/:reset_token"
          render={(props) => <ResetPassword context={ context } {...props} /> }
        />
      </PageLayout>
    )
  }
}

export default AuthRoutes
