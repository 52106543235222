import React from 'react';
import { StateContext } from '../../components/Context';
import { Redirect, Link } from 'react-router-dom';
import { registerUser } from '../../lib/api';

import { Input } from '../../components/Inputs';
import { getUrlQuery } from '../../lib/helper';
import Validation, { required, email, toBeTruthy } from '../../lib/validation';
import { Button } from '../../style/components/button';
import { ErrorText } from '../../style/components/input';
import {
  AuthBody,
  StyledForm,
  Subheading,
  TitleContainer,
  InlinePadded,
  AuthContainer,
} from '../../style/styles';

export default class Register extends React.Component {
  static contextType = StateContext;

  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      form: {
        full_name: '',
        email: '',
        password: '',
        password_confirm: '',
        acceptTC: false,
      },
      redirect: false,
      referral: '',
      formErrors: {},
      user: {},
      token: '',
    };

    this.validation = new Validation({
      full_name: [{ type: required, message: 'Please enter your full name' }],
      email: [
        { type: required, message: 'Please enter your email' },
        { type: email, message: 'Please enter a valid email' },
      ],
      password: [{ type: required, message: 'Please enter your password' }],
      password_confirm: [
        { type: required, message: 'Please confirm your password' },
      ],
      acceptTC: [
        {
          type: toBeTruthy,
          message: 'Please confirm you have read the terms and conditions',
        },
      ],
    });
  }

  componentDidMount() {
    if (this.context.state.token) {
      this.setState({
        redirect: true,
      });
    }
  }

  updateFormState(target) {
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const field = target.name;

    this.setState({
      form: {
        ...this.state.form,
        [field]: value,
      },
    });
  }

  handleBlur(target) {
    this.validation.validateInput(target);

    this.setState({
      formErrors: this.validation.errors,
    });
  }

  async clearPasswords() {
    await this.updateFormState({ name: 'password', value: '' });
    this.updateFormState({ name: 'password_confirm', value: '' });
  }

  registerUser = async (event, context) => {
    event.preventDefault();

    let referralCode = '';

    if (this.props.location.search) {
      referralCode = getUrlQuery(this.props.location.search).referral;
    }

    if (this.validation.validateForm(this.state.form)) {
      if (this.state.form.password !== this.state.form.password_confirm) {
        this.clearPasswords();
        return;
      } else {
        try {
          let { data } = await registerUser(this.state.form, referralCode);

          if (data && data.token) {
            this.props.context.updateUser(data.user);
            this.props.context.updateToken(data.token);
            this.setState({
              redirect: true,
              token: data.token,
              user: data.user,
            });
          } else {
            this.clearPasswords();
          }
        } catch (e) {
          if (e.response.status === 400) {
            this.setState({
              formErrors: {
                ...this.state.formErrors,
                email: 'A user with this email address already exists',
              },
            });
          }
          this.clearPasswords();
        }
      }
    } else {
      this.clearPasswords();
      this.setState({
        formErrors: this.validation.errors,
      });
    }
  };

  render() {
    const { context } = this.props;

    if (this.state.redirect) {
      return (
        <Redirect
          push
          to={{
            pathname: '/auth/email/unverified',
            state: { token: this.state.token, user: this.state.user },
          }}
        />
      );
    }

    return (
      <AuthBody>
        <AuthContainer>
          <TitleContainer>
            <Subheading>Create new account</Subheading>
            <span>Play-Ex is free to use</span>
          </TitleContainer>
          <StyledForm onSubmit={(event) => this.registerUser(event, context)}>
            <Input
              placeholder='Full name'
              data_testid='full_name'
              name='full_name'
              onChange={this.updateFormState.bind(this)}
              onBlur={this.handleBlur.bind(this)}
              value={this.state.form.full_name}
              errors={this.state.formErrors.full_name}
            />
            <Input
              placeholder='Email address'
              data_testid='email'
              name='email'
              type='email'
              onChange={this.updateFormState.bind(this)}
              onBlur={this.handleBlur.bind(this)}
              value={this.state.form.email}
              errors={this.state.formErrors.email}
            />
            <Input
              placeholder='Password'
              data_testid='password'
              name='password'
              type='password'
              onChange={this.updateFormState.bind(this)}
              onBlur={this.handleBlur.bind(this)}
              value={this.state.form.password}
              errors={this.state.formErrors.password}
            />
            <Input
              placeholder='Confirm password'
              data_testid='password_confirm'
              name='password_confirm'
              type='password'
              onChange={this.updateFormState.bind(this)}
              onBlur={this.handleBlur.bind(this)}
              value={this.state.form.password_confirm}
              errors={this.state.formErrors.password_confirm}
            />
            <Input
              type='checkbox'
              checked={this.state.form.acceptTC}
              name='acceptTC'
              data_testid='acceptTC'
              onChange={this.updateFormState.bind(this)}
              value={this.state.form.acceptTC}
              errors={this.state.formErrors.acceptTC}
            >
              I have read the{' '}
              <Link to='https://play-ex.com/terms-and-conditions'>
                Terms and Conditions
              </Link>
            </Input>

            <Button data-testid='registerButton'>Register</Button>
          </StyledForm>
          <InlinePadded>
            <span>
              Already have an account? <Link to='/auth/login'>Sign in</Link>
            </span>
          </InlinePadded>
        </AuthContainer>
      </AuthBody>
    );
  }
}
