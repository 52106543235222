import React from 'react';
import Truncate from 'react-truncate';
import styled from 'styled-components';
import { colours, fonts, devices } from '../../style';
import { Subheading, ProviderImg } from '../../style/styles';
import { Container } from '../../style/components/card';
import logo from '../../assets/Play-Ex-Icon.png';

const ProviderCard = styled(Container)`
  @media ${devices.tabletsm} {
    box-shadow: inset 0 0 0 1px
      ${({ highlighted }) => (highlighted ? colours.blue : colours.white)};
  }
`;

const Description = styled.div`
  font-size: ${fonts.size.default};
  color: ${colours.grey};
  max-height: 60px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const ProviderContainer = styled.div`
  display: flex;
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    display: inline-block !important;
  }
  flex: 1;
  flex-flow: column;
`;

export default class ProviderIndexCard extends React.Component {
  render() {
    const { provider, handleClick, highlighted } = this.props;

    const cleanHtml = provider.description;

    var stripedHtml = cleanHtml.replace(/<[^>]+>/g, ' ');
    return (
      <ProviderCard
        key={provider.id}
        onClick={() => handleClick(provider)}
        highlighted={highlighted}
      >
        <ProviderImg src={provider.logo_url || logo} />
        <ProviderContainer>
          <Subheading>{provider.name}</Subheading>
          <Description>
            <Truncate lines={2}>{stripedHtml}</Truncate>
          </Description>
        </ProviderContainer>
      </ProviderCard>
    );
  }
}
