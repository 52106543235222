import React from 'react'
import { Route } from "react-router-dom"
import { Enquiries } from '../views/Individual'
import { ProfileViews } from '../views/Profile'
import {
  Settings
} from '../views/Settings'
import {
  ViewCategories,
  ViewProducts,
  ProductProviders,
  Enquire
} from '../views/Individual/Enquire'
import { PageHeader } from '../components/Header'

import { IndividualLayout, PageWidth } from '../style/styles'


class IndividualRoutes extends React.Component {
  constructor(props) {
    super(props)

    this.props = props

    this.state = {
      displayMenu: false
    }
  }

  toggleMenu = () => {
    this.setState({
      displayMenu: !this.state.displayMenu
    })
  }

  render() {
    return (
      <IndividualLayout>
        <PageWidth>
          <PageHeader context={ this.props.context } />

          {/* Take an enquiry ID to help render that enquiry when the page loads */}
          <Route
            exact
            key="individualEnquiries"
            path={ ["/enquiries", "/enquiries/:id"] }
            render={ (routerProps) => <Enquiries {...routerProps} context={ this.props.context } /> }
          />

          {/* Trying to update the header/breadcrumb on mount in this seems to fail due to the middleware */}
          {/* class protecting this route. Must find a better way of handling route protection */}
          <Route
            exact
            key='profile'
            path="/profile"
            render={ (routerProps) => <ProfileViews {...routerProps} context={ this.props.context } /> }
          />
          <Route
            exact
            key='settings'
            path="/settings"
            render={ (routerProps) => <Settings {...routerProps} context={ this.props.context } /> }
          />

          {/* Routes for navigating products through to making an enquiry */}
          <Route
            exact
            path="/category"
            render={ (routerProps) => <ViewCategories {...routerProps} context={ this.props.context } /> }
          />
          <Route
            exact
            path="/category/:id"
            render={ (routerProps) => <ViewProducts {...routerProps} context={ this.props.context } /> }
          />
          <Route
            exact
            path={ ["/service/:id/providers", "/service/:id/providers/:provId"] }
            render={ (routerProps) => <ProductProviders {...routerProps} context={ this.props.context } /> }
          />
          <Route
            exact
            path="/service/:prodId/providers/:id/enquiry"
            render={ (routerProps) => <Enquire {...routerProps} context={ this.props.context } /> }
          />

          <Route
            exact
            path="/"
            render={ (routerProps) => <ViewCategories {...routerProps} context={ this.props.context } /> }
          />
        </PageWidth>
      </IndividualLayout>
    )
  }
}

export default IndividualRoutes
