import React from 'react'
import NameCard from '../../../components/Influencers/NameCard'
import { Button } from '../../../style/components/button'
import { UnstyledLink, ScreenBody } from '../../../style/styles'
import { DesktopTable } from '../../../style/layouts'
import { getAllInfluencers, createInfluencer } from '../../../lib/api'
import { widths } from '../../../style'
import { getEnquiryStatusCounts, getInfluencerStatus } from '../../../lib/helper'
import Validation, { required, email } from '../../../lib/validation'
import { CreateOrUpdate } from '../../../components/Influencers'

export default class InfluencerIndex extends React.Component {
  constructor(props) {
    super(props)

    this.props = props
    this.state = {
      tableData: [],
      influencers: [],
      influencer: {
        full_name: '',
        mobile_number: '',
        email: '',
        twitter_handle: '',
        start_date: '',
        end_date: '',
        leads: [],
        users: []
      },
      formErrors: {}
    }

    this.validation = new Validation({
      full_name: [
        {type: required, message: 'Please enter a full name'}
      ],
      email: [
        {type: required, message: 'Please enter an email'},
        {type: email, message: 'Please enter a valid email'}
      ],
      mobile_number: [
        {type: required, message: 'Please enter a mobile number'}
      ],
      twitter_handle: [
        {type: required, message: 'Please enter a twitter handle'}
      ],
      start_date: [
        {type: required, message: 'Please enter a start date'}
      ],
      end_date: [
        {type: required, message: 'Please enter a end date'}
      ],
      commission: [
        {type: required, message: 'Please enter a fee %'}
      ],
    })
  }

  async componentDidMount() {
    const { context } = this.props
    context.updatePageTitle('Influencers')

    try {
      const { data } = await getAllInfluencers()

      await this.setState({
        influencers: data,
        tableData: data.map((influencer, index) => {
          let {
            openCount,
            newCount,
            wonCount,
            lostCount,
            totalCount
          } = getEnquiryStatusCounts(influencer.users.map(user => user.enquiries).flat())

          return {
            id: influencer.id,
            name: influencer.full_name,
            start_date:influencer.start_date,
            end_date:influencer.end_date,
            commission: `${influencer.commission || 0}%`,
            signups: influencer.users ? influencer.users.length : 0,
            new: newCount,
            open: openCount,
            won: wonCount,
            lost: lostCount,
            total: totalCount,
            data: influencer
          }
        })
      })
    } catch (e) {
      console.log(e)
    }

    if (!context.isDeviceMobile()) {
      context.setHeaderButtons([
        {
          to: '/influencer#create',
          text: 'Add influencer',
          hasPermission: 'create-influencer',
          buttonDisplay: true
        }
      ])
    }
    this.handlePageBreadcrumbs()
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props
    if (location.hash !== prevProps.location.hash) {
      this.handlePageBreadcrumbs()
    }
  }

  handlePageBreadcrumbs() {
    const { location, context } = this.props
    const {
      displayBCOnMobile,
      hideBCOnMobile,
      setBreadcrumb,
      isDeviceMobile,
      updatePageTitle
    } = context

    if (location.hash.includes('create') && isDeviceMobile()) {
      displayBCOnMobile()
      updatePageTitle('Add influencer')
      setBreadcrumb([
        { text: 'Home', to: '/' },
        { text: 'Influencers', to: '/influencer' }
      ])
    } else {
      hideBCOnMobile()
      setBreadcrumb()
      updatePageTitle('Influencers')
    }
  }

  componentWillUnmount() {
    this.props.context.setHeaderButtons()
  }


  handleBlur(target) {
    this.validation.validateInput(target)

    // View won't refresh on blur unless state updates
    this.setState({
      formErrors: this.validation.errors
    })
  }

  updateInfluencerState(target) {
    const { name, value } = target

    this.setState({
      influencer: {
        ...this.state.influencer,
        [name]: value
      }
    })
  }

  cancelCreation = async (event) => {
    event.preventDefault()
    const { history } = this.props

    this.validation.resetErrors()
    history.push('/influencer')
  }

  async createInfluencer(event) {
    event.preventDefault()

    if (!this.validation.validateForm(this.state.influencer)) {
      this.setState({
        formErrors: this.validation.errors
      })
      return
    }

    try {
      try {
        const { data } = await createInfluencer(this.state.influencer)
        this.props.history.push(`/influencer/${data.id}/details`)
      } catch (e) {
        if (e.response.status === 400) {
          this.setState({
            formErrors: {
              ...this.state.formErrors,
              email: 'An influencer with this email already exists'
            }
          })
        }
      }
    } catch(e) {
      console.log(e.message)
    }
  }
  handleRowClick(row) {
    this.props.history.push(`/influencer/${row.id}`)
  }

  renderCreateForm() {
    const { match, history, location } = this.props

    return <CreateOrUpdate
        influencer={ this.state.influencer }
        match={ match }
        history={ history }
        location={ location }
        handleBlur={ this.handleBlur.bind(this) }
        updateFormState={ this.updateInfluencerState.bind(this) }
        cancel={ this.cancelCreation.bind(this) }
        submitForm={ this.createInfluencer.bind(this) }
        errors={ this.state.formErrors }
      />
  }

  render() {
    const { context, location } = this.props

    if (!context.isDeviceMobile()) {
      return <DesktopTable
          displaySidePanel={ location.hash.includes('create') }
          tableData={ this.state.tableData }
          tableColumns={ [
            { name: 'Name', selector: 'name', sortable: true },
            { name: 'Status', cell: row => getInfluencerStatus(row).label },
            { name: 'Fee %', selector: 'commission', sortable: true, minWidth: widths.table.valueColumn },
            { name: 'Signups', selector: 'signups', sortable: true, minWidth: widths.table.valueColumn },
            { name: 'New', selector: 'new', sortable: true, width: widths.table.valueColumn },
            { name: 'Open', selector: 'open', sortable: true, width: widths.table.valueColumn },
            { name: 'Won', selector: 'won', sortable: true, width: widths.table.valueColumn },
            { name: 'Lost', selector: 'lost', sortable: true, width: widths.table.valueColumn },
            { name: 'Total', selector: 'total', sortable: true, width: widths.table.valueColumn }
          ] }
          onRowClicked={ this.handleRowClick.bind(this) }
          tableFilterOptions={ [{ value: 1, text: 'All influencers' }] }
          updatingTable={ this.state.updatingTable }
          tableName="influencers"
          context={ this.props.context }
        >
          { this.renderCreateForm() }
        </DesktopTable>
    }

    if (location.hash.includes('create')) {
      return (
        <ScreenBody>
          <div>
            { this.renderCreateForm() }
          </div>
        </ScreenBody>
      )
    }

    return (
      <ScreenBody>
        <div>
          <UnstyledLink to="/influencer#create">
            <Button>
              Add influencer
            </Button>
          </UnstyledLink>
          {
            this.state.influencers.map((influencer, index) => {
              return (
                <NameCard
                  key={ index }
                  influencer={ influencer }
                  full_name={ influencer.full_name }
                  users={ influencer.users }
                  showFlag
                  to={ `/influencer/${influencer.id}/leads` }
                />
              )
            })
          }
        </div>
      </ScreenBody>
    )
  }
}
