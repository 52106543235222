import React from "react";
import { CardContainer } from "../../style/components/card";
import styled from "styled-components";

const DescriptionContainer = styled(CardContainer)`
  white-space: pre-wrap;
`;

export default class Description extends React.Component {
  render() {
    const { description } = this.props;

    return (
      <DescriptionContainer>
        <div dangerouslySetInnerHTML={{ __html: description }}></div>
      </DescriptionContainer>
    );
  }
}
