import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Icon } from '../../style/styles'
import { devices, margin, fonts, colours, icons, border } from '../../style'

// Assuming the first breadcrumb will always be "Home", we must hide it on mobile even when the breadcrumb is mobile friendly
const BreadcrumbContainer = styled.div`
  display: ${({displayOnMobile}) => displayOnMobile ? 'flex' :  'none'};
  flex: 0 1;
  flex-direction: row;
  margin: ${margin.lg} ${margin.lg} 0 ${margin.lg};
  max-height: 20px;
  align-items: center;

  & > div:first-child {
    display: none;
  }
  & > div:nth-child(2) {
    display: none;
  }

  @media ${devices.tabletsm} {
    display: flex;
    margin: 0;
    text-transform: uppercase;

    & > div:first-child {
      display: block;
    }
    & > div:nth-child(2) {
      display: block;
    }
  }
`

const Crumb = styled.div`
  padding: 3px 5px;
  background: ${colours.white};
  border-radius: ${border.radius.lg};

  @media ${devices.tabletsm} {
    padding: 0 0 5px 0;
    background: transparent;
    font-size: ${fonts.size.md};
    color: ${colours.darkGrey};

    svg {
      display: none;
    }
  }
`
const CrumbLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  font-family: 'silkaregular';
`

const IconContainer = styled.div`
  padding: 0 5px;
  span {
    display: none;
  }

  @media ${devices.tabletsm} {
    color: ${colours.darkGrey};

    svg {
      display: none;
    }
    span {
      display: block;
    }
  }
`

export default class Breadcrumb extends React.Component {
  constructor(props) {
    super(props)

    this.props = props
  }

  renderIcon(crumb) {
    if (crumb.hideIcon) {
      return crumb.text
    }

    return (
      <Icon icon={ icons.chevronLeft }>
        { crumb.text }
      </Icon>
    )
  }

  renderCrumb(crumb, index) {
    if (crumb.onClick) {
      return (
        <Crumb onClick={ crumb.onClick } key={ index }>
          { this.renderIcon(crumb) }
        </Crumb>
      )
    }

    if (crumb.to) {
      return (
        <Crumb key={ index }>
          <CrumbLink to={ crumb.to }>
            { this.renderIcon(crumb) }
          </CrumbLink>
        </Crumb>
      )
    }

    return (
      <Crumb key={ index }>
        { this.renderIcon(crumb) }
      </Crumb>
    )
  }

  render() {
    const { crumbs } = this.props
    if (crumbs && crumbs.length) {
      return (
        <BreadcrumbContainer displayOnMobile={ this.props.displayOnMobile }>
          {
            crumbs
              .map((crumb, index) => this.renderCrumb(crumb, index) )
              .reduce((prev, curr) => {
                let i = 0
                let iconKey = `iconKey${i}`
                i++
                return [
                  prev,
                  <IconContainer key={ iconKey }>
                    <Icon icon={ icons.chevronRight } />
                    <span>/</span>
                  </IconContainer>,
                  curr
                ]
            })
          }
        </BreadcrumbContainer>
      )
    }

    return <div />
  }
}
