import React from 'react';
import DoubleColumn from '../../../style/layouts/DoubleColumn';
import { getProduct } from '../../../lib/api';
import { ProviderIndex, EnquiryPreview } from '../../../components/Providers';
import { ScreenBody } from '../../../style/styles';

export default class ProductProviders extends React.Component {
  constructor(props) {
    super(props);

    this.props = props;
    this.state = {
      product: {},
      provider: {
        id: null,
      },
      providers: [],
      loaded: false,
      hiddenColumn: 'right',
    };
  }

  async componentDidMount() {
    const { match, context } = this.props;

    const productId = match.params.id;
    try {
      const { data } = await getProduct(productId);
      await this.setPageState(data);
    } catch (e) {
      context.updatePageTitle('Providers');
      console.log(e);
    }

    this.setState({
      loaded: true,
    });
  }

  componentDidUpdate({ match }) {
    const previousId = match.params.provId;
    const { provId } = this.props.match.params;

    if (previousId !== provId) {
      if (!provId) {
        this.showLeftColumn();
      }
    }
  }

  setPageState = async (product = this.state.product) => {
    this.props.context.updatePageTitle(product.name || 'Providers');
    if (product.parent) {
      this.props.context.setBreadcrumb([
        { text: 'Home', to: '/' },
        { text: 'Services', to: '/category', hideIcon: true },
        {
          text: product.parent.name,
          to: `/category/${product.parent.id}`,
          hideIcon: true,
        },
      ]);
    } else {
      this.props.context.setBreadcrumb([
        { text: 'Home', to: '/' },
        { text: 'Services', to: '/category' },
      ]);
    }
    this.props.context.displayBCOnMobile();
    const selectedProvider = product.providers.find((prov) => {
      if (this.props.match.params.provId) {
        return prov.id === parseInt(this.props.match.params.provId);
      }
      return prov.id;
    });

    await this.setState({
      product: product,
      providers: product.providers,
      provider: selectedProvider || product.providers[0],
    });

    if (this.props.match.params.provId) {
      this.showRightColumn(this.state.provider);
    }
  };

  componentWillUnmount() {
    this.props.context.updateClickableHeaders();
    this.props.context.hideBCOnMobile();
    this.props.context.setBreadcrumb();
  }

  goToProvider(provider) {
    this.setState({
      redirectId: provider.id,
    });
  }

  viewProductProviders = (product) => {
    this.setState({
      redirectId: product.id,
    });
  };

  showLeftColumn = () => {
    this.setPageState();
    this.setState({
      hiddenColumn: 'right',
    });
  };

  showRightColumn = (provider) => {
    const { context } = this.props;
    const { product } = this.state;

    if (context.isDeviceMobile()) {
      context.updatePageTitle(provider.name || 'Provider');
    }

    context.displayBCOnMobile();

    if (context.isDeviceMobile()) {
      if (product.parent) {
        this.props.context.setBreadcrumb([
          { text: 'Home', to: '/' },
          { text: 'Services', to: '/category', hideIcon: true },
          {
            text: product.parent.name,
            to: `/category/${product.parent.id}`,
            hideIcon: true,
          },
          { text: product.name, to: `/category/${product.id}`, hideIcon: true },
        ]);
      } else {
        context.setBreadcrumb([
          { text: 'Home', to: '/' },
          { text: 'Services', to: '/category', hideIcon: true },
          { text: product.name, to: `/category/${product.id}`, hideIcon: true },
        ]);
      }
    } else {
      if (product.parent) {
        this.props.context.setBreadcrumb([
          { text: 'Home', to: '/' },
          { text: 'Services', to: '/category', hideIcon: true },
          {
            text: product.parent.name,
            to: `/category/${product.parent.id}`,
            hideIcon: true,
          },
        ]);
      } else {
        context.setBreadcrumb([
          { text: 'Home', to: '/' },
          { text: 'Services', to: '/category' },
        ]);
      }
    }

    this.setState({
      provider,
      hiddenColumn: 'left',
    });
  };

  displayProvider = (provider) => {
    const { history, context } = this.props;
    const { product } = this.state;

    this.showRightColumn(provider);

    if (context.isDeviceMobile()) {
      this.props.context.updatePageTitle(provider.name || 'Provider');
      history.push(`/service/${product.id}/providers/${provider.id}`);
    } else {
      history.replace(`/service/${product.id}/providers/${provider.id}`);
    }
  };

  hideProvider = () => {
    const { provider } = this.state;
    this.showLeftColumn(provider);
  };

  render() {
    const { match, context } = this.props;

    if (!this.state.loaded) {
      return <div />;
    }

    return (
      <DoubleColumn
        context={this.props.context}
        leftColumn={
          <ProviderIndex
            providers={this.state.providers}
            handleClick={this.displayProvider.bind(this)}
            selectedId={this.state.provider.id}
          />
        }
        rightColumn={
          <ScreenBody>
            <EnquiryPreview
              match={match}
              context={context}
              provider={this.state.provider}
              product={this.state.product}
            />
          </ScreenBody>
        }
        hideColumn={this.state.hiddenColumn}
      />
    );
  }
}
