import React from 'react'
import Breadcrumb from './Breadcrumb'
import ClickableHeader from './ClickableHeader'
import { ScreenHeader } from '../../style/styles'
import styled from 'styled-components'
import { Button, PillButton } from '../../style/components/button'
import { hasPermission } from '../../lib/permission'
import { devices, margin } from '../../style'

const ActionableContainer = styled.div`
  display: ${show => show ? 'inline' : 'none'};
`

const HeaderDiv = styled.div`
  position: relative;
  display: flex;
  flex-flow: column-reverse;

  @media ${devices.tabletsm} {
    flex-flow: column;
    margin: ${margin.xxl} ${margin.xxl} 0 ${margin.xxl};
  }
`

export default class PageHeader extends React.Component {
  actionableButton(actionButton, index) {
    if (actionButton.hasPermission && !hasPermission(actionButton.hasPermission)) {
      return
    }
    if (actionButton.to) {
      if (actionButton.buttonDisplay) {
        return (
          <PillButton key={ index } to={ actionButton.to }>
            { actionButton.text }
          </PillButton>
        )
      }
      return <PillButton key={ index } to={ actionButton.to }>{ actionButton.text }</PillButton>
    }

    if (actionButton.onClick) {
      return (
        <Button key={ index } onClick={ actionButton.onClick }>{ actionButton.text }</Button>
      )
    }

    return <div key={ index }>{ actionButton.text }</div>
  }

  renderActionableButtons() {
    const { headerButtons } = this.props.context.state

    return (
      <ActionableContainer>
        {
          headerButtons.map((button, index) => {
            return this.actionableButton(button, index)
          })
        }
      </ActionableContainer>
    )
  }

  renderHeaderType() {
    const {
      clickableHeaders,
      pageTitle
    } = this.props.context.state

    if (clickableHeaders.length && this.props.context.isDeviceMobile()) {
      return (
        <ClickableHeader
          headers={ clickableHeaders }
        />
      )
    }

    return (
      <ScreenHeader>
        <h1>{ pageTitle }</h1>
        { this.renderActionableButtons() }
      </ScreenHeader>
    )
  }

  render() {
    const {
      breadcrumb,
      mobileFriendlyBC
    } = this.props.context.state
    return (
      <HeaderDiv isDeviceMobile={ this.props.context.isDeviceMobile() }>
        <div>
          <Breadcrumb
            context={ this.props.context }
            crumbs={ breadcrumb }
            displayOnMobile={ mobileFriendlyBC }
          />
        </div>
        { this.renderHeaderType() }
      </HeaderDiv>
    )
  }
}
