import React from 'react'
import { forgotPassword } from '../../lib/api'
import { Redirect, Link } from 'react-router-dom'

import { Input } from '../../components/Inputs'
import Validation, { required, email } from '../../lib/validation'

import { Button } from '../../style/components/button'
import {
  AuthBody,
  StyledForm,
  Subheading,
  SubText,
  TitleContainer,
  InlinePadded,
  AuthContainer
} from '../../style/styles'

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      formErrors: {},
      redirect: false,
      requestSuccess: false
    }

    this.validation = new Validation({
      email: [
        {type: required, message: 'Please enter your email'},
        {type: email, message: 'Please enter a valid email'}
      ]
    })
  }

  componentDidMount() {
    if (this.props.context.state.token) {
      this.setState({
        redirect: true
      })
    }
  }

  updateEmail(target) {
    const value = target.value

    this.setState({
      email: value
    })
  }

  requestResetLink = async (event) => {
    event.preventDefault()
    const { email } = this.state
    if (this.validation.validateForm({ email })) {
      try {
        await forgotPassword(email)

        this.setState({
          requestSuccess: true
        })
      } catch (e) {
        console.log(e)
      }
    } else {
      this.setState({
        formErrors: this.validation.errors
      })
    }
  }

  handleBlur(target) {
    this.validation.validateInput(target)

    this.setState({
      formErrors: this.validation.errors
    })
  }

  render () {
    if (this.state.redirect) {
      return <Redirect push to="/" />
    }

    if (this.state.requestSuccess) {
      return (
        <AuthBody>
          <AuthContainer>
            <TitleContainer>
              <Subheading>Reset link sent</Subheading>
              <SubText>We've sent a reset link - check your email</SubText>
            </TitleContainer>
            <InlinePadded>
              <Link to="/auth/login">Back to sign in</Link>
            </InlinePadded>
          </AuthContainer>
        </AuthBody>
      )
    }

    return (
      <AuthBody>
        <AuthContainer>
          <TitleContainer>
            <Subheading>Forgot your password?</Subheading>
            <SubText>No problem. Enter your email here and we'll send you a reset link.</SubText>
          </TitleContainer>
          <StyledForm onSubmit={(event) => this.requestResetLink(event)}>
            <Input
              placeholder="Email"
              data_testid="email"
              name="email"
              type="email"
              onChange={ this.updateEmail.bind(this) }
              onBlur={ this.handleBlur.bind(this) }
              value={ this.state.email }
              errors={ this.state.formErrors.email }
            />
            <Button>Send reset link</Button>
          </StyledForm>
          <InlinePadded>
            <Link to="/auth/login">Back to sign in</Link>
          </InlinePadded>
        </AuthContainer>
      </AuthBody>
    )
  }
}

export default ForgotPassword
