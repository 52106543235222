import React from 'react';

import { Route } from 'react-router-dom';
import WelcomeEmail from '../welcome-email';

class EmailRoute extends React.Component {
  createMarkup() {
    return { __html: WelcomeEmail };
  }
  render() {
    return (
      <Route
        key='emailRouter'
        path='/email'
        render={() => (
          <div>
            <div dangerouslySetInnerHTML={this.createMarkup()} />
          </div>
        )}
      />
    );
  }
}

export default EmailRoute;
