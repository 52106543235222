import React from 'react';
import moment from 'moment';

import { PanelBody, UnstyledLink, ScreenBody } from '../../../style/styles';
import {
  fetchProvider,
  updateProvider,
  getCategories,
  inviteProvider,
  fetchEnquiry,
} from '../../../lib/api';
import { orderNumerically, orderAlphabetically } from '../../../lib/helper';
import { DesktopTable } from '../../../style/layouts';
import {
  CreateOrUpdate,
  Detail,
  ProviderDetails,
} from '../../../components/Providers';
import { IndexCard, Enquiry } from '../../../components/Enquiries';
import Validation, { required, email } from '../../../lib/validation';
import { widths } from '../../../style';
import { Button } from '../../../style/components/button';
import DoubleColumn from '../../../style/layouts/DoubleColumn';

export default class ServiceProvider extends React.Component {
  constructor(props) {
    super(props);

    this.props = props;

    this.state = {
      provider: {
        id: this.props.match.params.id,
        name: '',
        website: '',
        telephone: '',
        contact_name: '',
        contact_position: '',
        contact_email: '',
        contact_telephone: '',
        enquiries: [],
        description: '',
        products: [],
        user: {},
      },
      formCategories: [],
      enquiry: {
        product: {},
        profile: {},
        influencer: {},
        status: {},
        provider: {
          user: {
            profile: {},
          },
        },
        user: {
          profile: {},
        },
      },

      providers: [],
      search: '',
      status: '1',
      categories: [],
      products: [],
      category: '',
      product: '',
      showModal: false,
      formErrors: {},
      tableData: [],
      tableColumns: [],
      tableName: this.props.context.state.tableName,
      hiddenColumn: '',
      loaded: false,
      enquiryLoaded: false,
    };

    this.validation = new Validation({
      name: [{ type: required, message: 'Please enter a company name' }],
      website: [{ type: required, message: 'Please enter a company website' }],
      telephone: [
        { type: required, message: 'Please enter a company telephone number' },
      ],
      contact_name: [
        { type: required, message: 'Please enter a contact name' },
      ],
      contact_position: [
        { type: required, message: 'Please enter a contact position' },
      ],
      contact_telephone: [
        { type: required, message: 'Please enter a contact phone number' },
      ],
      contact_email: [
        { type: required, message: 'Please enter a contact email' },
        { type: email, message: 'Please enter a valid email' },
      ],
      description: [{ type: required, message: 'Please enter a description' }],
    });
  }

  async componentDidMount() {
    try {
      const { data } = await getCategories();

      let products = orderAlphabetically(data, 'name');

      await this.setState({
        products,
      });
    } catch (e) {
      console.log(e);
    }

    await this.fetchProvider();
    await this.fetchEnquiry();
  }

  async componentDidUpdate(prevProps) {
    const { id, enquiryId, page } = this.props.match.params;

    if (id !== prevProps.match.params.id) {
      this.fetchProvider();
    }
    if (enquiryId !== prevProps.match.params.enquiryId) {
      this.fetchEnquiry();
      this.setPageBreadcrumbs();
    }

    if (page !== prevProps.match.params.page) {
      this.setState({
        hiddenColumn: page === 'leads' ? 'right' : 'left',
      });
      this.setPageBreadcrumbs();
    }
  }

  componentWillUnmount() {
    const { context } = this.props;
    if (context) {
      context.updatePageTitle();
      context.setBreadcrumb();
      context.updateClickableHeaders();
      context.hideBCOnMobile();
      context.setHeaderButtons();
    }
  }

  setPageHeader(provider = this.state.provider) {
    const { match, context } = this.props;

    context.displayBCOnMobile();
    context.updatePageTitle(provider.name);
    context.setHeaderButtons([
      {
        to: '/providers#create',
        text: 'Add provider',
        hasPermission: 'create-provider',
        buttonDisplay: true,
      },
    ]);

    let enquiries = [];
    if (provider.enquiries) {
      enquiries = provider.enquiries.flat();
    }

    const isLeadsActive = match.params.page === 'leads' && enquiries.length;
    this.setPageBreadcrumbs();
    context.updateClickableHeaders([
      {
        title: enquiries.length ? `Leads (${enquiries.length})` : 'Leads',
        active: isLeadsActive,
        to: `/providers/${provider.id}/leads`,
        disabled: !enquiries.length,
      },
      {
        title: 'Details',
        active: !isLeadsActive,
        to: `/providers/${provider.id}/details`,
      },
    ]);
  }

  async fetchEnquiry() {
    const { enquiryId } = this.props.match.params;
    if (enquiryId) {
      try {
        const { data } = await fetchEnquiry(enquiryId);
        await this.setState({
          enquiry: data,
          enquiryLoaded: true,
        });
      } catch (e) {
        console.log(e);
      }
    }
  }

  setPageBreadcrumbs() {
    const { context, match, history } = this.props;
    const { id, enquiryId } = match.params;

    if (enquiryId && context.isDeviceMobile()) {
      context.setBreadcrumb([
        { text: 'Home', to: '/' },
        { text: 'Providers', to: '/providers', hideIcon: true },
        {
          text: 'Leads',
          onClick: () => {
            history.push(`/providers/${id}/leads`);
            context.setBreadcrumb([
              { text: 'Home', to: '/' },
              { text: 'Providers', to: '/providers' },
            ]);
          },
          hideIcon: true,
        },
      ]);
    } else {
      context.setBreadcrumb([
        { text: 'Home', to: '/' },
        { text: 'Providers', to: '/providers' },
      ]);
    }
  }

  async fetchProvider() {
    const { match, context } = this.props;
    try {
      const { data } = await fetchProvider(match.params.id);

      if (context.isDeviceMobile()) {
        const hiddenColumn =
          match.params.page === 'leads' && data.enquiries.length
            ? 'right'
            : 'left';
        this.setState({
          hiddenColumn,
        });
      }

      await this.setProviderState(data);
      this.setPageHeader(data);
      this.displayLeads(data);
    } catch (e) {
      console.log(e);
    }

    this.setState({
      loaded: true,
    });
  }

  async displayLeads(provider) {
    provider.enquiries = orderNumerically(provider.enquiries, 'id', true);

    await this.setState({
      tableName: 'providerLeads',
      tableData: provider.enquiries.map((enquiry) => {
        return {
          id: enquiry.id,
          name: enquiry.full_name,
          date: enquiry.created_at,
          product: enquiry.product.name,
          status: enquiry.status.display_name,
          data: provider,
        };
      }),
      tableColumns: [
        {
          name: 'Date',
          selector: 'date',
          sortable: true,
          cell: (row) => moment(row.date).format('DD/MM/YYYY'),
          width: widths.table.dateStatusColumn,
        },
        { name: 'Name', selector: 'name', sortable: true },
        { name: 'Service', selector: 'product', sortable: true },
        {
          name: 'Status',
          selector: 'status',
          sortable: true,
          width: widths.table.dateStatusColumn,
        },
      ],
    });
  }

  startEditing = () => {
    this.setState({
      editing: true,
    });
  };

  inviteProvider = async (provider) => {
    let confirmed = window.confirm(
      `Are you sure you want to send an invite to ${provider.name}?`
    );

    if (confirmed) {
      await inviteProvider(provider.id);
      await this.setState({
        provider: {
          ...this.state.provider,
          user: {
            ...this.state.provider.user,
            invited: true,
          },
        },
      });

      this.setPageHeader();
    }
  };

  handleRowClick(row) {
    switch (this.state.tableName) {
      case 'providers':
        this.props.history.push(`/providers/${row.id}`);
        break;
      case 'providerLeads':
      default:
        this.props.history.push(`/providers/${row.data.id}/leads/${row.id}`);
        break;
    }
  }

  async updateFormState(target) {
    const { name, value, file, type } = target;

    switch (type) {
      case 'file':
        await this.setState({
          file: file,
          provider: {
            ...this.state.provider,
            logo: file,
            logo_url: '',
          },
        });
        break;
      default:
        this.setState({
          provider: {
            ...this.state.provider,
            [name]: value,
          },
        });

        break;
    }
  }

  async setDescriptionState(target) {
    await this.setState({
      provider: {
        description: target,
      },
    });
  }

  async setProviderState(provider) {
    await this.setState({
      provider: {
        ...provider,
        contact_name: provider.user.profile.full_name,
        contact_position: provider.user.profile.profession,
        contact_email: provider.user.email,
        contact_telephone: provider.user.profile.telephone,
        iframe: provider.iframe,
        products: provider.products.map((product) => {
          return {
            key: product.id,
            ...product,
          };
        }),
      },
    });
  }

  async updateProvider(event) {
    event.preventDefault();
    if (!this.validation.validateForm(this.state.provider)) {
      this.setState({
        formErrors: this.validation.errors,
      });
      return;
    }

    const provider = {
      id: this.props.match.params.id,
      name: this.state.provider.name,
      website: this.state.provider.website,
      telephone: this.state.provider.telephone,
      description: this.state.provider.description,
      iframe: this.state.provider.iframe,
      products: this.state.provider.products.map((product) => product.id),
      contact: {
        name: this.state.provider.contact_name,
        position: this.state.provider.contact_position,
        email: this.state.provider.contact_email,
        telephone: this.state.provider.contact_telephone,
      },
      logo: this.state.file,
      logo_url: this.state.provider.logo_url,
    };

    try {
      const { data } = await updateProvider(provider);

      // await this.setPage(this.state.provider)
      this.setPageHeader(data);
      this.setProviderState(data);
      this.setState({
        editing: false,
      });
      // Set provider data
    } catch (e) {
      console.log(e);
    }
  }

  async cancel(event) {
    event.preventDefault();

    await this.fetchProvider();
    this.validation.resetErrors();
    this.setState({
      editing: false,
    });
  }

  handleBlur(target) {
    this.validation.validateInput(target);

    // View won't refresh on blur unless state updates
    this.setState({
      formErrors: this.validation.errors,
    });
  }

  updateSelectedProducts(options) {
    let products = [];
    if (options && options.length) {
      products = options.map((product) => {
        return {
          id: product.value,
          key: product.value,
          name: product.label,
        };
      });
    }

    this.updateFormState({
      name: 'products',
      value: products,
    });
  }

  renderEnquiry() {
    if (!this.state.enquiryLoaded) return <React.Fragment />;
    return (
      <React.Fragment>
        <Enquiry
          enquiry={this.state.enquiry}
          profile={this.state.enquiry.user.profile}
          provider={this.state.enquiry.provider}
          influencer={
            this.state.enquiry.user.influencer
              ? this.state.enquiry.user.influencer
              : null
          }
        />
        {this.props.context.isDeviceMobile() ? (
          <React.Fragment />
        ) : (
          <div>
            <UnstyledLink to={`/providers/${this.state.provider.id}`}>
              <Button styled='secondary'>Hide lead</Button>
            </UnstyledLink>
          </div>
        )}
      </React.Fragment>
    );
  }

  renderPage() {
    const { match, location } = this.props;
    if (this.props.match.params.enquiryId) {
      return this.renderEnquiry();
    }

    if (this.state.editing) {
      return (
        <CreateOrUpdate
          provider={this.state.provider}
          products={this.state.products}
          updateFormState={this.updateFormState.bind(this)}
          submitForm={this.updateProvider.bind(this)}
          errors={this.state.formErrors}
          cancel={this.cancel.bind(this)}
          handleBlur={this.handleBlur.bind(this)}
          updateSelectedProducts={this.updateSelectedProducts.bind(this)}
          match={match}
          location={location}
        />
      );
    }

    return (
      <ProviderDetails
        history={this.props.history}
        provider={this.state.provider}
        startEditing={this.startEditing.bind(this)}
        inviteProvider={this.inviteProvider.bind(this)}
      />
    );
  }

  renderLeftColumn() {
    let enquiries = [];
    if (this.state.provider.enquiries) {
      enquiries = orderNumerically(
        this.state.provider.enquiries.flat(),
        'id',
        true
      );
    }

    if (this.props.match.params.enquiryId) {
      return this.renderEnquiry();
    }

    return (
      <div>
        <Detail provider={this.state.provider} />
        {enquiries.map((enquiry, index) => {
          return (
            <UnstyledLink
              key={index}
              to={`/providers/${this.props.match.params.id}/leads/${enquiry.id}`}
            >
              <IndexCard
                status={enquiry.status}
                name={enquiry.full_name}
                date={enquiry.created_at}
                product={enquiry.product.name}
              />
            </UnstyledLink>
          );
        })}
      </div>
    );
  }

  render() {
    const { provider } = this.state;
    const { context } = this.props;

    if (!this.state.loaded) return <React.Fragment />;

    if (context.isDeviceMobile()) {
      return (
        <ScreenBody>
          <DoubleColumn
            context={context}
            leftColumn={this.renderLeftColumn()}
            rightColumn={this.renderPage()}
            hideColumn={this.state.hiddenColumn}
          />
        </ScreenBody>
      );
    }

    if (provider) {
      return (
        <DesktopTable
          tableName={this.state.tableName}
          displaySidePanel={true}
          tableData={this.state.tableData}
          tableColumns={this.state.tableColumns}
          onRowClicked={this.handleRowClick.bind(this)}
          tableFilterOptions={[{ value: 1, text: 'All leads' }]}
          updatingTable={this.state.updatingTable}
          context={this.props.context}
        >
          <PanelBody>{this.renderPage()}</PanelBody>
        </DesktopTable>
      );
    }
    return <div />;
  }
}
