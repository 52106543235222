import React from 'react';
import { CardContent, CardFlag, Container } from '../../style/components/card';
import styled from 'styled-components';
import moment from 'moment';
import { colours, heights, devices, margin, fonts } from '../../style';
import { Subheading } from '../../style/styles';
import { hasRole } from '../../lib/permission';
import { Pill } from '../../style/components/pill';

const PillContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-wrap: wrap;

  align-items: center;
`;

const EnquiryDate = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  justify-content: center;

  text-align: center;
  color: ${colours.navy};
  height: 100%
  border-right: 1px solid ${colours.background};
  margin-right: ${margin.default};

  min-height: ${heights.indexCard}
`;

const MonthDay = styled.p`
  font-weight: 700;
  font-size: ${fonts.size.xl};
  margin: 0;
`;
const Year = styled.p`
  margin: 0;
  font-weight: ${fonts.weight.bold};
  width: 100%;
  font-size: 22px;
`;

const EnquiryContainer = styled(Container)`
  flex-direction: column;
  position: relative;
  flex: 0 1;

  max-height: ${heights.indexCard};

  & > div {
    padding: 0;
    margin-bottom: 0;
    align-items: center;
  }

  @media ${devices.tabletsm} {
    box-shadow: inset 0 0 0 1px
      ${({ highlighted }) => (highlighted ? colours.blue : colours.white)};
  }
`;

const EnquiryBody = styled(Container)`
  padding: 0;
  width: 100%;
  margin-bottom: 0;
`;

const EnquiryName = styled(Subheading)`
  font-size: 16px;
  margin-bottom: 5px;
`;

export default class IndexCard extends React.Component {
  renderStatusName(status) {
    switch (status.id) {
      case 1:
        return 'New';
      case 2:
        return 'Open';
      case 3:
        return hasRole('admin') || hasRole('developer') ? 'Won' : 'Closed';
      case 4:
        return hasRole('admin') || hasRole('developer') ? 'Lost' : 'Closed';
      default:
        return 'Closed';
    }
  }

  render() {
    const { id, status, name, date, product, selectedId } = this.props;

    return (
      <EnquiryContainer highlighted={selectedId && selectedId === id}>
        {status ? (
          <CardFlag
            highlighted={selectedId && selectedId === id}
            status={status.id}
          >
            {this.renderStatusName(status)}
          </CardFlag>
        ) : (
          <React.Fragment />
        )}

        <EnquiryBody>
          <EnquiryDate>
            <div>
              <MonthDay>{moment(date).format('DD MMM').toUpperCase()}</MonthDay>
              <Year>{moment(date).format('YYYY')}</Year>
            </div>
          </EnquiryDate>
          <CardContent>
            <EnquiryName>{name}</EnquiryName>
            <PillContainer>
              {/** It's always a product that is rendered here */}
              <Pill>{product}</Pill>
            </PillContainer>
          </CardContent>
        </EnquiryBody>
      </EnquiryContainer>
    );
  }
}
