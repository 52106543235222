import styled from 'styled-components'

import { devices, colours, padding, fonts } from '..'

export const Header = styled.div`
  background: ${colours.white};
  text-align: center;
  position: relative;
  border-bottom: ${({location}) => location.includes('auth') ? `3px solid ${colours.teal}` : `0 solid ${colours.white}`};
  display: flex;
  @media only screen and (-ms-high-contrast:active), (-ms-high-contrast:none) {
    display: inline-block !important;
  }

  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media ${devices.tabletsm} {
    border-bottom: 3px solid ${colours.teal};
    justify-content: space-between;
    text-align: left;
    padding: 0 35px;
  }
`

export const HeaderMenuOption = styled.div`
  display: none;

  flex: row;
  align-items: center;

  & > a {
    font-size: ${fonts.lg};
    padding: ${padding.md} ${padding.lg};
    background: ${colours.white};

    :hover {
      cursor: pointer;
    }
  }

  @media ${devices.tabletsm} {
    display: ${({displayOptions}) => displayOptions ? 'flex' : 'none'};
  }
` // Need to figure out how to handle the HeaderMenuOptions and Hamburger on smaller devices
