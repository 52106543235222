import React from 'react'

import { Boxes } from '../../../components/Boxes'
import { BoxScreenBody, ModalForm } from '../../../style/styles'
import { icons } from '../../../style'
import { Modal } from '../../../components/Modal'
import { Input, Select } from '../../../components/Inputs'
import Validation, { required } from '../../../lib/validation'
import { Button } from '../../../style/components/button'

import { createProduct, getCategory, updateProduct, deleteProduct, getCategories } from '../../../lib/api'
import { orderAlphabetically } from '../../../lib/helper'

import IconList from '../../../lib/icons'

export default class Products extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
    this.state = {
      newProduct: { name: '', icon: '' },
      formErrors: {},
      showModal: false,
      category: {},
      products: [],
      categories: [],
      loaded: false,
      icons: IconList
    }

    this.validation = new Validation({
      name: [
        {type: required, message: 'Please enter a name for the service'},
      ],
      icon: [
        {type: required, message: 'Please enter a Font awesome icon name'}
      ],
    })
  }

  async componentDidMount() {
    await this.fetchProducts()
    try {
      const { data } = await getCategory(this.props.match.params.id)

      await this.setState({
        category: data
      })
    } catch (e) {
      console.log(e)
    }


    if (this.props.context) {
      this.props.context.updatePageTitle(this.state.category.name || 'Category')
      this.props.context.pushToBreadcrumb({ text: 'Services', to: '/categories' })
      this.props.context.displayBCOnMobile()
    }

    this.setState({
      loaded: true
    })
  }

  componentWillUnmount() {
    if (this.props.context) {
      this.props.context.hideBCOnMobile()
      this.props.context.setBreadcrumb()
    }
  }

  fetchProducts = async () => {
    let addProduct = {
        name: 'Add service',
        icon: icons.plus,
        onClick: this.addProduct.bind(this),
        editable: false
      }

    let products = []
    try {
      let categories = []
      const { data } = await getCategory(this.props.match.params.id)
      categories = await getCategories()

      products = orderAlphabetically(data.children, 'name')

      this.setState({
        category: data,
        categories: categories.data
      })

    } catch(e) {
      console.log(e)
    }

    products.push(addProduct)

    this.setState({
      products
    })
  }

  updateChosenIcon = (option) => {
    if (option) {
      this.setState({
        newProduct: {
          ...this.state.newProduct,
          icon: option.value
        }
      })
    }
  }

  updateNewProductState = (target) => {
    const { name, value } = target

    this.setState({
      newProduct: {
        ...this.state.newProduct,
        [name]: value
      }
    })
  }

  handleBlur = (target) => {
    if (this.state.showModal) {
      this.validation.validateInput(target)

      this.setState({
        formErrors: this.validation.errors
      })
    }
  }

  async createOrUpdateProduct(event = null) {
    event.preventDefault()

    if (this.validation.validateForm(this.state.newProduct)) {
      let product = this.state.newProduct
      product.parent_id = this.props.match.params.id

      try {
        if (this.state.newProduct.id) {
          await updateProduct(product)
        } else {
          await createProduct(product)
        }
      } catch (e) {
        console.log(e)
      }

      this.fetchProducts()

      this.closeModal()
    } else {
      this.setState({
        formErrors: this.validation.errors
      })
    }
  }

  addProduct = () => {
    this.setState({
      showModal: true
    })
  }

  closeModal = async () => {
    this.validation.resetErrors()
    await this.setState({
      formErrors: {}
    })
    await this.setState({
      showModal: false,
      newProduct: { name: '', icon: '' }
    })
  }

  editProduct = (category) => {
    this.setState({
      newProduct: category,
      showModal: true
    })
  }

  removeProduct = async (category) => {
    if (window.confirm("You are about to delete a service. Do you wish to continue?")) {
      try {
        await deleteProduct(category)
      } catch (e) {
        console.log(e)
      }

      this.fetchProducts()
    }
  }

  render() {
    if (!this.state.loaded) return <div />

    return (
      <React.Fragment>
        <BoxScreenBody>
          <Boxes
            items={ this.state.products }
            displayKey="name"
            editable={ true }
            onMenuClick={ this.editProduct.bind(this) }
            // menuItems={[
            //   {
            //     icon: icons.pencil,
            //     text: 'Edit',
            //     onClick: this.editProduct.bind(this)
            //   },
            //   {
            //     icon: icons.archive,
            //     text: 'Remove',
            //     onClick: this.removeProduct.bind(this)
            //   }
            // ]}
          />
        </BoxScreenBody>
        <Modal
          title={ this.state.newProduct.id ? 'Update service' : 'Add service' }
          show={ this.state.showModal }
          close={ this.closeModal.bind(this)}
        >
          <ModalForm onSubmit={ this.createOrUpdateProduct.bind(this) }>
            <Input
              placeholder="Service name"
              data_testid="name"
              name="name"
              type="text"
              onChange={ this.updateNewProductState.bind(this) }
              onBlur={ this.handleBlur.bind(this) }
              value={ this.state.newProduct.name }
              errors={ this.state.formErrors.name }
            />
            <Select
              placeholder="Choose an icon"
              name="icon"
              options={ this.state.icons }
              onChange={this.updateChosenIcon.bind(this)}
              closeMenuOnSelect={true}
            />
            {/* { this.state.newProduct.id ?
              <Dropdown name="category_id">
                { this.state.categories.map(category =>
                  <option key={category.id} selected={ this.state.newProduct.category_id === category.id ? true : null }>{category.name}</option>
                )}
              </Dropdown>
            : null } */}
            <Button>
              {  this.state.newProduct.id ? 'Update service' : 'Add service' }
            </Button>
          </ModalForm>
        </Modal>
      </React.Fragment>
    )
  }
}
