import moment from 'moment'
import { colours } from "../style"

export const getUrlQuery = (urlQuery) => {
  if (urlQuery !== '') {
    let queryObject = {}
    const queryString = urlQuery.slice(1)
    const queryArray = queryString.split('&')

    for (let i = 0; i < queryArray.length; i++) {
      let query = queryArray[i].split('=')

      queryObject = {
        ...queryObject,
        [query[0]]: query[1]
      }
    }

    return queryObject
  }

  return false
}

export const orderAlphabetically = (data, key, reverse = false) => {
  if (!key) {
    console.log('No key provided')

    return data
  }

  const orderedData = data.sort((a, b) => a[key].localeCompare(b[key]))

  if (!reverse) {
    return orderedData
  }
  return orderedData.reverse()
}

// As we commonly sort by id, we are setting the default key to id
export const orderNumerically = (data, key = 'id', reverse = false) => {
  if (!key) {
    console.log('No key provided')

    return data
  }

  let orderedData = data.sort((a, b) => a[key] - b[key])

  if (!reverse) {
    return orderedData
  }
  return orderedData.reverse()
}

export const getEnquiryStatusCounts = (enquiries) => {
  let counts = {
    openCount: 0,
    newCount: 0,
    wonCount: 0,
    lostCount: 0,
    closedCount: 0,
    totalCount: enquiries.length
  }

  if (enquiries.length) {
    enquiries.map(lead => {
      switch (lead.status.display_name.toLowerCase()) {
        case 'open':
          counts.openCount ++
          break
        case 'new':
          counts.newCount ++
          break
        case 'won':
          counts.wonCount ++
          counts.closedCount ++
          break
        case 'lost':
          counts.lostCount ++
          counts.closedCount ++
          break
        default:
          break
      }
      return lead
    })
  }

  return counts
}

export const checkActiveTab = (tab) => {
  const urlComponents = window.location.pathname.split('/').filter(s => s) // filter out empty strings
  if (urlComponents.length) {
    // check url path root against tab
    return urlComponents[0].includes(tab) ? colours.blue : null
  }
  return false
}

export const negateScrolling = () => {
  var page = document.getElementById('root')
  page.style.overflow = 'hidden'
}

export const allowScrolling = () => {
  var page = document.getElementById('root')
  page.style.overflow = 'auto'
}

export const getInfluencerStatus = influencer => {
  const now = moment()
  if (moment(influencer.start_date).diff(now) > 0) return { label: 'Pending', status: 2 }
  if (moment(influencer.end_date).diff(now) > 0 && moment(influencer.start_date).diff(now) < 0) return { label: 'Active', status: 3 }
  return { label: 'Expired', status: 4 }
}
