export const icons = {
  chevronDown: 'chevron-down',
  pencil: 'pencil-alt',
  plus: 'plus',
  ellipsisH: 'ellipsis-h',
  times: 'times',
  car: 'car',
  home: 'home',
  archive: 'archive',
  chevronLeft: 'chevron-left',
  exchange: 'exchange-alt',
  plane: 'plane',
  stethoscope: 'stethoscope',
  star: 'star',
  building: 'building',
  briefcase: 'briefcase',
  users: 'users',
  mobile: 'mobile-alt',
  chevronRight: 'chevron-right',
  chevronUp: 'chevron-up',
  lock: 'lock',
  user: 'user',
  signout: 'sign-out-alt',
  cog: 'cog',
  calculator: 'calculator',
  hardHat: 'hard-hat',
  city: 'city',
  coins: 'coins',
  umbrella: 'umbrella',
  key: 'key',
  houseDamage: 'house-damage',
  handHoldingUsd: 'hand-holding-usd',
  heartbeat: 'heartbeat',
  carSide: 'car-side'
}
