import React from 'react';
import { Container, CardContent } from '../../style/components/card';
import {
  InfoContainer,
  ProfileImg,
  ProfileImageContainer,
} from '../../style/styles';
import { Modal } from '../Modal';

export default class DetailsCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showingProfileImage: false,
    };
  }
  render() {
    const {
      profession,
      telephone,
      email,
      instagram,
      countyOfResidence,
      ageRange,
      profileImage,
      socialMediaTotal,
    } = this.props;

    return (
      <>
        <Container>
          <CardContent>
            {!!email && (
              <InfoContainer>
                <div>Email:</div>
                <span>{email}</span>
              </InfoContainer>
            )}
            {!!telephone && (
              <InfoContainer>
                <div>Mobile:</div>
                <span>{telephone}</span>
              </InfoContainer>
            )}
            {!!profession && (
              <InfoContainer>
                <div>Profession:</div>
                <span>{profession}</span>
              </InfoContainer>
            )}
            {!!instagram && (
              <InfoContainer>
                <div>Instagram:</div>
                <a
                  href={`https://www.instagram.com/${instagram}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {instagram}
                </a>
              </InfoContainer>
            )}

            {!!countyOfResidence && (
              <InfoContainer>
                <div>County:</div>
                <span>{countyOfResidence}</span>
              </InfoContainer>
            )}

            {!!ageRange && (
              <InfoContainer>
                <div>Age:</div>
                <span>{ageRange}</span>
              </InfoContainer>
            )}

            {!!socialMediaTotal && (
              <InfoContainer>
                <div>Social:</div>
                <span>{socialMediaTotal}</span>
              </InfoContainer>
            )}
          </CardContent>
        </Container>
        {!!profileImage && (
          <>
            <ProfileImageContainer>
              <ProfileImg
                src={profileImage}
                width={150}
                height={150}
                onClick={() => {
                  this.setState({ showingProfileImage: true });
                }}
              />
            </ProfileImageContainer>
            <Modal
              show={this.state.showingProfileImage}
              type='gallery'
              title='Profile Image'
              close={() => {
                this.setState({ showingProfileImage: false });
              }}
            >
              <img src={profileImage} width={600} />
            </Modal>
          </>
        )}
      </>
    );
  }
}
