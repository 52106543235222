import React from "react";
import { Detail, Contact, Description } from "../../components/Providers";
import styled from "styled-components";
import { devices, margin, padding } from "../../style";
import { Boxes } from "../../components/Boxes";
import { PageLayout, BoxScreenBody } from "../../style/styles";
import { fetchProvider } from "../../lib/api";
import { orderAlphabetically } from "../../lib/helper";

const ProviderContainer = styled.div`
  display: flex;
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    display: inline-block !important;
  }
  flex: 1;
  flex-flow: column;

  @media ${devices.tabletsm} {
    flex-flow: row;
  }
`;

const ProductContainer = styled.div`
  display: flex;
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    display: inline-block !important;
  }
  flex: 1;
  margin-bottom: ${margin.default};

  @media ${devices.tabletsm} {
    flex: 1;
    margin-left: 10px;
  }
`;

const DetailContainer = styled.div`
  padding-right: ${padding.lg};
  @media ${devices.tabletsm} {
    max-width: 340px;
    margin-right: 10px;
  }
`;

export default class ProviderDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      provider: {
        user: { profile: {} },
        provider: {},
        products: [],
      },
    };
  }

  async componentDidMount() {
    const { context } = this.props;

    context.updatePageTitle("Details");
    context.setBreadcrumb();

    try {
      const { data } = await fetchProvider(context.state.user.provider_id);

      this.setState({
        provider: data,
      });

      context.updateClickableHeaders([
        {
          title:
            data.enquiries.length > 0
              ? `Leads (${data.enquiries.length})`
              : "Leads",
          active: false,
          to: "/leads",
        },
        {
          title: "Details",
          active: true,
          to: "/provider",
        },
      ]);
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const { provider } = this.state;
    if (!provider) {
      return <React.Fragment />;
    }
    const sortedProducts = orderAlphabetically(provider.products, "name");
    return (
      <PageLayout>
        <BoxScreenBody>
          <div>
            <ProviderContainer>
              <DetailContainer>
                <Detail flag={false} provider={provider} />
                <Contact
                  email={provider.user.email}
                  profile={provider.user.profile}
                />
                <Description description={provider.description} />
              </DetailContainer>

              <ProductContainer>
                <Boxes items={sortedProducts} displayKey="name" />
              </ProductContainer>
            </ProviderContainer>
          </div>
        </BoxScreenBody>
      </PageLayout>
    );
  }
}
