import React from 'react'
import { resetPassword, checkResetToken } from '../../lib/api'
import { Redirect, Link } from 'react-router-dom'

import { Input } from '../../components/Inputs'
import Validation, { required } from '../../lib/validation'

import { Button } from '../../style/components/button'
import {
  AuthBody,
  StyledForm,
  Subheading,
  SubText,
  TitleContainer,
  InlinePadded,
  AuthContainer
} from '../../style/styles'

class ResetPassword extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      passwords: {
        password: '',
        password_confirm: ''
      },
      formErrors: {},
      redirect: false,
      resetSuccess: false,
      loading: true,
      resetTokenErrorMessage: false
    }

    this.validation = new Validation({
      password: [
        { type: required, message: 'Please enter a password' },
      ],
      password_confirm: [
        { type: required, message: 'Please confirm you new password' }
      ]
    })
  }

  async componentDidMount() {
    try {
      await checkResetToken(this.props.match.params.reset_token)
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message) {

        await this.setState({
          resetTokenErrorMessage: e.response.data.message
        })
      } else {
        await this.setState({
          resetTokenErrorMessage: 'Sorry, something went wrong'
        })
      }
    }

    if (this.props.context.state.token) {
      this.setState({
        redirect: true
      })
    }

    this.setState({
      loading: false
    })
  }

  updateForm(target) {
    const { value, name } = target

    this.setState({
      passwords: {
        ...this.state.passwords,
        [name]: value
      }
    })
  }

  updatePasswords = async (event) => {
    event.preventDefault()

    const { passwords } = this.state


    if (passwords.password !== passwords.password_confirm) {
      return this.setState({ formErrors: { password_confirm: "Please make sure the passwords match" } })
    }

    if (this.validation.validateForm(passwords)) {
      try {
        await resetPassword(passwords, this.props.match.params.reset_token)

        this.setState({
          resetSuccess: true
        })
      } catch (e) {
        console.log(e)
      }
    } else {
      this.setState({
        formErrors: this.validation.errors
      })
    }
  }

  handleBlur(target) {
    this.validation.validateInput(target)

    this.setState({
      formErrors: this.validation.errors
    })
  }

  render() {
    if (!this.state.loading) {
      let header = 'Reset password'
      let subHeader = 'Enter a new password below'
      let body = (
        <StyledForm onSubmit={(event) => this.updatePasswords(event)}>
          <Input
            placeholder="Password"
            data_testid="password"
            name="password"
            type="password"
            onChange={this.updateForm.bind(this)}
            onBlur={this.handleBlur.bind(this)}
            value={this.state.password}
            errors={this.state.formErrors.password}
          />
          <Input
            placeholder="Confirm password"
            data_testid="password_confirm"
            name="password_confirm"
            type="password"
            onChange={this.updateForm.bind(this)}
            onBlur={this.handleBlur.bind(this)}
            value={this.state.password_confirm}
            errors={this.state.formErrors.password_confirm}
          />
          <Button>
            Reset password
          </Button>
        </StyledForm>
      )

      if (this.state.redirect) {
        return <Redirect push to="/" />
      }

      if (this.state.resetSuccess) {
        header = 'Password reset'
        subHeader = 'Your password has been updated, please return to the sign in page'
        body = null
      }

      if (this.state.resetTokenErrorMessage) {
        if (this.state.resetTokenErrorMessage === 'Token is invalid') {
          header = 'Link is invalid'
          subHeader = 'Your password reset link is invalid, please request a new link'
          body = null
        } else {
          header = 'Link has expired'
          subHeader = 'Your password reset link has expired, please request a new link'
          body = null
        }
      }

      return (
        <AuthBody>
          <AuthContainer>
            <TitleContainer>
              <Subheading>{header}</Subheading>
              <SubText>{subHeader}</SubText>
            </TitleContainer>
            {body}
            <InlinePadded>
              <Link to="/auth/login">Back to sign in</Link>
            </InlinePadded>
          </AuthContainer>
        </AuthBody>
      )
    }

    return <React.Fragment />
  }
}

export default ResetPassword
