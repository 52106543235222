import React from 'react'
import { Container, CardContent } from '../../style/components/card'
import { InfoContainer } from '../../style/styles'

export default class Contact extends React.Component {
  render() {
    const { mobile_number, email, twitter_handle } = this.props
    return (
      <Container>
        <CardContent>
          <InfoContainer>
            <div>Mobile:</div>
            <span>{ mobile_number }</span>
          </InfoContainer>
          <InfoContainer>
            <div>Email:</div>
            <span>{ email }</span>
          </InfoContainer>
          <InfoContainer>
            <div>Twitter:</div>
            <span>{ twitter_handle }</span>
          </InfoContainer>
        </CardContent>
      </Container>
    )
  }
}
