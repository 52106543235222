import React from 'react'
import { Route, Redirect } from 'react-router-dom'

export default ({ component: Component, context, ...rest }) => (
  <Route {...rest} render={(props) => (
    context.state.token && context.state.token !== ''
      ? (
        context.emailVerified ?
          <Component {...props} context={ context } /> :
          <Redirect push to='/auth/email/unverified' />
      ) : <Redirect push to='/auth/login' />
  )} />
)
