import React from 'react'
import { Route } from "react-router-dom"

import { ProfileViews } from '../views/Profile'
import { Categories, Products } from '../views/Admin/Categories'
import { LeadsIndex } from '../views/Leads'
import { ProviderIndex, ServiceProvider } from '../views/Admin/Providers'
import { Influencer, InfluencerIndex } from '../views/Admin/Influencers'

// Errors when importing from Individuals index.js
import { Individual, IndividualIndex } from '../views/Admin/Individuals'

import { PageHeader } from '../components/Header'

import { AppLayout, PageLayout } from '../style/styles'
import { Settings } from '../views/Settings'

class AdminRoutes extends React.Component {
  constructor(props) {
    super(props)

    this.props = props

    this.state = {
      displayMenu: false
    }
  }

  toggleMenu = () => {
    this.setState({
      displayMenu: !this.state.displayMenu
    })
  }

  render() {
    const { context } = this.props
    return (
      <AppLayout>
        <PageLayout>
          <PageHeader context={ context } />
          {/** Routes for creating categories and products */}
          <Route key='categories' exact path="/categories" render={(routeProps) => (
            <Categories context={ context } {...routeProps} />
          )} />
          <Route key='category' exact path="/categories/:id" render={(routeProps) => (
            <Products context={ context } {...routeProps} />
          )} />

          <Route exact key='profile' path="/profile" render={ (routerProps) => (
            <ProfileViews {...routerProps} context={ this.props.context } />
          )} />

          {/** Routes for viewing, creating and editing a service provider */}
          <Route key='providers' exact path="/providers" render={(routeProps) => (
            <ProviderIndex context={ context } {...routeProps} />
          )} />
          <Route key='provider' exact path={ ["/providers/:id", "/providers/:id/:page", "/providers/:id/:page/:enquiryId"] } render={(routeProps) => (
            <ServiceProvider context={ context } {...routeProps} />
          )} />
          {/* <Route key='lead' exact path="/providers/:id/lead/:enquiryId" render={(routeProps) => (
            <ProviderLead context={ context } {...routeProps} />
          )} /> */}

          {/** Routes for viewing, creating and editing an influencer */}
          <Route exact key='influencerIdPage' path={ ["/influencer/:id", "/influencer/:id/:page", "/influencer/:id/:page/:enquiryId"] } render={ (routerProps) => (
            <Influencer {...routerProps} context={ context } />
          )} />
          <Route exact key='influencers' path="/influencer" render={ (routerProps) => (
            <InfluencerIndex context={ context } {...routerProps} />
          )} />

          {/** Routes for viewing users on the system */}
          <Route key='users' exact path="/user" render={(routeProps) => (
            <IndividualIndex context={ context } {...routeProps} />
          )} />
          <Route key='user' exact path={ ["/user/:id", "/user/:id/:page", "/user/:id/:page/:enquiryId"] } render={(routeProps) => (
            <Individual context={ context } {...routeProps} />
          )} />

          <Route exact key='settings' path="/settings" render={ (routerProps) => (
            <Settings {...routerProps} context={ this.props.context } />
          )} />

          {/** Routes for viewing leads */}
          {/** As providers use the same components, set up a route parameter to help with navigation */}
          <Route key='leads' exact path={ ["/", "/leads", "/leads/:id"] } render={(routeProps) => (
            <LeadsIndex context={ context } {...routeProps} />
          )} />
        </PageLayout>
      </AppLayout>
    )
  }
}

export default AdminRoutes
