import React from 'react';
import { ScreenBody } from '../styles';
import styled from 'styled-components';
import DataTable from 'react-data-table-component';
import { border, margin, devices, colours } from '..';

const MainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-flow: row;
  max-height: 100%;
`;

const TableContainer = styled.div`
  display: none;

  @media ${devices.tabletsm} {
    display: flex;
    flex: 1;
    flex-flow: column;
    position: relative;
  }
`;

const PanelContainer = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1;

  @media ${devices.tabletsm} {
    display: ${({ displaySidePanel }) => (displaySidePanel ? 'block' : 'none')};
    flex: 0;
    max-width: 340px;
    min-width: 340px;
  }
`;

const LeftContainerDiv = styled.div`
  max-height: 100%
  background: ${colours.white};
  border-radius: ${border.radius.default};
  margin-right: ${({ displaySidePanel }) => (displaySidePanel ? margin.xl : 0)};
  margin-bottom: ${margin.xxl};

  .rdt_TableBody {
    overflow-y: auto;
  }
`;

const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border: 1px solid #e5e5e5;
  margin: 12px 0;
`;

export default class DesktopTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRow: {},
      tableData: [],
      tableColumns: [],
      resetToDefault: false,
      filterText: '',
    };
  }

  async componentDidMount() {
    const {
      selectedRowID,
      tableData,
      tableColumns,
      context,
      tableName,
    } = this.props;
    await this.setState({
      selectedRow: { id: selectedRowID },
      tableData: this.generateSearchStrings(tableColumns, tableData),
      tableColumns,
    });

    context.setTableInfo({
      tableName,
    });
  }

  generateSearchStrings(tableColumns, tableData) {
    return tableData.map((item) => {
      let searchString = '';
      tableColumns.forEach(({ selector }) => {
        searchString += item[selector];
      });
      return { ...item, searchString };
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.tableData.length !== this.state.tableData.length ||
      this.props.tableData.length !== prevState.tableData.length ||
      this.props.tableName !== prevProps.tableName
    ) {
      this.setState({
        tableData: this.generateSearchStrings(
          this.props.tableColumns,
          this.props.tableData
        ),
        tableColumns: this.props.tableColumns,
      });
    }

    if (this.props.tableName !== prevProps.tableName) {
      this.props.context.setTableInfo({
        tableName: this.props.tableName,
        resetToDefault: true,
      });
    }

    if (
      this.props.context.state.paginationPerPage !==
      prevProps.context.state.paginationPerPage
    ) {
      this.setState({
        resetToDefault: true,
      });
    }
  }

  handleRowClick(row) {
    this.setState({
      selectedRow: row,
    });
    if (this.props.onRowClicked) {
      this.props.onRowClicked(row);
    }
  }

  updateTableContext = (value, key) => {
    this.props.context.setTableInfo({
      [key]: value,
    });
  };

  renderDatatable() {
    const conditionalRowStyling = [
      {
        when: (row) => row.id === this.state.selectedRow.id,
        style: {
          background: 'rgba(0, 0, 0, 0.1)',
          '&:nth-child(odd)': {
            background: 'rgba(0, 0, 0, 0.1)',
          },
        },
      },
    ];

    const filteredItems = this.state.tableData.filter(
      (item) =>
        item.searchString &&
        item.searchString
          .toLowerCase()
          .includes(this.state.filterText.toLowerCase())
    );

    return (
      <DataTable
        defaultSortField='registerDate'
        defaultSortAsc={false}
        data={filteredItems}
        columns={this.state.tableColumns}
        pagination
        fixedHeader
        noHeader
        overflowY={true}
        onRowClicked={(event) => this.handleRowClick(event)}
        subHeader
        subHeaderComponent={
          <TextField
            id='search'
            type='search'
            placeholder='Search'
            aria-label='Search Input'
            value={this.state.filterText}
            onChange={(e) => this.setState({ filterText: e.target.value })}
          />
        }
        conditionalRowStyles={conditionalRowStyling}
        striped
        highlightOnHover
        pointerOnHover
        dense
        paginationResetDefaultPage={this.state.resetToDefault}
        paginationDefaultPage={this.props.context.state.paginationDefaultPage}
        paginationPerPage={this.props.context.state.paginationPerPage}
        onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
          this.updateTableContext(currentRowsPerPage, 'paginationPerPage');
        }}
        onChangePage={(page, totalRows) => {
          this.updateTableContext(page, 'paginationDefaultPage');
        }}
      />
    );
  }

  render() {
    return (
      <ScreenBody>
        <MainContainer>
          <TableContainer displaySidePanel={this.props.displaySidePanel}>
            <LeftContainerDiv displaySidePanel={this.props.displaySidePanel}>
              {this.renderDatatable()}
            </LeftContainerDiv>
          </TableContainer>
          <PanelContainer displaySidePanel={this.props.displaySidePanel}>
            {this.props.children}
          </PanelContainer>
        </MainContainer>
      </ScreenBody>
    );
  }
}
