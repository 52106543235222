import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { border, colours } from '..';

export const Button = styled.button`
  padding: 10px;
  cursor: pointer;
  text-align: center;
  margin-bottom: 15px;
  font-family: 'silkasemibold';
  cursor: pointer;
  ${({ styled }) => {
    let width = '100%';
    let backgroundColour = colours.blue;
    let colour = colours.white;
    let borderColour = 'transparent';

    switch (styled) {
      case 'secondary':
        backgroundColour = 'transparent';
        borderColour = colours.blue;
        colour = colours.blue;
        break;

      case 'remove':
        backgroundColour = 'transparent';
        borderColour = colours.status.text.lost;
        colour = colours.status.text.lost;
        width = 'auto';

        break;

      case 'primary':
      default:
        break;
    }
    return `
      width: ${width};
      background: ${backgroundColour};
      border: ${border.style.solid} ${borderColour};
      color: ${colour};
    `;
  }};
  border-radius: ${border.radius.xxl};
`;

export const PillButton = styled(Link)`
  width: 100%;
  color: ${colours.white};
  background-color: ${colours.blue};
  border: none;
  text-decoration: none;
  font-family: 'silkasemibold', sans-serif;
  padding: 5px 10px;
  border-radius: ${border.radius.md};
  cursor: pointer;
`;
