import React from 'react';

import { Link } from 'react-router-dom';

import styled from 'styled-components';
import { fonts, padding, border, devices, colours, margin } from '.';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ScreenHeader = styled.div`
  background: ${colours.white};
  text-align: center;
  margin: 0;
  display: flex;
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    display: inline-block !important;
  }
  justify-content: center;
  align-items: center;
  padding-bottom: ${padding.lg};

  h1 {
    color: ${colours.navy};
    font-size: ${fonts.size.subheading};
    margin: 0;
  }
  border-bottom: 3px solid ${colours.teal};

  @media ${devices.tabletsm} {
    background: ${colours.background};
    border-bottom: ${border.style.solid} ${colours.mediumGrey};
    text-align: left;
    justify-content: space-between;

    h1 {
      font-size: 30px;
      font-weight: ${fonts.weight.bold};
      margin: 0;
    }
  }
`;

export const ScreenBody = styled.div`
  padding: ${padding.lg};
  /* max-width: 400px; */
  display: flex;
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    display: inline-block !important;
  }
  flex-flow: column;
  flex: 1;

  @media ${devices.tabletsm} {
    padding: ${padding.xl} ${padding.xxl};
  }
`;

export const BoxScreenBody = styled.div`
  padding: ${padding.lg} 0 ${padding.lg} ${padding.lg};
  display: flex;
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    display: inline-block !important;
  }
  flex-flow: column;
  flex: 1;

  @media ${devices.tabletsm} {
    padding: ${padding.xl} ${padding.default} ${padding.xl} ${padding.xxl};
  }
`;

export const AuthBody = styled(ScreenBody)`
  @media ${devices.tabletsm} {
    width: 340px;
    justify-content: center;
    margin: 0 auto;

    height: 75%;
  }
`;

export const AuthContainer = styled.div`
  display: flex;
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    display: inline-block !important;
  }
  flex-flow: column;
  height: 75%;
  justify-content: flex-start;
`;

export const StyledForm = styled.form`
  position: relative;
  display: flex;
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    display: inline-block !important;
  }
  flex-direction: column;
`;

export const TitleContainer = styled.div`
  margin-bottom: 15px;
  padding: 0 ${padding.sm};
`;

export const Subheading = styled.div`
  font-size: ${fonts.size.subheading};
  padding-bottom: 5px;
  font-family: 'silkasemibold', sans-serif;
  color: ${colours.navy};
`;

export const SubText = styled.span`
  color: ${colours.subtext};
  padding-right: ${padding.sm};
`;

export const PointerDiv = styled.div`
  :hover {
    cursor: pointer;
  }

  div {
    :hover {
      cursor: pointer;
    }
  }
`;

export const Icon = ({ icon, onClick, children, data_testid, ...rest }) => {
  if (onClick) {
    return (
      <PointerDiv data-testid={data_testid} onClick={() => onClick()}>
        <FontAwesomeIcon icon={icon} {...rest} /> {children}
      </PointerDiv>
    );
  }
  return (
    <React.Fragment>
      <FontAwesomeIcon icon={icon} {...rest} /> {children}
    </React.Fragment>
  );
};

export const Inline = styled.div`
  display: flex;
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    display: inline-block !important;
  }
  flex-direction: row;
`;

export const ModalForm = styled.form`
  button:last-child {
    margin-bottom: 0;
  }
`;

export const AppLayout = styled.div`
  display: block;
  flex-flow: column;
  flex: 1;

  @media ${devices.tabletsm} {
    display: flex;
    @media only screen and (-ms-high-contrast: active),
      (-ms-high-contrast: none) {
      display: inline-block !important;
    }
    flex-flow: row;
    overflow-y: auto;
  }
`;

export const IndividualLayout = styled(AppLayout)`
  @media ${devices.tabletsm} {
    flex: 1;
    display: flex;
    @media only screen and (-ms-high-contrast: active),
      (-ms-high-contrast: none) {
      display: inline-block !important;
    }
    flex-flow: column;
    overflow-y: auto;
    align-items: center;
  }
`;

export const PageLayout = styled.div`
  flex: 1;
  display: flex;
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    display: inline-block !important;
  }
  flex-flow: column;
`;

export const PanelBody = styled(ScreenBody)`
  padding: 0;

  @media ${devices.tabletsm} {
    padding: 0 0;
    flex: 0;
  }
`;

export const InlinePadded = styled(Inline)`
  padding: 0 ${padding.sm} ${padding.sm} ${padding.sm};
`;

export const UnstyledAnchor = styled.a`
  display: flex;
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    display: inline-block !important;
  }
  flex: 0 1;
  width: 100%;
  color: ${colours.black};
  text-decoration: none;
  border: none;

  & > div {
    flex: 1;
  }

  div {
    cursor: pointer;
  }
`;

export const UnstyledLink = styled(Link)`
  display: flex;
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    display: inline-block !important;
  }
  flex: 0 1;
  width: 100%;
  color: ${colours.black};
  text-decoration: none;
  border: none;

  & > div {
    flex: 1;
  }

  div {
    cursor: pointer;
  }
`;

export const BodyTextContainer = styled.div`
  padding: ${padding.sm} ${padding.sm} ${padding.sm} ${padding.sm};
`;

export const PaddingBottom = styled.div`
  padding-bottom: ${padding.default};
`;

export const PageWidth = styled.div`
  height: 100%;
  display: flex;
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    display: inline-block !important;
  }
  flex: 1;
  flex-direction: column;

  max-width: 760px;
  width: 100%;
`;

export const InfoContainer = styled.div`
  display: flex;
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    display: inline-block !important;
  }
  flex-direction: row;
  flex: 1;
  align-items: flex-start;

  line-height: 25px;

  & > div:first-child {
    width: 25%;
    color: ${colours.subtext};
  }

  span {
    width: 75%;
    color: ${colours.black};
    word-wrap: break-word;
  }
`;

export const ProfileImageContainer = styled.div`
  text-align: center;
`;

export const ProviderImg = styled.img`
  width: 60px;
  height: 60px;
  margin-right: ${margin.default};
  border-radius: ${border.radius.md};
`;

export const ProfileImg = styled.img`
  width: 100%;
  height: 200px;
  border-radius: ${border.radius.md};
  object-position: center center;
  object-fit: cover;
  cursor: pointer;
`;

export const Invited = styled.div`
  text-align: center;
  background: ${colours.status.won};
  color: ${colours.status.text.won};
  padding: 5px 0;
  margin-bottom: 10px;
  border-radius: ${border.radius.default};
`;

export const ColumnForm = styled(StyledForm)`
  flex-direction: column;
  @media ${devices.tabletsm} {
    max-width: 340px;
  }
`;
