import React from 'react'
import { Button } from '../../style/components/button'
import { StyledForm } from '../../style/styles'
import { Input } from '../Inputs'

export default class CreateOrUpdate extends React.Component {
  constructor(props) {
    super(props)

    this.handleBlur = props.handleBlur
    this.submitForm = props.submitForm
    this.cancel = props.cancel
    this.updateFormState = props.updateFormState
  }

  componentDidMount() {
    const { influencer, match } = this.props

    if (influencer.id && match.params.id !== 'create') {
      this.setState({
        influencer
      })
    }
  }

  render() {
    const { influencer, errors, location } = this.props

    return (
      <StyledForm onSubmit={(event) => this.submitForm(event)}>
        <Input
          name="full_name"
          value={ influencer.full_name }
          placeholder="Name"
          onChange={ this.updateFormState.bind(this) }
          onBlur={ this.handleBlur.bind(this) }
          errors={ errors.full_name }
        />
        <Input
          name="email"
          value={ influencer.email }
          placeholder="Email"
          type="email"
          onChange={ this.updateFormState.bind(this) }
          onBlur={ this.handleBlur.bind(this) }
          errors={ errors.email }
        />
        <Input
          name="mobile_number"
          value={ influencer.mobile_number }
          placeholder="Mobile"
          type="tel"
          onChange={ this.updateFormState.bind(this) }
          onBlur={ this.handleBlur.bind(this) }
          errors={ errors.mobile_number }
        />
        <Input
          name="twitter_handle"
          value={ influencer.twitter_handle }
          placeholder="Twitter"
          onChange={ this.updateFormState.bind(this) }
          onBlur={ this.handleBlur.bind(this) }
          errors={ errors.twitter_handle }
        />
        <Input
          name="start_date"
          value={ influencer.start_date }
          placeholder="Start date"
          type="date"
          onChange={ this.updateFormState.bind(this) }
          onBlur={ this.handleBlur.bind(this) }
          errors={ errors.start_date }
        />
        <Input
          name="end_date"
          value={ influencer.end_date }
          placeholder="End date"
          type="date"
          onChange={ this.updateFormState.bind(this) }
          onBlur={ this.handleBlur.bind(this) }
          errors={ errors.end_date }
        />
        <Input
          name="commission"
          value={ influencer.commission }
          placeholder="Fee %"
          type="number"
          onChange={ this.updateFormState.bind(this) }
          onBlur={ this.handleBlur.bind(this) }
          errors={ errors.commission }
        />
        <Button styled="primary">
          { (location && location.hash.includes('create')) ? 'Add influencer' : 'Update influencer' }
        </Button>
        <Button onClick={ this.cancel } styled="secondary">
          Cancel
        </Button>
      </StyledForm>
    )
  }
}
