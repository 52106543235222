import React from 'react'
import styled from 'styled-components'

import { version } from '../../../package.json'
import { devices } from '../../style/index.js'

const Text = styled.p`
  text-align: center;
  font-size: 12px;
`

const Position = styled.div`
  width: calc(100% - 30px);
  position: relative;
  @media ${devices.tabletsm} {
    position: absolute;
    bottom: 0;
    width: calc(100% - 60px);
  }
`

export default () => <Position><Text>v{version}</Text></Position>
