import React from 'react'
import { Inline, Subheading } from '../../style/styles'
import { CardContainer } from '../../style/components/card'
import { Pill } from '../../style/components/pill'

const NameCard = ({ name, newLeads, openLeads, user, onClick }, ...rest) => {
  return (
    <CardContainer onClick={ () => onClick ? onClick(user) : null }>
      <Subheading>
        { name }
      </Subheading>
      <Inline>
        <Pill>{ newLeads || 0 } new</Pill>
        <Pill>{ openLeads || 0 } open</Pill>
      </Inline>
    </CardContainer>
  )
}

export default NameCard
