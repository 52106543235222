import React from 'react'
import ResetPassword from './ResetPassword'
import DoubleColumn from '../../style/layouts/DoubleColumn'

export default class Settings extends React.Component {
  state = {
    hiddenColumn: 'right'
  }

  componentDidMount() {
    const { context } = this.props

    context.updatePageTitle('Settings')
    context.updateClickableHeaders()
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props
    if (match.params.page !== prevProps.match.params.page) {
      this.hidePage()
    }
  }

  componentWillUnmount() {
    this.props.context.updatePageTitle()
  }

  render() {
    const { context } = this.props
    return (
      <DoubleColumn
        context={ context }
        leftColumn={ <ResetPassword context={ context } /> }
        hideColumn={ this.state.hiddenColumn }
      />
    )
  }
}
