import React from 'react'
import styled from 'styled-components'

import { ScreenBody, ColumnForm } from '../../style/styles'
import { Input } from '../../components/Inputs'
import { Button } from '../../style/components/button'
import { updatePassword } from '../../lib/api'
import { colours } from '../../style'

import Version from '../../components/Version/VersionText'

const Response = styled.p`
  color: ${({error}) => error ? colours.danger : colours.black};
  margin-top: 0;
`

export default class ResetPassword extends React.Component {
  state = {
    passwords: {
      password_current: '',
      password: '',
      password_confirm: ''
    },
    responseMessage: '',
    responseErrored: false
  }

  updatePasswords = (target) => {
    const { name, value } = target

    this.setState({
      passwords: {
        ...this.state.passwords,
        [name]: value
      }
    })
  }
  async submitProfile(event) {
    event.preventDefault()
    await this.setState({
      responseMessage: '',
      reponseErrored: false
    })

    try {
      const response = await updatePassword(this.state.passwords)

      this.setState({
        responseMessage: response.message
      })
    } catch (e) {
      if (e.response) {
        this.setState({
          responseMessage: e.response.data.message,
          reponseErrored: true
        })
      }
    }

    this.setState({
      passwords: {
        password_current: '',
        password: '',
        password_confirm: ''
      }
    })
  }

  renderResponseMessage() {
    if (this.state.responseMessage) {
      return <Response error={ this.state.responseErrored }>{ this.state.responseMessage }</Response>
    }
  }

  render() {
    return (
      <ScreenBody>
        <ColumnForm onSubmit={(event) => this.submitProfile(event)}>
          <Input
            name='password_current'
            onChange={ this.updatePasswords }
            value={ this.state.passwords.password_current }
            placeholder='Current password'
            type="password"
          />
          <Input
            name='password'
            onChange={ this.updatePasswords }
            value={ this.state.passwords.password }
            placeholder='New password'
            type="password"
          />
          <Input
            name='password_confirm'
            onChange={ this.updatePasswords }
            value={ this.state.passwords.password_confirm }
            placeholder='Confirm new password'
            type="password"
          />

          { this.renderResponseMessage() }
          <Button>Save changes</Button>
        </ColumnForm>
        <Version />
      </ScreenBody>
    )
  }
}
