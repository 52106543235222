import React from 'react'
import styled from 'styled-components'

import { Icon, UnstyledLink } from '../../style/styles'
import { fonts, padding, icons, colours } from '../../style'

const MenuContainer = styled.div`
  position: relative;
  z-index: 2;
  color: ${colours.black};
`

const MenuOptions = styled.div`
  position: absolute;
  background: ${colours.white};
  width: 100%
  color: ${colours.black};

  display: ${({displayMenu}) => displayMenu ? 'block' : 'none' }

  a {
    div {
      display: flex;
      flex: 1;
    }
  }
`

const MenuOption = styled.div`
  font-size: ${fonts.lg};
  padding: ${padding.md} ${padding.lg};
  background: ${colours.white};
  color: ${colours.black};

  :hover {
    cursor: pointer;
  }
`

const MenuOptionHoverable = styled(MenuOption)`
  :hover {
    background: rgba(0, 0, 0, 0.2);
  }
`

export default class DropdownMenu extends React.Component {
  constructor(props) {
    super(props)

    this.dropdownWrapperRef = React.createRef()
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.state = {
      displayMenu: false
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.dropdownWrapperRef && !this.dropdownWrapperRef.current.contains(event.target)) {
      this.setState({ displayMenu: false })
    }
  }

  renderMenuOption(option, index) {
    if (option.to) {
      return (
        <UnstyledLink key={ "dropdown" + index } to={ option.to }>
          <MenuOptionHoverable>
            { option.text }
          </MenuOptionHoverable>
        </UnstyledLink>
      )
    }
    return (
      <MenuOptionHoverable key={ "dropdown" + index } onClick={ option.onClick }>
        { option.text }
      </MenuOptionHoverable>
    )
  }

  render() {
    const { menuLabel, options } = this.props

    return (
      <MenuContainer ref={ this.dropdownWrapperRef }>
        <MenuOption onClick={ () => this.setState({ displayMenu: !this.state.displayMenu}) }>
          { menuLabel } <Icon icon={ this.state.displayMenu ? icons.chevronUp : icons.chevronDown } />
        </MenuOption>
        <MenuOptions displayMenu={ this.state.displayMenu }>
          {
            options.map(this.renderMenuOption)
          }
        </MenuOptions>
      </MenuContainer>
    )
  }
}
