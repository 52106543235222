import React from 'react'
import styled from 'styled-components'
import { devices, colours, fonts } from '../../style'
import { ScreenHeader, UnstyledLink } from '../../style/styles'

const MobileHeader = styled(ScreenHeader)`
  padding-bottom: 0;
  border-bottom: none;

  @media ${devices.tabletsm} {
    display: none;
  }
`

const HeaderContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  background: ${colours.white};

  & > div {
    justify-content: center;
    display: flex;
    width: ${({headers}) => 100 / headers.length + '%'}
  }

  & > a {
    flex: 1;
    justify-content: center;
  }

  @media ${devices.tabletsm} {
    display: none;
  }
`

const HeaderCell = styled.div`
  text-align: center;
  font-size: ${fonts.size.subheading};
  font-family: 'archiasemibold';
  letter-spacing: -.5px;
  font-weight: 600;
  margin: 0;
  padding-bottom: 15px;

  border-bottom: 3px solid ${({active}) => active ? colours.teal : colours.white}
  color: ${({active}) => active ? colours.navy : colours.lightGrey};
  @media ${devices.tabletsm} {
    letter-spacing: normal;
  }
`

const HeaderLink = styled(UnstyledLink)`
  & > div {
    display: flex;
    flex: 1;
    justify-content: center;
  }
`

export default class ClickableHeader extends React.Component {
  constructor(props) {
    super(props)

    this.renderHeaderCell = this.renderHeaderCell.bind(this)
    this.state = {
      activeIndex: null
    }
  }

  componentDidMount() {
    const activeIndex = this.props.headers.findIndex(header => header.active)
    this.setState({
      activeIndex
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.headers !== prevProps.headers) {
      const activeIndex = this.props.headers.findIndex(header => header.active)
      this.setState({
        activeIndex
      })
    }
  }

  clickHeader(header, index) {
    if (!header.disabled) {
      this.setState({
        activeIndex: index
      })
      if (header.onClick) {
        header.onClick()
      }
    }
  }

  renderHeaderCell(header, index) {
    if (header.to && !header.disabled) {
      return (
        <HeaderLink
          key={ index }
          to={ header.to }
          active={ (this.state.activeIndex === index || header.active) && !header.disabled }
        >
          <HeaderCell onClick={ () => this.clickHeader(header, index) } active={ this.state.activeIndex === index }>
            { header.title }
          </HeaderCell>
        </HeaderLink>
      )
    }

    return (
      <HeaderCell
        active={ (this.state.activeIndex === index || header.active) && !header.disabled }
        onClick={ () => this.clickHeader(header, index) }
        key={ index }
      >
        { header.title }
      </HeaderCell>
    )
  }

  render() {
    const { headers } = this.props
    return (
      <MobileHeader>
        <HeaderContainer headers={ headers }>
          {
            headers.map(this.renderHeaderCell)
          }
        </HeaderContainer>
      </MobileHeader>
    )
  }
}
