export const hasPermission = permission => {
  if (!localStorage.getItem('jwt')) {
    return false
  }
  let token = localStorage.getItem('jwt').split('.')

  let payloadBuffer = new Buffer.from(token[1], 'base64')
  let payload = JSON.parse(payloadBuffer.toString('ascii'))

  if (payload.permissions.indexOf(permission) > -1) {
    return true
  }

  return false
}

export const hasRole = role => {
  if (!localStorage.getItem('jwt')) {
    return false
  }
  let token = localStorage.getItem('jwt').split('.')

  let payloadBuffer = new Buffer.from(token[1], 'base64')
  let payload = JSON.parse(payloadBuffer.toString('ascii'))

  if (payload.role === role) {
    return true
  }

  return false
}
