import React from 'react'
import styled from 'styled-components'

import { ReactComponent as AddIcon } from '../../assets/add-blue.svg'

import Box from './Box'
import { margin, devices, colours, fonts } from '../../style'

export const BoxLayout = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;

  & > div {
    width: calc(50vw - 22.5px); // account for the margin right and padding to the left (15px) of the container
    height: calc(50vw - 22.5px); // account for the margin right and padding to the left (15px) of the container
    margin-bottom: ${margin.lg};
    margin-right: ${margin.lg};

    @media ${devices.tabletsm} {
      width: 160px;
      height: 160px;
      margin-bottom: ${margin.xl};
      margin-right: ${margin.xl};
    }
  }

  @media ${devices.tabletsm} {
    justify-content: flex-start;
    height: max-content;
  }
`

const BoxText = styled.span`
  padding-top: 10px;
  color: ${colours.blue};
  font-family: ${fonts.family.silkasemibold};
`

export default class Boxes extends React.Component {
  boxClicked = (callback) => {
    if (callback) {
      callback()
    }
  }

  renderIcon(item) {
    const width = '80'
    const height = '80'
    if (item.icon && item.icon.includes('svg')) {
      return <img src={item.icon} width={ width } />
    } else {
      return <AddIcon width={ width } height={ height } />
    }
  }

  render() {
    const { items, displayKey, onClick, menuItems, onMenuClick } = this.props

    if (items && items.length) {
      return (
        <div>
          <BoxLayout>
            {
              items.map((item, index) => {
                return (
                  <Box
                    boxItem={ item }
                    key={ index }
                    onClick={ () => this.boxClicked(item.onClick || (onClick ? onClick(item) : null)) }
                    editable={ item.editable !== undefined ? item.editable : true }
                    menuItems={ menuItems }
                    onMenuClick={ onMenuClick }
                    data_testid={ (item.data_testid || `box`)  + index }
                  >
                    { this.renderIcon(item) }
                    <BoxText data-testid={ `box${index}Text` }>{ item[displayKey] || item }</BoxText>
                  </Box>
                )
              })
            }
          </BoxLayout>
        </div>
      )
    }

    return <div />
  }
}
