import React from 'react';
import styled from 'styled-components';

import { Icon } from '../../style/styles';
import { icons, colours, border } from '../../style';
import { hasRole } from '../../lib/permission';

// Should we set the menu to be a little transparent so that we can still see the box behind it?
const BoxMenu = styled.div`
  z-index: 10;
  position: absolute;
  top: 10px;
  right: 10px;
  bottom: 10px;
  left: 10px;

  z-index: 5;

  background: #fff;

  display: ${({ show }) => (show ? 'flex' : 'none')};
  flex: 1;
  flex-direction: column;
`;

const BoxContainer = styled.div`
  position: relative;
  text-align: center;
`;

// For our current state, we will only hide the menu icon if the object is explicitly uneditable
const MenuIcon = styled(Icon)`
  position: absolute;
  top: 0;
  right: 0;

  padding: 10px;

  z-index: 10;
`;

export const BoxDisplay = styled.div`
  position: relative;

  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  height: 100%;
  background: ${colours.white};
  border-radius: ${border.radius.lg};

  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
`;

const BoxContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  font-size: 14px;
  font-weight: 500;
`;

const MenuItem = styled.div`
  padding-bottom: 10px;
`;

export default class Box extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.containerRef = React.createRef();
    this.state = {
      height: 20,
      showMenu: false,
    };
  }

  componentDidMount() {
    this.setState({
      height: this.containerRef.current.offsetWidth,
    });
  }

  openMenu = () => {
    if (this.props.onMenuClick) {
      this.props.onMenuClick(this.props.boxItem);
    } else {
      this.setState({
        showMenu: !this.state.showMenu,
      });
    }
  };

  closeMenu = () => {
    this.setState({
      showMenu: false,
    });
  };

  renderMenuIcon() {
    const { editable, menuItems } = this.props;

    if ((editable || menuItems) && (hasRole('admin') || hasRole('developer'))) {
      return (
        <MenuIcon
          onClick={this.openMenu}
          icon={this.state.showMenu ? icons.times : icons.ellipsisH}
        />
      );
    }

    return;
  }

  renderMenu() {
    const { menuItems, boxItem, data_testid } = this.props;

    if (menuItems) {
      return (
        <BoxMenu
          data-testid={(boxItem.data_testid || data_testid) + 'boxMenu'}
          show={this.state.showMenu}
        >
          {menuItems.map((item, index) => {
            return (
              <MenuItem key={index}>
                <Icon
                  data_testid={
                    (boxItem.data_testid || data_testid) + 'menuItem' + index
                  }
                  onClick={() => this.handleMenuClick(item, boxItem)}
                  icon={item.icon}
                >
                  {item.text}
                </Icon>
              </MenuItem>
            );
          })}
        </BoxMenu>
      );
    }

    return;
  }

  handleMenuClick = (item, boxItem) => {
    item.onClick(boxItem);
    this.closeMenu();
  };

  render() {
    const { children, onClick, data_testid } = this.props;

    return (
      <BoxContainer data-testid={data_testid}>
        {this.renderMenu()}
        {this.renderMenuIcon()}

        <BoxDisplay
          onClick={onClick}
          ref={this.containerRef}
          height={this.state.height + 'px'}
        >
          <BoxContentContainer>{children}</BoxContentContainer>
        </BoxDisplay>
      </BoxContainer>
    );
  }
}
