import React from 'react';
import { Route } from 'react-router-dom';

import ProviderDetails from '../views/Provider/ProviderDetails';
import { LeadsIndex } from '../views/Leads';
import { ProfileViews } from '../views/Profile';

import { PageHeader } from '../components/Header';

import { AppLayout, PageLayout } from '../style/styles';
import { Settings } from '../views/Settings';

class ProviderRoutes extends React.Component {
  constructor(props) {
    super(props);

    this.props = props;

    this.state = {
      displayMenu: false,
    };
  }

  toggleMenu = () => {
    this.setState({
      displayMenu: !this.state.displayMenu,
    });
  };

  render() {
    const { context } = this.props;

    return (
      <AppLayout>
        <PageLayout>
          <PageHeader context={context} />
          {/** Routes for viewing provider details */}
          <Route
            key='provider'
            exact
            path='/provider'
            render={(routeProps) => (
              <ProviderDetails context={context} {...routeProps} />
            )}
          />

          <Route
            exact
            key='profile'
            path='/profile'
            render={(routerProps) => (
              <ProfileViews {...routerProps} context={this.props.context} />
            )}
          />

          {/** Routes for viewing provider leads */}
          {/** As admin uses the same component, set up a route parameter to help with navigation */}
          <Route
            key='leads'
            exact
            path={['/', '/leads', '/leads/:id']}
            render={(routeProps) => (
              <LeadsIndex context={context} {...routeProps} />
            )}
          />

          <Route
            exact
            key='settings'
            path='/settings'
            render={(routerProps) => (
              <Settings {...routerProps} context={this.props.context} />
            )}
          />
        </PageLayout>
      </AppLayout>
    );
  }
}

export default ProviderRoutes;
