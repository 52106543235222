import React from 'react'
import ProviderIndexCard from './ProviderIndexCard'
import { ScreenBody } from '../../style/styles'

export default class ProviderIndex extends React.Component {
  render() {
    const { handleClick, selectedId } = this.props

    return (
      <ScreenBody>
        {
          this.props.providers.map((provider, index) => (
            <ProviderIndexCard
              key={ index }
              provider={ provider }
              handleClick={ handleClick }
              highlighted={ provider.id === selectedId }
            />
          ))
        }
      </ScreenBody>
    )
  }
}
