export const border = {
  radius: {
    default: '10px',
    sm: '3px',
    md: '5px',
    lg: '10px',
    xl: '15px',
    xxl: '20px'
  },
  style: {
    solid: '1px solid',
    dashed: '1px dashed'
  }
}
