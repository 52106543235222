import React from 'react'
import {
  ScreenBody,
  Subheading,
  SubText,
  UnstyledLink,
  BodyTextContainer,
  PaddingBottom
} from '../../../style/styles'
import { createEnquiry, fetchProvider, getProduct } from '../../../lib/api'
import { Button } from '../../../style/components/button'
import { Create } from '../../../components/Enquiries'
import DoubleColumn from '../../../style/layouts/DoubleColumn'
import { ProviderIndex } from '../../../components/Providers'
import Validation, { required, email } from '../../../lib/validation'

export default class Enquire extends React.Component {
  constructor(props) {
    super(props)

    this.props = props
    this.state = {
      product: {},
      provider: {},
      providers: [],
      enquiry: {
        full_name: '',
        email: '',
        telephone: '',
        profession: '',
      },
      enquirySuccess: false,
      creatingEnquiry: false
    }

    this.validation = new Validation({
      full_name: [
        { type: required, message: 'Please provide your full name' }
      ],
      email: [
        { type: required, message: 'Please provide your email address' },
        { type: email, message: 'Email must be in the correct format' }
      ],
      telephone: [
        { type: required, message: 'Please provide your telephone number' }
      ],
      profession: [
        { type: required, message: 'Please provide your profession' }
      ],
    })
  }

  async componentDidMount() {
    const { match, context } = this.props

    try {
      const { data } = await fetchProvider(match.params.id)
      const prodResponse = await getProduct(match.params.prodId)

      await this.setState({
        provider: data,
        product: prodResponse.data,
        providers: prodResponse.data.providers
      })

      context.displayBCOnMobile()
      context.setBreadcrumb([
        { text: 'Home', to: '/' },
        { text: 'Services', to: '/category', hideIcon: true }
      ])
      if (this.props.context.isDeviceMobile()) {
        context.updatePageTitle(data.name || 'Enquiry')
        if (prodResponse.data.parent) {
          context.pushToBreadcrumb([
            { text: prodResponse.data.parent.name, to: `/category/${prodResponse.data.parent_id}`, hideIcon: true },
            { text: prodResponse.data.name, to: `/service/${prodResponse.data.id}/providers`, hideIcon: true }
          ])
        } else {
          context.pushToBreadcrumb(
            { text: prodResponse.data.name, to: `/service/${prodResponse.data.id}/providers`, hideIcon: true }
          )
        }
      } else {
        context.updatePageTitle(prodResponse.data.name || 'Providers')
        if (prodResponse.data.parent) {
          context.pushToBreadcrumb(
            { text: prodResponse.data.parent.name, to: `/category/${prodResponse.data.parent_id}`, hideIcon: true }
          )
        }
      }
    } catch(e) {
      console.log(e)
    }

    const {
      full_name,
      profession,
      telephone
    } = context.state.user.profile

    await this.setState({
      enquiry: {
        full_name,
        email: context.state.user.email,
        telephone,
        profession,
      }
    })
  }

  componentWillUnmount() {
    this.props.context.updateClickableHeaders()
    this.props.context.setBreadcrumb()
    this.props.context.hideBCOnMobile()
  }

  submitEnquiry = async (event) => {
    event.preventDefault()
    const { context, match } = this.props

    if (!this.state.creatingEnquiry) {
      try {
        await this.setState({
          creatingEnquiry: true
        })

        let request = {
          ...this.state.enquiry,
          provider_id: match.params.id,
          product_id: match.params.prodId,
          user_id: context.state.user.profile.user_id
        }

        delete request.validationErrors

        const { data } = await createEnquiry(request)

        context.updateUser(data.user)
        context.updatePageTitle('Enquiry sent')
        await this.setState({
          enquirySuccess: true,
          creatingEnquiry: false
        })
      } catch(e) {
        console.log(e)
      }
    }
  }

  displayProvider = (provider) => {
    const { history, match } = this.props

    history.push(`/service/${match.params.prodId}/providers/${provider.id}`)
  }

  handleChange = (target) => {
    const { name, value } = target
    this.setState({
      enquiry: {
        ...this.state.enquiry,
        [name]: value
      }
    })
  }

  handleBlur = (target) => {
    this.validation.validateInput(target)

    this.setState({
      validationErrors: this.validation.errors
    })
  }

  renderEnquiryFlow() {
    const { enquirySuccess, provider } = this.state
    const { match } = this.props

    if (enquirySuccess) {
      return (
        <ScreenBody>
          <PaddingBottom>
            <Subheading>Your enquiry has been sent</Subheading>
            <SubText>
              { provider.name } have been notified of your enquiry and will be in touch shortly.
            </SubText>
          </PaddingBottom>
          <div>
            <UnstyledLink to={ `/service/${match.params.prodId}/providers` }>
              <Button>Continue</Button>
            </UnstyledLink>
          </div>
        </ScreenBody>
      )
    }
    return (
      <ScreenBody>
        <BodyTextContainer>
          <Subheading>Send enquiry to {provider.name}</Subheading>
          <SubText>Please complete all the details below. We will store these against your profile to save you time with your next enquiry.</SubText>
        </BodyTextContainer>
        <Create
          enquiry={ this.state.enquiry }
          productId={this.props.match.params.prodId}
          submitEnquiry={ this.submitEnquiry.bind(this) }
          handleChange={ this.handleChange.bind(this) }
          handleBlur={ this.handleBlur.bind(this) }
          errors={ this.validation.errors }
        />
      </ScreenBody>
    )
  }

  render() {
    return (
      <DoubleColumn
        context={ this.props.context }
        leftColumn={
          <ProviderIndex
            selectedId={ parseInt(this.props.match.params.id) }
            providers={ this.state.providers }
            handleClick={ this.displayProvider.bind(this) }
          />
        }
        rightColumn={ this.renderEnquiryFlow() }
        hideColumn='left'
      />
    )
  }
}
