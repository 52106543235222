import React from 'react';
import TripleColumn from '../../style/layouts/TripleColumn';
import DoubleColumn from '../../style/layouts/DoubleColumn';

import IFrame from '../../components/Providers/IFrame/IFrame';
import { getEnquiries } from '../../lib/api';
import { Enquiry, IndexCard } from '../../components/Enquiries';
import { EnquiryPreview } from '../../components/Providers';
import { ScreenBody, PointerDiv } from '../../style/styles';

import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';

const override = css`
  display: block;
  margin: 0 auto;
  margin-top: 100px;
  border-color: '#030545';
`;

export default class Enquiries extends React.Component {
  constructor(props) {
    super(props);

    this.props = props;
    this.state = {
      enquiries: [],
      enquiry: {
        provider: {
          user: {
            profile: {},
          },
        },
      },
      loaded: false,
      hiddenColumn: 'right',
      width: 0,
      height: 0,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  async componentDidMount() {
    const { context, match } = this.props;

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

    context.updateClickableHeaders([
      {
        title: 'Services',
        to: '/category',
        active: false,
      },
      {
        title: 'Enquiries',
        active: true,
      },
    ]);
    try {
      const { data } = await getEnquiries();
      await this.setPageState(data);

      if (match.params.id) {
        this.showRightColumn();
      }
    } catch (e) {
      context.updatePageTitle('Enquiries');
      console.log(e);
    }

    this.setState({
      loaded: true,
    });
  }

  setPageState = async (enquiries = this.state.enquiries) => {
    const { context, match } = this.props;
    context.updatePageTitle('Enquiries');
    context.setBreadcrumb();

    const enquiry = enquiries.find((enquiry) => {
      return enquiry.id === parseInt(match.params.id);
    });

    await this.setState({
      enquiries,
      enquiry: enquiry ? enquiry : enquiries.length ? enquiries[0] : null,
      provider:
        enquiry && enquiry.hasOwnProperty('provider') ? enquiry.provider : {},
    });
  };

  componentWillUnmount() {
    const { context } = this.props;
    context.hideBCOnMobile();
    context.setBreadcrumb();
    context.updateClickableHeaders();
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  showLeftColumn = () => {
    this.setPageState();
    this.props.context.hideBCOnMobile();
    this.setState({
      hiddenColumn: 'right',
    });
  };

  showRightColumn = (enquiry = this.state.enquiry) => {
    const { context } = this.props;
    // context.updatePageTitle(enquiry.provider.name || 'Provider')
    context.displayBCOnMobile();
    if (context.isDeviceMobile()) {
      context.setBreadcrumb([
        { text: 'Home', to: '/' },
        { text: 'Enquiries', onClick: this.showLeftColumn.bind(this) },
      ]);
    }

    this.setState({
      enquiry,
      provider: enquiry.provider,
      hiddenColumn: 'left',
    });
  };

  render() {
    const { context } = this.props;

    if (!this.state.loaded) {
      return <ClipLoader css={override} size={50} color={'#123abc'} />;
    }

    return (
      <DoubleColumn
        context={context}
        leftColumn={
          this.state.enquiries.length ? (
            <ScreenBody>
              {this.state.enquiries.map((enquiry, index) => {
                return (
                  <PointerDiv onClick={() => this.showRightColumn(enquiry)}>
                    <IndexCard
                      id={enquiry.id}
                      status={enquiry.status}
                      name={enquiry.provider.name}
                      date={enquiry.created_at}
                      product={enquiry.product.name}
                      key={index}
                      selectedId={this.state.enquiry.id}
                    />
                  </PointerDiv>
                );
              })}
            </ScreenBody>
          ) : (
            <React.Fragment />
          )
        }
        rightColumn={
          this.state.enquiry ? (
            <ScreenBody>
              {this.state.enquiry.status_id > 1 ? (
                <Enquiry
                  enquiry={this.state.enquiry}
                  provider={this.state.enquiry.provider}
                />
              ) : (
                <EnquiryPreview
                  provider={this.state.enquiry.provider}
                  hideButton={true}
                  width={this.state.width}
                />
              )}
            </ScreenBody>
          ) : (
            <React.Fragment />
          )
        }
        hideColumn={this.state.hiddenColumn}
      />
    );
  }
}
