import React from 'react'
import { Redirect } from 'react-router-dom'

import { Button } from '../../style/components/button'
import { AuthBody, Subheading, TitleContainer, AuthContainer } from '../../style/styles'
import { checkIfUserVerified, resendVerificationEmail } from '../../lib/api'

class UnverifiedEmail extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      credentials: {
        email: '',
        password: '',
      },
      redirect: false,
      requestingResend: false
    }
  }

  async componentDidMount() {
    const { context } = this.props
    try {
      const { data } = await checkIfUserVerified()

      context.updateToken(data.token)
    } catch(e) {
      console.log(e.message)
    }

    if (context.verifiedEmail()) {
      this.setState({
        redirect: true
      })
    }
  }

  resendEmail = async () => {
    await this.setState({
      requestingResend: true
    })
    try {
      await resendVerificationEmail(this.props.context.state.user.profile.user_id)
    } catch (e) {
      console.log(e.message)
    }
    await this.setState({
      requestingResend: false
    })
  }

  render () {
    const { context } = this.props

    if (!context.state.token || context.state.token === '') {
      return <Redirect push to='/auth/login' />
    }

    if (this.state.redirect || context.emailVerified) {
      return <Redirect push to="/" />
    }

    return (
      <AuthBody>
        <AuthContainer>
          <TitleContainer>
            <Subheading>Check your inbox</Subheading>
            <span>We've sent you a verification email</span>
          </TitleContainer>

          <Button disabled={ this.state.requestingResend } onClick={ this.resendEmail }>
            Re-send email
          </Button>
        </AuthContainer>
      </AuthBody>
    )
  }
}

export default UnverifiedEmail
