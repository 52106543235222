import React from 'react';

import Iframe from 'react-iframe';

import './IFrame.css';

export default class IFrame extends React.Component {
  render() {
    const { url, location } = this.props;

    if (url === 'undefined' || url === 'null') {
      return <div></div>;
    }

    return (
      <>
        {url && (
          <Iframe
            url={url}
            width={location === 'admin' ? '335px' : '100%'}
            height='600px'
            id='myId'
            className='iFrame'
            display='initial'
            position='relative'
            loading='This is loading'
            frameBorder='0'
            sandbox='allow-forms allow-scripts allow-same-origin'
          />
        )}
      </>
    );
  }
}
