import React from 'react';
import styled from 'styled-components';

import { Icon, Subheading } from '../../style/styles';
import { devices, colours, icons, border } from '../../style';

const ModalBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgb(3, 5, 69, 0.9); // rgb is equal to our navy hex

  display: ${({ show }) => (show ? 'flex' : 'none')};
  flex: 1;
  justify-content: center;
  align-items: center;

  z-index: 150;
`;

const ModalBody = styled.div`
  background: ${colours.modalGrey};
  border-radius: ${border.radius.default};
  margin: 15px;
  position: relative;
  padding: 15px;
  width: ${({ type }) => (type === 'gallery' ? 'auto' : '100%')};
  @media ${devices.tabletsm} {
    width: ${({ type }) => (type === 'gallery' ? 'auto' : '400px')};
  }
`;

const CloseModalIcon = styled(Icon)`
  position: absolute;
  top: 15px;
  right: 15px;
`;

const ModalTitle = styled(Subheading)`
  padding: 5px;
  text-align: center;
  margin-bottom: 15px;
`;

export default class Modal extends React.Component {
  render() {
    const { children, close, show, title, type } = this.props;
    return (
      <ModalBackground show={show}>
        <ModalBody type={type}>
          <CloseModalIcon icon={icons.times} onClick={close} />
          <ModalTitle data-testid='modalHeader'>{title}</ModalTitle>
          {children}
        </ModalBody>
      </ModalBackground>
    );
  }
}
