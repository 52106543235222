import React from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';

import {
  updateClientProfile,
  updateEmail,
  fetchUserProfile,
} from '../../lib/api';
import { hasRole } from '../../lib/permission';
import { Button } from '../../style/components/button';
import { ScreenBody, ColumnForm } from '../../style/styles';
import { Input } from '../../components/Inputs';
import { Dropdown, FileInput, Select } from '../../components/Inputs';
import CountyList from './countyList';
import { isCompositeComponent } from 'react-dom/test-utils';

const Response = styled.p`
  margin-top: 0;
`;

export default class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        email: '',
        profile: {
          full_name: '',
          profession: '',
          telephone: '',
          instagram: '',
          social_media_total: '',
          age_range: '',
          county_of_residence: '',
          profile_image: '',
        },
      },
      returnedProfile: {},
      redirect: false,
      loading: true,
      responseMessage: '',
    };
  }

  async componentDidMount() {
    const { updateUser } = this.props.context;
    try {
      const { data } = await fetchUserProfile();
      updateUser(data.user);
    } catch (e) {
      console.log(e.message);
    }

    const { user } = this.props.context.state;
    if (
      user &&
      (user.hasOwnProperty('email') || user.hasOwnProperty('profile'))
    ) {
      await this.setState({
        user,
      });
    }

    if (this.props.context) {
      await this.props.context.setBreadcrumb();
    }

    this.setState({
      loading: false,
    });
  }

  componentWillUnmount() {
    if (this.props.context) {
      this.props.context.setBreadcrumb();
    }
  }

  async submitProfile(event) {
    event.preventDefault();

    const profile = {
      full_name: this.state.user.profile.full_name,
      profession: this.state.user.profile.profession,
      telephone: this.state.user.profile.telephone,
      instagram: this.state.user.profile.instagram,
      social_media_total: this.state.user.profile.social_media_total,
      age_range: this.state.user.profile.age_range,
      county_of_residence: this.state.user.profile.county_of_residence,
      file: this.state.user.profile.file,
    };
    try {
      await updateClientProfile(profile, this.props.context.state.token);

      if (this.state.user.email !== this.props.context.state.user.email) {
        await updateEmail(
          this.state.user.email,
          this.props.context.state.token
        );
      }

      this.setState({
        responseMessage: 'Profile updated',
      });
      this.props.context.updateUser(this.state.user);
    } catch (e) {
      console.log('submit profile', e);
    }
  }

  handleUserChange(target) {
    this.setState({
      user: {
        ...this.state.user,
        [target.name]: target.value,
      },
    });
  }

  async handleProfileChange(target) {
    const { name, value, file, type } = target;

    switch (type) {
      case 'file':
        await this.setState({
          user: {
            ...this.state.user,
            profile: {
              ...this.state.user.profile,
              file: file,
              profile_image: null,
            },
          },
        });
        break;
      default:
        this.setState({
          user: {
            ...this.state.user,
            profile: {
              ...this.state.user.profile,
              [name]: value,
            },
          },
        });
        break;
    }
  }

  cancelForm = () => {
    this.setState({
      redirect: true,
    });
  };

  renderButtons() {
    return (
      <React.Fragment>
        <Button>Save changes</Button>
      </React.Fragment>
    );
  }

  renderOtherInputs() {
    if (!hasRole('admin') && !hasRole('developer')) {
      return (
        <React.Fragment>
          <Input
            name='telephone'
            placeholder='Mobile'
            value={this.state.user.profile.telephone}
            onChange={this.handleProfileChange.bind(this)}
          />
          <Input
            name='profession'
            placeholder={hasRole('individual') ? 'Profession' : 'Position'}
            value={this.state.user.profile.profession}
            onChange={this.handleProfileChange.bind(this)}
          />
          <Select
            name='age_range'
            placeholder='Select an age range...'
            value={
              this.state.user.profile.age_range
                ? { label: this.state.user.profile.age_range }
                : null
            }
            options={[
              { value: '18-25', label: '18-25', name: 'age_range' },
              { value: '25-30', label: '25-30', name: 'age_range' },
              { value: '30+', label: '30+', name: 'age_range' },
            ]}
            onChange={this.handleProfileChange.bind(this)}
            closeMenuOnSelect={true}
          />
          <Select
            placeholder='Select a County of Residence'
            name='county_of_residence'
            value={
              this.state.user.profile.county_of_residence
                ? { label: this.state.user.profile.county_of_residence }
                : null
            }
            options={CountyList}
            onChange={this.handleProfileChange.bind(this)}
            closeMenuOnSelect={true}
          />

          <Input
            name='instagram'
            placeholder={hasRole('individual') ? 'Instagram' : 'Instagram'}
            value={this.state.user.profile.instagram}
            onChange={this.handleProfileChange.bind(this)}
          />
          <Input
            name='social_media_total'
            placeholder='Total Social Media Following'
            value={this.state.user.profile.social_media_total}
            onChange={this.handleProfileChange.bind(this)}
          />
        </React.Fragment>
      );
    }
  }

  renderResponseMessage() {
    if (this.state.responseMessage) {
      return <Response>{this.state.responseMessage}</Response>;
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect push to='/' />;
    }

    if (this.state.loading) {
      return <React.Fragment />;
    }

    return (
      <ScreenBody>
        <ColumnForm onSubmit={(event) => this.submitProfile(event)}>
          <FileInput
            file={
              this.state.user.profile.file ||
              this.state.user.profile.profile_image
            }
            onChange={this.handleProfileChange.bind(this)}
            name='file'
            placeholder='Upload profile image'
          />
          <Input
            name='full_name'
            placeholder='Full name'
            value={this.state.user.profile.full_name}
            onChange={this.handleProfileChange.bind(this)}
          />
          <Input
            name='email'
            placeholder='Email'
            value={this.state.user.email}
            onChange={this.handleUserChange.bind(this)}
          />
          {this.renderOtherInputs()}
          {this.renderResponseMessage()}
          {this.renderButtons()}
        </ColumnForm>
      </ScreenBody>
    );
  }
}
