import React from 'react';
import { Redirect } from 'react-router-dom';
import moment from 'moment';

import { NameCard } from '../../../components/Individuals';
import { getIndividuals } from '../../../lib/api';
import { DesktopTable } from '../../../style/layouts';
import { widths } from '../../../style';
import { getEnquiryStatusCounts } from '../../../lib/helper';

export default class IndividualIndex extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      tableData: [],
      tableColumns: [],
      tableFilterOptions: [],
      users: [],
      individuals: [],
      searchValue: '',
      redirectId: null,
    };
  }

  async componentDidMount() {
    try {
      await this.getUsers();
    } catch (e) {
      console.log(e.message);
    }
    this.props.context.updatePageTitle('Users');
    this.props.context.setBreadcrumb();
  }

  async getUsers() {
    this.props.context.updatePageTitle('Users');
    try {
      const { data } = await getIndividuals();

      this.setState({
        individuals: data,
      });

      this.setState({
        tableData: data.map((user, index) => {
          const { id, email, profile, created_at, enquiries } = user;
          let {
            openCount,
            newCount,
            wonCount,
            lostCount,
            totalCount,
          } = getEnquiryStatusCounts(enquiries);

          return {
            id: id,
            registerDate: created_at,
            name: profile.full_name,
            profession: profile.profession,
            instagram: profile.instagram,
            new: newCount,
            open: openCount,
            won: wonCount,
            lost: lostCount,
            total: totalCount,
            email,
            profile,
          };
        }),
      });
    } catch (e) {
      console.log(e);
    }
  }

  componentWillUnmount() {
    this.props.context.updatePageTitle();
  }

  navigateToUser(user) {
    this.setState({
      redirectId: user.id,
    });
  }

  async handleRowClick(row) {
    this.props.history.push(`/user/${row.id}/leads`);
  }

  render() {
    if (this.state.redirectId) {
      return <Redirect push to={`/user/${this.state.redirectId}/leads`} />;
    }

    return (
      <DesktopTable
        displaySidePanel={false}
        tableData={this.state.tableData}
        tableColumns={[
          { name: 'Name', selector: 'name', sortable: true },
          { name: 'Profession', selector: 'profession', sortable: true },
          {
            name: 'Instagram Handle',
            selector: 'instagram',
            cell: (row) => (
              <a
                href={`https://www.instagram.com/${row.instagram}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                {row.instagram}
              </a>
            ),
          },
          { name: 'County', selector: 'county_of_residence', sortable: true },

          { name: 'Age', selector: 'age_range', sortable: true },

          { name: 'Social', selector: 'social_media_total', sortable: true },

          {
            name: 'Registered',
            selector: 'registerDate',
            sortable: true,
            width: widths.table.dateStatusColumn,
            cell: (row) => (
              <div>{moment(row.registerDate).format('DD/MM/YYYY')}</div>
            ),
          },
          {
            name: 'New',
            selector: 'new',
            sortable: true,
            width: widths.table.valueColumn,
          },
          {
            name: 'Open',
            selector: 'open',
            sortable: true,
            width: widths.table.valueColumn,
          },
          {
            name: 'Won',
            selector: 'won',
            sortable: true,
            width: widths.table.valueColumn,
          },
          {
            name: 'Lost',
            selector: 'lost',
            sortable: true,
            width: widths.table.valueColumn,
          },
          {
            name: 'Total',
            selector: 'total',
            sortable: true,
            width: widths.table.valueColumn,
          },
        ]}
        onRowClicked={this.handleRowClick.bind(this)}
        tableFilterOptions={[
          { value: 1, text: 'All' },
          { value: 2, text: 'New' }, // Registered within the last x days
          { value: 3, text: 'Active' }, // Open enquiries
          { value: 4, text: 'Inactive' }, // No open enquiries
        ]}
        tableName='individuals'
        context={this.props.context}
      >
        <div>
          {this.state.individuals.map((user, index) => {
            return (
              <NameCard
                user={user}
                name={user.profile.full_name}
                openLeads={
                  user.enquiries.filter((lead) => lead.status.name === 'open')
                    .length
                }
                newLeads={
                  user.enquiries.filter((lead) => lead.status.name === 'new')
                    .length
                }
                key={index}
                onClick={this.navigateToUser.bind(this)}
              />
            );
          })}
        </div>
      </DesktopTable>
    );
  }
}
