import React from 'react';
import {
  StyledInput,
  InputRow,
  InputLabel,
  LabelText,
  ErrorText,
  TextArea,
} from '../../style/components/input';
import { SubText } from '../../style/styles';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import moment from 'moment';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class Input extends React.Component {
  constructor(props) {
    super(props);

    this.props = props;
  }

  shouldComponentUpdate(nextProps) {
    return (
      this.props.value !== nextProps.value ||
      nextProps.errors !== this.props.errors
    );
  }

  renderErrorMessage(errorMessage) {
    if (!this.props.errors) return;

    return <ErrorText>{errorMessage}</ErrorText>;
  }

  render() {
    let {
      label,
      type,
      name,
      data_testid,
      value,
      onChange,
      onBlur,
      checked,
      errors,
      placeholder,
      children,
      data,
    } = this.props;

    switch (type) {
      case 'richeditor':
        return (
          <InputRow>
            <CKEditor
              editor={ClassicEditor}
              data={data}
              onInit={(editor) => {
                editor.ui
                  .getEditableElement()
                  .parentElement.insertBefore(
                    editor.ui.view.toolbar.element,
                    editor.ui.getEditableElement()
                  );
              }}
              type='classic'
              onChange={(event, editor) => {
                const input = editor.getData();
                onChange({ name: 'description', value: input });
              }}
              onBlur={(event, editor) => {}}
              config={{
                toolbar: [
                  'heading',
                  '|',
                  'bold',
                  'italic',
                  'link',
                  'bulletedList',
                  'numberedList',
                  'blockQuote',
                ],
                heading: {
                  options: [
                    {
                      model: 'paragraph',
                      title: 'Paragraph',
                      class: 'ck-heading_paragraph',
                    },
                    {
                      model: 'heading1',
                      view: 'h1',
                      title: 'Heading 1',
                      class: 'ck-heading_heading1',
                    },
                    {
                      model: 'heading2',
                      view: 'h2',
                      title: 'Heading 2',
                      class: 'ck-heading_heading2',
                    },
                  ],
                },
              }}
              error={errors}
            />
          </InputRow>
        );
      case 'textarea':
        return (
          <InputRow>
            <TextArea
              data-testid={data_testid}
              type={type || 'text'}
              value={value}
              checked={checked || false}
              onChange={({ target }) => onChange(target)}
              onBlur={({ target }) => (onBlur ? onBlur(target) : null)}
              name={name}
              error={errors}
              placeholder={placeholder}
              rows={this.props.rows || 7}
            />
            {this.renderErrorMessage(errors)}
          </InputRow>
        );
      case 'checkbox':
        return (
          <InputRow>
            <LabelText>
              <input
                data-testid={data_testid}
                type='checkbox'
                value={value}
                onChange={({ target }) => onChange(target)}
                onBlur={({ target }) => (onBlur ? onBlur(target) : null)}
                name={name}
                error={errors}
                checked={checked || false}
              />
              <SubText>{children}</SubText>
            </LabelText>
            {this.renderErrorMessage(errors)}
          </InputRow>
        );

      case 'date':
        if (
          typeof value === 'undefined' ||
          (typeof value === 'string' && value.toLowerCase() === 'invalid date')
        ) {
          value = '';
        }

        return (
          <InputRow>
            <DayPickerInput
              data-testid={data_testid}
              clickUnselectsDay={true}
              value={value ? moment(value).format('DD/MM/YYYY') : ''}
              onDayChange={(date) => onChange({ name, value: date })}
              formatDate={formatDate}
              parseDate={parseDate}
              format='DD/MM/YYYY'
              component={(props) => {
                return (
                  <StyledInput
                    data-testid={data_testid}
                    error={errors}
                    autoComplete='off'
                    {...props}
                  />
                );
              }}
              onDayPickerHide={() =>
                onBlur ? onBlur({ name, value: value }) : null
              }
              inputProps={{
                name,
                data_testid,
                error: errors,
              }}
              dayPickerProps={{
                selectedDays: moment(value).toDate(),
              }}
              placeholder={placeholder}
            />
            {this.renderErrorMessage(errors)}
          </InputRow>
        );
      default:
        if (label) {
          return (
            <InputRow>
              <InputLabel htmlFor={name}>
                <LabelText>{label}</LabelText>
                <StyledInput
                  data-testid={data_testid}
                  type={type || 'text'}
                  value={value}
                  checked={checked || false}
                  onChange={({ target }) => onChange(target)}
                  onBlur={({ target }) => (onBlur ? onBlur(target) : null)}
                  name={name}
                  error={errors}
                  placeholder={placeholder}
                />
              </InputLabel>
              {this.renderErrorMessage(errors)}
            </InputRow>
          );
        }

        return (
          <InputRow>
            <StyledInput
              data-testid={data_testid}
              type={type || 'text'}
              value={value}
              checked={checked || false}
              onChange={({ target }) => onChange(target)}
              onBlur={({ target }) => (onBlur ? onBlur(target) : null)}
              name={name}
              error={errors}
              placeholder={placeholder}
            />
            {this.renderErrorMessage(errors)}
          </InputRow>
        );
    }
  }
}

export default Input;
