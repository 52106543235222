import React from 'react'
import { processSignIn } from '../../lib/api'
import { Redirect, Link } from 'react-router-dom'

import { StateContext } from '../../components/Context'
import { Input } from '../../components/Inputs'
import Validation, { required, email } from '../../lib/validation'

import { Button } from '../../style/components/button'

import { ErrorText } from '../../style/components/input'

import {
  AuthBody,
  StyledForm,
  Subheading,
  SubText,
  TitleContainer,
  InlinePadded,
  AuthContainer
} from '../../style/styles'

class SignIn extends React.Component {
  static contextType = StateContext

  constructor(props) {
    super(props)

    this.state = {
      credentials: {
        email: '',
        password: '',
      },
      formErrors: {},
      redirect: false,
      loginFailed: false
    }

    this.validation = new Validation({
      email: [
        {type: required, message: 'Please enter your email'},
        {type: email, message: 'Please enter a valid email'}
      ],
      password: [
        {type: required, message: 'Please enter your password'}
      ],
    })
  }

  componentDidMount() {
    if (this.context.state.token) {
      this.setState({
        redirect: true
      })
    }
  }

  async signIn(event = null, context) {
    event.preventDefault()

    if (this.validation.validateForm(this.state.credentials)) {
      try {
        let { data } = await processSignIn(this.state.credentials)
        if (data && data.token) {
          context.updateUser(data.user)
          context.updateToken(data.token)

          this.setState({
            loginFailed: false,
            redirect: true
          })
        } else {
          this.updateCredentialsState({ name: 'password', value: '' })
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          // Incorrect username/password combination
          this.setState({ loginFailed: true })
        }
        this.updateCredentialsState({ name: 'password', value: '' })
      }
    } else {
      this.updateCredentialsState({ name: 'password', value: '' })
      this.setState({
        formErrors: this.validation.errors
      })
    }
  }

  updateCredentialsState(target) {
    const value = target.value
    const field = target.name

    this.setState({
      credentials: {
        ...this.state.credentials,
        [field]: value
      }
    })
  }

  handleBlur(target) {
    this.validation.validateInput(target)

    this.setState({
      formErrors: this.validation.errors
    })
  }

  renderFailedLogin() {
    if (!this.state.loginFailed) {
      return <React.Fragment />
    }
    
    return (
      <InlinePadded>
        <ErrorText>The email/password combination you have entered is incorrect.</ErrorText>
      </InlinePadded>
    )
  }

  render () {
    if (this.state.redirect) {
      return <Redirect push to="/" />
    }

    return (
      <StateContext.Consumer>
        {(context) => (
          <React.Fragment>
            <AuthBody>
              <AuthContainer>
                <TitleContainer>
                  <Subheading>Welcome to Play-Ex</Subheading>
                  <SubText>Enter your login details below</SubText>
                </TitleContainer>
                <StyledForm onSubmit={(event) => this.signIn(event, context)}>
                  <Input
                    placeholder="Email"
                    data_testid="email"
                    name="email"
                    type="email"
                    onChange={ this.updateCredentialsState.bind(this) }
                    onBlur={ this.handleBlur.bind(this) }
                    value={ this.state.credentials.email }
                    errors={ this.state.formErrors.email }
                  />
                  <Input
                    placeholder="Password"
                    data_testid="password"
                    name="password"
                    type="password"
                    onChange={ this.updateCredentialsState.bind(this) }
                    onBlur={ this.handleBlur.bind(this) }
                    value={ this.state.credentials.password }
                    errors={ this.state.formErrors.password }
                  />
                  { this.renderFailedLogin() }
                  <Button data-testid="signInButton">

                    Sign in
                  </Button>
                </StyledForm>
                <InlinePadded>
                  <Link to="/auth/forgot_password">Forgot your password?</Link>
                </InlinePadded>
                <InlinePadded>
                  <SubText>Don't have an account?</SubText> <Link to="/auth/register">Register now</Link>
                </InlinePadded>
              </AuthContainer>
            </AuthBody>
          </React.Fragment>
        )}
      </StateContext.Consumer>
    )
  }
}

export default SignIn
