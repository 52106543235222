export const colours = {
  navy: '#030545',
  teal: '#06a09d',
  blue: '#0061fe',
  lightGrey: '#999',
  mediumGrey: '#5d5d5d',
  modalGrey: '#dfe0e1',
  main: '#aaa',
  subtext: '#777',
  grey: '#999',
  darkGrey: '#555',
  background: '#dfe0e1',
  white: '#fff',
  danger: 'red',
  success: '#b6d7a8',
  black: '#000',
  warning: '#f9cb9c',
  primary: '#9fc5f8',
  transparent: 'transparent',
  status: {
    new: '#E2EEFB',
    open: '#FFEDE1',
    won: '#E5F6E8',
    lost: '#FFE7E8',
    text: {
      new: '#228be6',
      open: '#fd7e14',
      won: '#40c057',
      lost: '#fa5252'
    }
  }
}
