import React from 'react';

import { Link } from 'react-router-dom';

import { ScreenBody, UnstyledLink } from '../../../style/styles';
import { Button } from '../../../style/components/button';
import { Detail } from '../../../components/Providers';
import { DesktopTable } from '../../../style/layouts';
import {
  fetchProviders,
  createProvider,
  getCategories,
} from '../../../lib/api';
import { widths } from '../../../style';
import {
  getEnquiryStatusCounts,
  orderAlphabetically,
} from '../../../lib/helper';
import { CreateOrUpdate } from '../../../components/Providers';
import Validation, { required, email } from '../../../lib/validation';

export default class ProviderIndex extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: '',
      providers: [],
      products: [],
      tableData: [],
      formErrors: {},
      provider: {
        id: this.props.match.params.id,
        name: '',
        website: '',
        telephone: '',
        contact_name: '',
        contact_position: '',
        contact_email: '',
        contact_telephone: '',
        leads: [],
        description: '',
        products: [],
      },
    };

    this.validation = new Validation({
      name: [{ type: required, message: 'Please enter a company name' }],
      website: [{ type: required, message: 'Please enter a company website' }],
      telephone: [
        { type: required, message: 'Please enter a company telephone number' },
      ],
      contact_name: [
        { type: required, message: 'Please enter a contact name' },
      ],
      contact_position: [
        { type: required, message: 'Please enter a contact position' },
      ],
      contact_telephone: [
        { type: required, message: 'Please enter a contact phone number' },
      ],
      contact_email: [
        { type: required, message: 'Please enter a contact email' },
        { type: email, message: 'Please enter a valid email' },
      ],
      description: [{ type: required, message: 'Please enter a description' }],
    });
  }

  async componentDidMount() {
    const { context } = this.props;

    try {
      const { data } = await getCategories();

      let products = orderAlphabetically(data, 'name');

      await this.setState({
        products,
      });
    } catch (e) {
      console.log(e);
    }

    await this.getProviders();

    if (context) {
    }
    this.handlePageHeader();
  }

  componentDidUpdate(prevProps) {
    const { hash } = this.props.location;
    if (hash !== prevProps.location.hash) {
      this.handlePageHeader();
    }
  }

  componentWillUnmount() {
    this.props.context.setHeaderButtons();
  }

  handlePageHeader() {
    const { location, context } = this.props;
    const {
      displayBCOnMobile,
      hideBCOnMobile,
      setBreadcrumb,
      isDeviceMobile,
      updatePageTitle,
    } = context;

    if (!context.isDeviceMobile()) {
      context.setHeaderButtons([
        {
          to: '/providers#create',
          text: 'Add provider',
          hasPermission: 'create-provider',
          buttonDisplay: true,
        },
      ]);
    }

    if (location.hash.includes('create') && isDeviceMobile()) {
      displayBCOnMobile();
      updatePageTitle('Add providers');
      setBreadcrumb([
        { text: 'Home', to: '/' },
        { text: 'Providers', to: '/providers' },
      ]);
    } else {
      hideBCOnMobile();
      setBreadcrumb();
      updatePageTitle('Providers');
    }
  }

  async getProviders() {
    try {
      const { data } = await fetchProviders();
      await this.setState({
        providers: data,
      });

      this.setState({
        tableData: data.map((provider, index) => {
          let {
            openCount,
            newCount,
            wonCount,
            lostCount,
            totalCount,
          } = getEnquiryStatusCounts(provider.enquiries);

          return {
            id: provider.id,
            name: provider.name,
            contact: provider.user.profile.full_name,
            phone: provider.telephone,
            new: newCount,
            open: openCount,
            won: wonCount,
            lost: lostCount,
            total: totalCount,
          };
        }),
      });
    } catch (e) {
      console.log(e);
    }
  }

  updateSearchState(target) {
    this.setState({
      search: target.value,
    });
  }

  handleRowClick(row) {
    this.props.history.push(`/providers/${row.id}`);
  }

  async updateFormState(target) {
    const { name, value, file, type } = target;

    switch (type) {
      case 'file':
        await this.setState({
          file: file,
          provider: {
            ...this.state.provider,
            logo: file,
            logo_url: null,
          },
        });
        break;
      default:
        this.setState({
          provider: {
            ...this.state.provider,
            [name]: value,
          },
        });
        break;
    }
  }

  async createProvider(event) {
    event.preventDefault();

    if (!this.validation.validateForm(this.state.provider)) {
      this.setState({
        formErrors: this.validation.errors,
      });
      return;
    }

    const provider = {
      id: this.props.match.params.id,
      name: this.state.provider.name,
      website: this.state.provider.website,
      telephone: this.state.provider.telephone,
      description: this.state.provider.description,
      products: this.state.provider.products.map((product) => product.id),
      contact: {
        name: this.state.provider.contact_name,
        position: this.state.provider.contact_position,
        email: this.state.provider.contact_email,
        telephone: this.state.provider.contact_telephone,
      },
      logo: this.state.file,
    };

    if (this.state.provider.iframe) {
      provider.iframe = this.state.provider.iframe;
    }

    try {
      const { data } = await createProvider(provider);

      this.setState({
        provider: {
          ...data,
          contact_name: data.user.profile.full_name,
          contact_position: data.user.profile.profession,
          contact_email: data.user.email,
          contact_telephone: data.user.profile.telephone,
          products: data.products.map((product) => {
            return {
              key: product.id,
              ...product,
            };
          }),
        },
      });
      this.props.history.push(`/providers/${data.id}/details`);
    } catch (e) {
      console.log(e.message);
      if (e.response.status === 400) {
        this.setState({
          formErrors: {
            ...this.state.formErrors,
            contact_email: 'A user with this email address already exists',
          },
        });
      }
    }
  }

  async cancel(event) {
    event.preventDefault();

    this.validation.resetErrors();
    this.props.history.push('/providers');
  }

  handleBlur(target) {
    this.validation.validateInput(target);

    // View won't refresh on blur unless state updates
    this.setState({
      formErrors: this.validation.errors,
    });
  }

  renderCreateProvider() {
    const { location, match } = this.props;

    return (
      <CreateOrUpdate
        provider={this.state.provider}
        products={this.state.products}
        updateFormState={this.updateFormState.bind(this)}
        submitForm={this.createProvider.bind(this)}
        errors={this.state.formErrors}
        cancel={this.cancel.bind(this)}
        handleBlur={this.handleBlur.bind(this)}
        updateSelectedProducts={this.updateSelectedProducts.bind(this)}
        match={match}
        location={location}
      />
    );
  }

  updateSelectedProducts(options) {
    let products = [];
    if (options && options.length) {
      products = options.map((product) => {
        return {
          id: product.value,
          key: product.value,
          name: product.label,
        };
      });
    }

    this.updateFormState({
      name: 'products',
      value: products,
    });
  }

  render() {
    const { context, location } = this.props;
    if (!context.isDeviceMobile()) {
      return (
        <DesktopTable
          displaySidePanel={location.hash.includes('create')}
          tableData={this.state.tableData}
          tableColumns={[
            { name: 'Name', selector: 'name', sortable: true },
            { name: 'Contact', selector: 'contact', sortable: true },
            { name: 'Phone', selector: 'phone', sortable: true },
            {
              name: 'New',
              selector: 'new',
              sortable: true,
              width: widths.table.valueColumn,
            },
            {
              name: 'Open',
              selector: 'open',
              sortable: true,
              width: widths.table.valueColumn,
            },
            {
              name: 'Won',
              selector: 'won',
              sortable: true,
              width: widths.table.valueColumn,
            },
            {
              name: 'Lost',
              selector: 'lost',
              sortable: true,
              width: widths.table.valueColumn,
            },
            {
              name: 'Total',
              selector: 'total',
              sortable: true,
              width: widths.table.valueColumn,
            },
          ]}
          onRowClicked={this.handleRowClick.bind(this)}
          tableFilterOptions={[{ value: 1, text: 'All providers' }]}
          updatingTable={this.state.updatingTable}
          tableName='providers'
          context={this.props.context}
        >
          {this.renderCreateProvider()}
        </DesktopTable>
      );
    }

    if (location.hash.includes('create')) {
      return (
        <ScreenBody>
          <div>{this.renderCreateProvider()}</div>
        </ScreenBody>
      );
    }

    return (
      <ScreenBody>
        <div>
          <Link to='/providers#create'>
            <Button data-testid='addServiceProviderButton'>
              Add service provider
            </Button>
          </Link>
          {this.state.providers.map((provider, index) => {
            return (
              <UnstyledLink to={`/providers/${provider.id}/leads`} key={index}>
                <Detail provider={provider} />
              </UnstyledLink>
            );
          })}
        </div>
      </ScreenBody>
    );
  }
}
