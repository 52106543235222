import React from 'react'
import { CardContainer } from '../../style/components/card'
import styled from 'styled-components'
import { padding, margin } from '../../style'
import { Pill } from '../../style/components/pill'

const ProductContainer = styled(CardContainer)`
  padding: ${padding.default} ${padding.default} 0 ${padding.default};
`

const ProductPill = styled(Pill)`
  margin-bottom: ${margin.default};
`

export default class Products extends React.Component {
  render () {
    const { products } = this.props
    if (products && products.length) {
      return (
        <ProductContainer>
          {products.map(product => (
              <ProductPill key={product.name}>{product.name}</ProductPill>
          ))}
        </ProductContainer>
      )
    }

    return <React.Fragment />
  }
}
