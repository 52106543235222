import React from 'react'
import { Redirect } from 'react-router-dom'
import { BoxScreenBody } from '../../../style/styles'
import { Boxes } from '../../../components/Boxes'
import { getCategory } from '../../../lib/api'

export default class ViewProducts extends React.Component {
  constructor(props) {
    super(props)

    this.props = props
    this.state = {
      category: {
        children: []
      },
      redirectId: null,
      loaded: true
    }
  }

  async componentDidMount() {
    const { match } = this.props
    const categoryId = match.params.id
    if (categoryId) {
      try {
        const { data } = await getCategory(categoryId)

        this.props.context.updatePageTitle(data.name || 'Services')
        this.props.context.displayBCOnMobile()
        this.props.context.setBreadcrumb([
          { text: 'Home', to: '/' },
          { text: 'Services', to: '/category' },
        ])

        this.setState({
          category: data
        })
      } catch(e) {
        this.props.context.updatePageTitle('Services')
        console.log(e)
      }
    } else {
      this.props.context.setBreadcrumb([
        { text: 'Home', to: '/' }
      ])
    }

    this.setState({
      loaded: true
    })
  }

  componentWillUnmount() {
    this.props.context.updateClickableHeaders()
    this.props.context.setBreadcrumb()
    this.props.context.hideBCOnMobile()
  }

  viewProductProviders = (product) => {
    this.setState({
      redirectId: product.id
    })
  }

  render() {
    if (!this.state.loaded) {
      return <div />
    }

    if (this.state.redirectId) {
      return (
        <Redirect push to={`/service/${this.state.redirectId}/providers`} />
      )
    }

    if (!this.state.category.children.length) {
      if (this.state.category.id) {
        return (
          <Redirect to={`/service/${this.state.category.id}/providers`} />
        )
      }
    }

    return (
      <BoxScreenBody>
        <Boxes
          items={ this.state.category.children }
          onClick={ this.viewProductProviders.bind(this) }
          displayKey="name"
        />
      </BoxScreenBody>
    )
  }
}
