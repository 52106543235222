import React from 'react'
import { Redirect } from 'react-router-dom'

import { verifyEmail } from '../../lib/api'
import { Button } from '../../style/components/button'
import { AuthBody, Subheading, SubText, TitleContainer, AuthContainer } from '../../style/styles'

class VerifiyEmail extends React.Component {
  constructor(props) {
    super(props)

    this.params = props.match.params

    this.state = {
      credentials: {
        email: '',
        password: '',
      },
      redirect: false,
      verified: false,
      errored: false
    }
  }

  async componentDidMount() {
    const { context } = this.props
    if (context.state.token && context.verifiedEmail()) {
      this.setState({
        redirect: true
      })
    }

    try {
      let verified = false
      let { data } = await verifyEmail({ verificationString: this.params.verificationString })

      if (data && data.token) {
        context.updateUser(data.user)
        verified = context.verifiedEmail(data.token)
      } else {
        verified = context.verifiedEmail()
      }

      if (verified) {
        this.setState({
          verified: true
        })
      }
    } catch(e) {
      console.log(e)

      this.setState({
        errored: true
      })
    }
  }

  renderMessage() {
    if (this.state.verified) {
      return (
        <AuthContainer>
          <TitleContainer>
            <Subheading data-testid="verifiedMessage">You are now verified</Subheading>
            <SubText>Click the button to get started</SubText>
          </TitleContainer>

          <Button onClick={() => this.setState({ redirect: true })}>
            Search services
          </Button>
        </AuthContainer>
      )
    }

    if (this.state.errored) {
      return (
        <AuthContainer>
          <TitleContainer>
            <Subheading data-testid="verifiedMessage">There was an error verifying your account</Subheading>
            <span>Please try verifying your account again</span>
          </TitleContainer>

          <Button onClick={() => console.log('resend verification email')}>
            Re-send verification email
          </Button>
          <Button styled='secondary' onClick={() => this.setState({ redirect: true })}>
            Return home
          </Button>
        </AuthContainer>
      )
    }

    return <div />
  }

  render () {
    if (this.state.redirect || !this.props.context.state.token) {
      return <Redirect push to="/" />
    }

    return (
      <React.Fragment>
        <AuthBody>
          { this.renderMessage() }
        </AuthBody>
      </React.Fragment>
    )
  }
}

export default VerifiyEmail
