import React from 'react'
import styled from 'styled-components'
import { devices, border } from '../../style'

const Container = styled.div`
  display: inline-block;
  cursor: pointer;
  position: absolute;
  top: 22px;
  left: 15px;
  transition: 0.2s;

  div {
    width: 30px;
    height: 4px;
    border-radius: ${border.radius.lg};
    background-color: #111;
    margin: 6px 0;

    transition: all 0.2s linear;
    position: relative;
    transform-origin: 1px;
  }

  div:first-child {
    transform: ${({active}) => active ? 'rotate(45deg)' : 'rotate(0deg)'};
  }

  div:nth-child(2) {
    opacity: ${({active}) => active ? '0' : '1'};
  }

  div:last-child {
    transform: ${({active}) => active ? 'rotate(-45deg)' : 'rotate(0deg)'};
  }

  @media ${devices.tabletsm} {
    display: none;
  }
`

export default class Hamburger extends React.Component {
  render() {
    const { onClick, active, context } = this.props
    if (context.state.token) {
      return (
        <Container active={ active } onClick={ onClick }>
          <div />
          <div />
          <div />
        </Container>
      )
    }

    return <div />
  }
}
