import React, { createContext, createRef } from 'react';

export const StateContext = createContext();

export default class StateProvider extends React.Component {
  constructor(props) {
    super(props);

    this.props = props;
    this.deviceWidthRef = createRef();

    this.state = {
      token: props.value ? props.value.token : '',
      user: {
        email: '',
        profile: {
          first_name: '',
          last_name: '',
          profession: '',
          dob: '',
          address_line_1: '',
          address_line_2: '',
          city: '',
          postcode: '',
          phone: '',
        },
      },
      activeTab: '',
      pageTitle: '',
      breadcrumb: [{ text: 'Home', to: '/' }],
      mobileFriendlyBC: false,
      clickableHeaders: [],
      headerButtons: [],

      paginationPerPage: 10,
      tableName: '',
      paginationDefaultPage: 1,
    };
  }

  async componentWillMount() {
    const token = localStorage.getItem('jwt');
    let user = this.state.user;
    if (localStorage.getItem('user') !== undefined) {
      user = JSON.parse(localStorage.getItem('user'));
    }

    if (token) {
      await this.setState({
        token,
        user,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.tableName !== prevState.tableName) {
      this.resetTableData();
    }
  }

  updateUser(user) {
    localStorage.setItem('user', JSON.stringify(user));
    this.setState({
      user,
    });
  }

  updateToken(token) {
    localStorage.setItem('jwt', token);
    const payload = this.getUserDetailsFromToken(token);
    this.setState({
      token,
      user: {
        ...this.state.user,
        id: payload.id,
      },
    });
  }

  getUserDetailsFromToken(token = this.state.token) {
    const tokenSplit = token.split('.');

    const payloadBuffer = new Buffer.from(tokenSplit[1], 'base64');
    return JSON.parse(payloadBuffer.toString('ascii'));
  }

  verifiedEmail(token = this.state.token) {
    this.setState({
      token,
    });

    if (!token || this.state.token === '') {
      return false;
    }

    this.setState({
      token,
    });

    this.updateToken(token);

    const payload = this.getUserDetailsFromToken(token);

    return payload.active;
  }

  checkEmailValidity() {
    if (!this.state.token || this.state.token === '') {
      return false;
    }

    const payload = this.getUserDetailsFromToken(this.state.token);

    return payload.active;
  }

  setActiveTab(tab = '') {
    this.setState({
      activeTab: tab,
    });
  }

  updatePageTitle(pageTitle = this.state.pageTitle) {
    this.setState({
      pageTitle,
    });
  }

  setBreadcrumb(crumbs = [{ text: 'Home', to: '/' }]) {
    this.setState({
      breadcrumb: crumbs,
    });

    if (crumbs.length === 1) {
      this.hideBCOnMobile();
    }
  }

  pushToBreadcrumb(crumb) {
    const crumbs = this.state.breadcrumb;
    crumbs.push(crumb);

    this.setState({
      breadcrumb: crumbs.flat(),
    });
  }

  displayBCOnMobile() {
    this.setState({
      mobileFriendlyBC: true,
    });
  }

  hideBCOnMobile() {
    this.setState({
      mobileFriendlyBC: false,
    });
  }

  logout() {
    localStorage.clear();
    this.setState({
      user: {},
      token: '',
    });
  }

  updateClickableHeaders(clickableHeaders = []) {
    this.setState({
      clickableHeaders,
    });
  }

  isDeviceMobile() {
    if (this.deviceWidthRef.current) {
      return this.deviceWidthRef.current.offsetWidth <= 703;
    }
    return false;
  }

  setHeaderButtons(headerButtons = []) {
    this.setState({
      headerButtons,
    });
  }

  setTableInfo(tableInfo) {
    const keys = Object.keys(tableInfo);

    keys.map((key) => {
      this.setState({
        [key]: tableInfo[key],
      });
      return key;
    });
  }

  resetTableData(tableName = this.state.tableName) {
    this.setState({
      tableName,
      paginationDefaultPage: 1,
    });
  }

  render() {
    return (
      <StateContext.Provider
        value={{
          state: this.state,
          deviceWidthRef: this.deviceWidthRef,
          isDeviceMobile: () => this.isDeviceMobile(),
          emailVerified: this.checkEmailValidity(),
          updateToken: (token) => this.updateToken(token),
          updateUser: (user) => this.updateUser(user),
          verifiedEmail: (token) => this.verifiedEmail(token),
          setActiveTab: (tab) => this.setActiveTab(tab),
          updatePageTitle: (title) => this.updatePageTitle(title),
          setBreadcrumb: (crumb) => this.setBreadcrumb(crumb),
          pushToBreadcrumb: (crumb) => this.pushToBreadcrumb(crumb),
          updateClickableHeaders: (headers) =>
            this.updateClickableHeaders(headers),
          setHeaderButtons: (headers) => this.setHeaderButtons(headers),
          displayBCOnMobile: () => this.displayBCOnMobile(),
          hideBCOnMobile: () => this.hideBCOnMobile(),
          logout: () => this.logout(),
          setTableInfo: (tableInfo) => this.setTableInfo(tableInfo),
          resetTableData: (tableName) => this.resetTableData(tableName),
        }}
      >
        {this.props.children}
      </StateContext.Provider>
    );
  }
}
