import styled from "styled-components";
import { colours } from "..";
import { border } from "../border";

export const StyledInput = styled.input`
  padding: 10px;
  border-radius: ${border.radius.xxl};
  font-family: inherit;
  height: 18px;
  background: ${colours.white};
  border: 1px solid
    ${({ error }) => (error ? colours.danger : colours.background)};
`;

export const InputLabel = styled.label`
  width: 100%;
  display: flex;
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    display: inline-block !important;
  }
  flex-direction: column;
`;

export const InputRow = styled.div`
  display: flex;
  @media only screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    display: inline-block !important;
  }
  margin-bottom: 15px;

  flex: 0 1;
  flex-direction: column;
`;
export const LabelText = styled.span`
  margin-bottom: 5px;
`;

export const ErrorText = styled.div`
  margin-top: 2px;
  color: ${colours.danger};
`;

export const TextArea = styled.textarea`
  font-size: inherit;
  font-family: inherit;
  border-radius: ${border.radius.xxl};
  border: 1px solid
    ${({ error }) => (error ? colours.danger : colours.background)};
  padding: 10px;

  display: flex;
  flex: 1;

  :disabled {
    color: ${colours.black};
    -webkit-text-fill-color: ${colours.black};
    opacity: 1;
    background: ${colours.white};
  }

  div {
    color: ${colours.black};
    -webkit-text-fill-color: ${colours.black};
    opacity: 1;
    background: ${colours.white};
  }
`;
