import React from 'react'
import { CardContainer, CardFlag } from '../../style/components/card'
import { Inline, UnstyledLink, Subheading } from '../../style/styles'
import { Pill } from '../../style/components/pill'
import { getInfluencerStatus } from '../../lib/helper'

export default class NameCard extends React.Component {
  render() {
    const {
      full_name,
      influencer,
      handleClick,
      users,
      to,
      showFlag
    } = this.props

    let enquiryCount = 0
    let signupCount = 0

    if (users) {
      signupCount = users.length
      users.map(user => {
        enquiryCount += user.enquiries.length
        return user
      })
    }

    const influencerStatus = getInfluencerStatus(influencer)
    if (to) {
      return (
        <UnstyledLink to={ to }>
          <CardContainer>
            {
              showFlag ?
                <CardFlag status={ influencerStatus.status }>
                  { influencerStatus.label }
                </CardFlag> :
                <React.Fragment />
            }
            <Subheading>
              { full_name }
            </Subheading>
            <Inline>
              <Pill>{ `${enquiryCount} Leads` }</Pill>
              <Pill>{ `${signupCount} Sign ups` }</Pill>
            </Inline>
          </CardContainer>
        </UnstyledLink>
      )
    }

    return (
      <CardContainer onClick={ () => handleClick ? handleClick(influencer) : null }>
        {
          showFlag ?
            <CardFlag status={ influencerStatus.status }>
              { influencerStatus.label }
            </CardFlag> :
            <React.Fragment />
        }
        <Subheading>
          { full_name }
        </Subheading>
        <Inline>
          <Pill>{ `${enquiryCount} Leads` }</Pill>
          <Pill>{ `${signupCount} Sign ups` }</Pill>
        </Inline>
      </CardContainer>
    )
  }
}
